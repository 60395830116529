<template>
  <div>
    <div class="disclaimer-item">
      <ExclamationMark id="exlamation-mark-icon" />
      <PlainText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.childrenUnder18') }}</PlainText>
    </div>
    <div class="disclaimer-item">
      <PlainText type="warning" class="text-right">*</PlainText>
      <PlainText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.averageContractedRate') }}</PlainText>
      <Tooltip>
        <LinkText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.tooltipLink') }}</LinkText>
        <template #content>
          <PlainText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.tooltipTextAverageContractedRate') }}</PlainText>
        </template>
      </Tooltip>
    </div>
    <div class="disclaimer-item">
      <PlainText type="warning" class="text-right">**</PlainText>
      <PlainText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.marketRate') }}</PlainText>
      <Tooltip>
        <LinkText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.tooltipLink') }}</LinkText>
        <template #content>
          <PlainText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.disclaimer.tooltipTextMarketRate') }}</PlainText>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script>
import ExclamationMark from '@/assets/icons/ExclamationMark.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import LinkText from '@/components/shared/2.0-import/text/LinkText.vue';
import Tooltip from '@/components/shared/2.0-import/Tooltip.vue';

export default {
  name: 'BasicInsuranceProductDisclaimer',
  components: {
    ExclamationMark,
    PlainText,
    LinkText,
    Tooltip
  },
};
</script>

<style lang='scss' scoped>
.disclaimer-item{
    display: flex;
    gap: 6px;
    :last-child{
      align-self: center;
    }
    :first-child{
        flex: 0 0 24px;
    }
}

#exlamation-mark-icon{
    color: var(--color-primary);
    height: 24px;
}

</style>
