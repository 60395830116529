import store from '@/store';
import { api } from '@/utils/api';

import Labels from '@/constants/labels';

const isLoading = function (type, value) {
  store.commit(Labels.Mutations.SET_LOADING, {
    type: type,
    value: value
  });
};

export default {
  LoadByLanguage (language) {
    isLoading('LOAD_BY_LANGUAGE', true);

    const endpoint = Labels.Endpoints.LoadByLanguage();
    const params = { language: language };

    return api.get(endpoint, { params })
      .finally(() => isLoading('LOAD_BY_LANGUAGE', false));
  }
};
