var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PartialOverlayWithCloseButton',{model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},[_c('div',[_c('div',{staticClass:"h1-container"},[_c('H1',[_vm._v(_vm._s(_vm.$tExistsOrDefault(
          "calculatePremium.basicInsurance.informationOverlay.title"
        )))])],1),_c('section',[_c('div',{staticClass:"h2-container"},[_c('H2',[_vm._v(_vm._s(_vm.$tExistsOrDefault(
            "calculatePremium.basicInsurance.informationOverlay.sectionIncluded.title"
          )))])],1),_c('div',{staticClass:"bulletpoint-container"},_vm._l((_vm.$tExistsOrDefault(
            'calculatePremium.basicInsurance.informationOverlay.sectionIncluded.items'
          )),function(item){return _c('div',{key:item,staticClass:"bulletpoint-wrapper"},[_c('Check'),_c('PlainText',[_vm._v(_vm._s(item))])],1)}),0),_c('div',[_c('PlainText',[_vm._v(_vm._s(_vm.$tExistsOrDefault(
            "calculatePremium.basicInsurance.informationOverlay.sectionIncluded.footer.cta-pt1"
          )))]),_c('PlainText',[_vm._v(_vm._s(_vm.$tExistsOrDefault(
            "calculatePremium.basicInsurance.informationOverlay.sectionIncluded.footer.cta-pt2"
          )))])],1)]),_c('section',[_c('div',{staticClass:"h2-container"},[_c('H2',[_vm._v(_vm._s(_vm.$tExistsOrDefault(
            "calculatePremium.basicInsurance.informationOverlay.sectionExcluded.title"
          )))])],1),_c('div',{staticClass:"bulletpoint-container"},_vm._l((_vm.$tExistsOrDefault(
            'calculatePremium.basicInsurance.informationOverlay.sectionExcluded.items'
          )),function(item){return _c('div',{key:item,staticClass:"bulletpoint-wrapper"},[_c('Unchecked'),_c('PlainText',[_vm._v(_vm._s(item))])],1)}),0),_c('PlainText',[_vm._v(_vm._s(_vm.$tExistsOrDefault(
          "calculatePremium.basicInsurance.informationOverlay.sectionExcluded.footer"
        )))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }