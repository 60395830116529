<template>
  <div>
    <div>
      <Checkbox
        :value="!!insured"
        :checkBoxSize="24"
        @change="handlePersonChange"
      >
        <PlainText class="label-text">{{ $tExistsOrDefault('insured.child') }}</PlainText>
      </Checkbox>
      <div class="row mb-3" v-if="insured">
        <PlainText class="col-12 mt-2 col-md-3  mb-2" id="input-label">{{ $tExistsOrDefault('insured.dateOfBirth') }}</PlainText>
        <ManualDateInput
          class="col"
          v-model="insuredDateOfBirth" 
          :minDate="childValidDateRange.to"
          :maxDate="childValidDateRange.from"
        />
        <PlainText class="col-12" type="secondary" v-if="showFreeInsuranceText">
          {{ $tExistsOrDefault('calculatePremium.basicInsurance.kidNotification') }}
        </PlainText>
      </div>
    </div>
  </div>
</template>

<script>
import { subYears } from 'date-fns';
import { mapGetters } from 'vuex';

import BusinessRule from '@/constants/business-rule';
import CalculatePremiumHelpers from '@/helpers/calculate-premium';

import ManualDateInput from '@/components/shared/2.0-import/ManualDateInput.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import Checkbox from '@/components/shared/Checkbox.vue';

export default {
  name: 'CalculatePremiumInsuredChild',
  components: {
    ManualDateInput,
    PlainText,
    Checkbox
  },
  props: {
    insured: Object
  },
  computed: {
    ...mapGetters(['startDate', 'getDateOfBirth']),

    childValidDateRange() {
      return CalculatePremiumHelpers.childrenDisabledDates(
        this.startDate
      );
    },
    insuredDateOfBirth: {
      get() {
        return this.getDateOfBirth(this.insured.id);
      },
      set(value) {
        this.$store.dispatch(BusinessRule.Actions.SET_DATE_OF_BIRTH_FOR_INSURED, {
          insuredId: this.insured.id,
          dateOfBirth: value
        });
      }
    },
    showFreeInsuranceText() {
      const dateOfBirth = this.getDateOfBirth(this.insured.id);

      return dateOfBirth && this.startDate && dateOfBirth > subYears(this.startDate, 18);
    },
  },
  methods: {
    handlePersonChange() {
      if(this.insured) {
         this.$store.commit(BusinessRule.Mutations.REMOVE_INSURED, this.insured.id);
      } else {
        this.$store.commit(BusinessRule.Mutations.ADD_INSURED, { typeOfInsured: 'M' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.label-text{
  margin-left: 13px;
}

.row{
  margin-left: 22px;
}

</style>
