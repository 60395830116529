<template>
  <RectangleWithShadow id="wrapper">
    <div id="header">
      <p>1.</p>
      <svg
        width="42" height="44"
        viewBox="0 0 42 44"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fill-rule="evenodd">
          <path stroke="#CCC" fill="#FFCE34" d="M.5 16.5h7v21h-7zM12.5 16.5h7v21h-7zM24.5 16.5h7v21h-7z" />
          <path d="M0 0h42v44H0z" />
        </g>
      </svg>
    </div>
    <div id="content">
      <H2>{{ $tExistsOrDefault('calculatePremium.basicInsurance.introduction.card1.title') }}</H2>
      <PlainText class="d-inline">{{ $tExistsOrDefault('calculatePremium.basicInsurance.introduction.card1.textPt1') }}</PlainText>
      <PlainText class="d-inline" type="secondary">{{ $tExistsOrDefault('calculatePremium.basicInsurance.introduction.card1.textPt2') }}</PlainText>
    </div>
  </RectangleWithShadow>
</template>

<script>
import RectangleWithShadow from '@/components/shared/RectangleWithShadow.vue';
import H2 from '@/components/shared/2.0-import/text/H2.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';


export default {
  name: 'ExplanationPt1',
    components: {
        H2,
        RectangleWithShadow,
        PlainText
    },
};
</script>

<style lang="scss" scoped>
#wrapper{
    position: relative;
    margin-top: 26px;
    width: 330px;
    min-height: 258px;
}
#header{
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 10px;
    top: -26px;
    margin-bottom: 18px;
    p{
        margin-bottom: 0;
        color: var(--color-primary);
        font-size: 36px;
        font-family: var(--text-font);
    }
}
#content{
    margin-top: 8px;
    H2{
        margin-bottom: 14px;
    }
}
</style>