<template>
  <div class="div-container">
    <section>
      <H3>{{ $tExistsOrDefault('calculatePremium.previewOverview.collectivityInformation.title') }}</H3>
      <H1>{{ selectedContract.contractName }}</H1>
      <PlainText type="primary">
          {{ $tExistsOrDefault('calculatePremium.previewOverview.collectivityInformation.collectivityNumber') }} 
          {{ selectedContract.contractCode }}
      </PlainText>
      <PlainText type="primary">
        {{ $tExistsOrDefault('calculatePremium.previewOverview.collectivityInformation.subcontractLabel') }}
        {{ selectedContract.organisationName }}
      </PlainText>
      <br>
    </section>
    <section>
      <H3 class="mb-3">{{ $tExistsOrDefault('calculatePremium.previewOverview.collectivityInformation.uspTitle') }}</H3>
      <div v-for="usp in $tExistsOrDefault(`calculatePremium.previewOverview.labelUsps.${labelSettings.label}.items`, { inserts: {zorgkiezerScore: $tExistsOrDefault(`calculatePremium.previewOverview.labelUsps.${labelSettings.label}.zorgkiezerScore`) }})" :key="usp">
        <div class="row mb-2">
          <div class="col-2">
            <CheckInCircle />
          </div>
          <PlainText class="col">
            {{ usp }}
          </PlainText>
        </div>
      </div>
    </section>
    <section v-if="isLabelAevitae">
      <iframe 
        frameborder="0" 
        allowtransparency="true" 
        src="https://www.klantenvertellen.nl/retrieve-widget.html?color=white&allowTransparency=true&button=false&lang=nl&tenantId=99&locationId=1078050" 
        width="100%" />
    </section>
    <section>
      <EmailCalculation class="mb-3" />
    </section>
    <section>
      <Button layoutType="action" class="full-width mb-3" @click.native="$emit('complete-application')">
        {{ $tExistsOrDefault('calculatePremium.previewOverview.buttonCompleteInsuranceRequest') }}
      </Button>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import H1 from '@/components/shared/2.0-import/text/H1.vue';
import H3 from '@/components/shared/2.0-import/text/H3.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import CheckInCircle from '@/assets/icons/CheckInCircle.vue';
import EmailCalculation from '@/components/shared/EmailCalculation.vue';
import Button from '@/components/shared/button/Button.vue';

export default {
  name: 'CollectivityInfo',
  components: {
    H1,
    H3,
    PlainText,
    CheckInCircle,
    EmailCalculation,
    Button
  },
  emits: ['complete-application'],
  props: {
    contract: Object
  },
  computed: {
    ...mapGetters(['selectedContract', 'labelSettings']),
    selectedContract() {
      return this.contract;
    },
    isLabelAevitae() {
      return this.labelSettings.label === 'aev';
    }
  }
};
</script>

<style lang="scss" scoped>
.div-container {
  background-color: var(--color-primary-050);
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 32px 24px 16px 24px;
}
.full-width {
  width: 100%;
}
</style>
