// dartz supported nationalities.
export default class NationalityService {
  static getNationalities() {
    return [
      { key: 'NL', value: 'NL-Nederland' },
      { key: 'BE', value: 'BE-België' },
      { key: 'DE', value: 'DE-Duitsland' },
      { key: 'FR', value: 'FR-France' },
      { key: 'GB', value: 'GB-United Kingdom' },
      { key: 'AD', value: 'AD-Andorra' },
      { key: 'AE', value: 'AE-United Arab Emirates' },
      { key: 'AF', value: 'AF-Afghanistan' },
      { key: 'AG', value: 'AG-Antiga And Parbuda' },
      { key: 'AI', value: 'AI-Anguilla' },
      { key: 'AL', value: 'AL-Albania' },
      { key: 'AM', value: 'AM-Armenia' },
      { key: 'AO', value: 'AO-Angola' },
      { key: 'AQ', value: 'AQ-Antarctica' },
      { key: 'AR', value: 'AR-Argentina' },
      { key: 'AS', value: 'AS-American Samao' },
      { key: 'AT', value: 'AT-Austria' },
      { key: 'AU', value: 'AU-Australia' },
      { key: 'AW', value: 'AW-Aruba' },
      { key: 'AZ', value: 'AZ-Azerbaijan' },
      { key: 'BA', value: 'BA-Bosnia And Herzegovina' },
      { key: 'BB', value: 'BB-Barbados' },
      { key: 'BD', value: 'BD-Bangladesh' },
      { key: 'BF', value: 'BF-Burkina Faso' },
      { key: 'BG', value: 'BG-Bulgaria' },
      { key: 'BH', value: 'BH-Barhain' },
      { key: 'BI', value: 'BI-Burundi' },
      { key: 'BJ', value: 'BJ-Benin' },
      { key: 'BM', value: 'BM-Bermuda' },
      { key: 'BN', value: 'BN-Brunei Darussalam' },
      { key: 'BO', value: 'BO-Bolivia' },
      { key: 'BQ', value: 'BQ-Bonaire, St Eustatius en Saba' },
      { key: 'BR', value: 'BR-Brazil' },
      { key: 'BS', value: 'BS-Bahamas' },
      { key: 'BT', value: 'BT-Bhutan' },
      { key: 'BV', value: 'BV-Bouvet Island' },
      { key: 'BW', value: 'BW-Botswana' },
      { key: 'BY', value: 'BY-Belarus' },
      { key: 'BZ', value: 'BZ-Belize' },
      { key: 'CA', value: 'CA-Canada' },
      { key: 'CC', value: 'CC-Cocos (Keeling) Islands' },
      { key: 'CF', value: 'CF-Central African Republic' },
      { key: 'CG', value: 'CG-Congo' },
      { key: 'CH', value: 'CH-Switzerland' },
      { key: 'CI', value: 'CI-Cote D\'Ivoire' },
      { key: 'CK', value: 'CK-Cook Islands' },
      { key: 'CL', value: 'CL-Chile' },
      { key: 'CM', value: 'CM-Cameroon' },
      { key: 'CN', value: 'CN-China' },
      { key: 'CO', value: 'CO-Colombia' },
      { key: 'CR', value: 'CR-Costa Rica' },
      { key: 'CU', value: 'CU-Cuba' },
      { key: 'CV', value: 'CV-Cape Verde' },
      { key: 'CW', value: 'CW-Curacao' },
      { key: 'CX', value: 'CX-Christmas Island' },
      { key: 'CY', value: 'CY-Cyprus' },
      { key: 'CZ', value: 'CZ-Czech Republic' },
      { key: 'DJ', value: 'DJ-Djibouti' },
      { key: 'DK', value: 'DK-Denmark' },
      { key: 'DM', value: 'DM-Dominica' },
      { key: 'DO', value: 'DO-Dominican Republic' },
      { key: 'DZ', value: 'DZ-Algeria' },
      { key: 'EC', value: 'EC-Ecuador' },
      { key: 'EE', value: 'EE-Estonia' },
      { key: 'EG', value: 'EG-Egypt' },
      { key: 'EH', value: 'EH-Western Sahara' },
      { key: 'ER', value: 'ER-Eritrea' },
      { key: 'ES', value: 'ES-Spain (including Ceuta and Melilla)' },
      { key: 'ET', value: 'ET-Ethiopia' },
      { key: 'FI', value: 'FI-Finland' },
      { key: 'FJ', value: 'FJ-Fiji' },
      { key: 'FK', value: 'FK-Falkland Island' },
      { key: 'FM', value: 'FM-Micronesia (Federated States Of)' },
      { key: 'FO', value: 'FO-Faroe Islands' },
      { key: 'FX', value: 'FX-France, Metropolitan' },
      { key: 'GA', value: 'GA-Gabon' },
      { key: 'GD', value: 'GD-Grenada' },
      { key: 'GE', value: 'GE-Goergia' },
      { key: 'GF', value: 'GF-French Guiana' },
      { key: 'GH', value: 'GH-Ghana' },
      { key: 'GI', value: 'GI-Gibraltar' },
      { key: 'GL', value: 'GL-Greenland' },
      { key: 'GM', value: 'GM-Gambia' },
      { key: 'GN', value: 'GN-Guinea' },
      { key: 'GP', value: 'GP-Guadeloupe' },
      { key: 'GQ', value: 'GQ-Equatorial Guinea' },
      { key: 'GR', value: 'GR-Greece' },
      { key: 'GS', value: 'GS-South Georgia And The South Sandwich Islands' },
      { key: 'GT', value: 'GT-Guatemala' },
      { key: 'GU', value: 'GU-Guam' },
      { key: 'GW', value: 'GW-Guinea-Bissau' },
      { key: 'GY', value: 'GY-Guyana' },
      { key: 'HK', value: 'HK-Hong Kong' },
      { key: 'HM', value: 'HM-Heard Island And Mcdonald Islands' },
      { key: 'HN', value: 'HN-Honduras' },
      { key: 'HR', value: 'HR-Croatia' },
      { key: 'HT', value: 'HT-Haiti' },
      { key: 'HU', value: 'HU-Hungary' },
      { key: 'ID', value: 'ID-Indonesia' },
      { key: 'IE', value: 'IE-Ireland' },
      { key: 'IL', value: 'IL-Israel' },
      { key: 'IN', value: 'IN-India' },
      { key: 'IO', value: 'IO-British Indian Ocean Territory' },
      { key: 'IQ', value: 'IQ-Iraq' },
      { key: 'IR', value: 'IR-Iran (Islamic Republic Of)' },
      { key: 'IS', value: 'IS-Iceland' },
      { key: 'IT', value: 'IT-Italy' },
      { key: 'JM', value: 'JM-Jamaica' },
      { key: 'JO', value: 'JO-Jordan' },
      { key: 'JP', value: 'JP-Japan' },
      { key: 'KE', value: 'KE-Kenya' },
      { key: 'KG', value: 'KG-Kyegyzstan' },
      { key: 'KH', value: 'KH-Cambodia' },
      { key: 'KI', value: 'KI-Kiribati' },
      { key: 'KM', value: 'KM-Comoros' },
      { key: 'KN', value: 'KN-Saint Kitts And Nevis' },
      { key: 'KP', value: 'KP-Korea, Republic Of' },
      { key: 'KR', value: 'KR-Korea, Republic Of' },
      { key: 'KW', value: 'KW-Kuwait' },
      { key: 'KY', value: 'KY-Gayman Islands' },
      { key: 'KZ', value: 'KZ-Kazakhstan' },
      { key: 'LA', value: 'LA-Lao People\'S Democratic Republic' },
      { key: 'LB', value: 'LB-Lebanon' },
      { key: 'LC', value: 'LC-Saint Lucia' },
      { key: 'LI', value: 'LI-Liechtenstein' },
      { key: 'LK', value: 'LK-Sri Lanka' },
      { key: 'LR', value: 'LR-Liberia' },
      { key: 'LS', value: 'LS-Lesotho' },
      { key: 'LT', value: 'LT-Lithuania' },
      { key: 'LU', value: 'LU-Luxembourg' },
      { key: 'LV', value: 'LV-Latvia' },
      { key: 'LY', value: 'LY-Libyan Arab Jamahiriya' },
      { key: 'MA', value: 'MA-Morocco' },
      { key: 'MC', value: 'MC-Monaco' },
      { key: 'MD', value: 'MD-Moldova, Republic Of' },
      { key: 'MF', value: 'MF-Sint Maarten' },
      { key: 'MG', value: 'MG-Madagascar' },
      { key: 'MH', value: 'MH-Marshall Islands' },
      { key: 'MK', value: 'MK-Macedonië' },
      { key: 'ML', value: 'ML-Mali' },
      { key: 'MM', value: 'MM-Myanmar' },
      { key: 'MN', value: 'MN-Mongolia' },
      { key: 'MO', value: 'MO-Macau' },
      { key: 'MP', value: 'MP-Nothern Mariana Island' },
      { key: 'MQ', value: 'MQ-Martinique' },
      { key: 'MR', value: 'MR-Mauritania' },
      { key: 'MS', value: 'MS-Montserrat' },
      { key: 'MT', value: 'MT-Malta' },
      { key: 'MU', value: 'MU-Mauritius' },
      { key: 'MV', value: 'MV-Maldives' },
      { key: 'MW', value: 'MW-Malawi' },
      { key: 'MX', value: 'MX-Mexico' },
      { key: 'MY', value: 'MY-Malaysia' },
      { key: 'MZ', value: 'MZ-Mozambique' },
      { key: 'NA', value: 'NA-Namibia' },
      { key: 'NC', value: 'NC-New Caledonia' },
      { key: 'NE', value: 'NE-Niger' },
      { key: 'NF', value: 'NF-Norfolk Island' },
      { key: 'NG', value: 'NG-Nigaria' },
      { key: 'NI', value: 'NI-Nicaragua' },
      { key: 'NO', value: 'NO-Norway' },
      { key: 'NP', value: 'NP-Nepal' },
      { key: 'NR', value: 'NR-Naura' },
      { key: 'NU', value: 'NU-Niue' },
      { key: 'NZ', value: 'NZ-New Zealand' },
      { key: 'OM', value: 'OM-Oman' },
      { key: 'PA', value: 'PA-Panama' },
      { key: 'PE', value: 'PE-Peru' },
      { key: 'PF', value: 'PF-French Polinesia' },
      { key: 'PG', value: 'PG-Papua New Guinea' },
      { key: 'PH', value: 'PH-Philippines' },
      { key: 'PK', value: 'PK-Pakistan' },
      { key: 'PL', value: 'PL-Poland' },
      { key: 'PM', value: 'PM-Saint-Pierre-et-Miquelon' },
      { key: 'PN', value: 'PN-Pitcairn' },
      { key: 'PR', value: 'PR-Puerto Rico' },
      { key: 'PT', value: 'PT-Portugal' },
      { key: 'PW', value: 'PW-Palau' },
      { key: 'PY', value: 'PY-Paraguay' },
      { key: 'QA', value: 'QA-Qatar' },
      { key: 'RE', value: 'RE-Reunion' },
      { key: 'RO', value: 'RO-Romania' },
      { key: 'RS', value: 'RS-Servie' },
      { key: 'RU', value: 'RU-Russian Federation' },
      { key: 'RW', value: 'RW-Rwanda' },
      { key: 'SA', value: 'SA-Saudi Arabia' },
      { key: 'SB', value: 'SB-Solomon Island' },
      { key: 'SC', value: 'SC-Seychelles' },
      { key: 'SD', value: 'SD-Sudan' },
      { key: 'SE', value: 'SE-Sweden' },
      { key: 'SG', value: 'SG-Singapore' },
      { key: 'SH', value: 'SH-Saint Helena' },
      { key: 'SI', value: 'SI-Slovenia' },
      { key: 'SJ', value: 'SJ-Svalbard And Jan Mayen' },
      { key: 'SK', value: 'SK-Slovakia' },
      { key: 'SL', value: 'SL-Sierra Leone' },
      { key: 'SM', value: 'SM-San Marino' },
      { key: 'SN', value: 'SN-Senegal' },
      { key: 'SO', value: 'SO-Somalia' },
      { key: 'SR', value: 'SR-Suriname' },
      { key: 'ST', value: 'ST-Sao Tome And Principe' },
      { key: 'SV', value: 'SV-El Salvador' },
      { key: 'SY', value: 'SY-Syrian Arab Republic' },
      { key: 'SZ', value: 'SZ-Swaziland' },
      { key: 'TC', value: 'TC-Turks And Caicos Island' },
      { key: 'TD', value: 'TD-Chao' },
      { key: 'TF', value: 'TF-French Southern Territories' },
      { key: 'TG', value: 'TG-Togo' },
      { key: 'TH', value: 'TH-Thailand' },
      { key: 'TJ', value: 'TJ-Tajikistan' },
      { key: 'TK', value: 'TK-Tokelau' },
      { key: 'TM', value: 'TM-Turkmenistan' },
      { key: 'TN', value: 'TN-Tunisia' },
      { key: 'TO', value: 'TO-Tonga' },
      { key: 'TP', value: 'TP-East Timor' },
      { key: 'TR', value: 'TR-Turkey' },
      { key: 'TT', value: 'TT-Trinidad And Tobaco' },
      { key: 'TV', value: 'TV-Tuvalu' },
      { key: 'TW', value: 'TW-Taiwan, Province Of China' },
      { key: 'TZ', value: 'TZ-Tanzania, United Republic Of' },
      { key: 'UA', value: 'UA-Ukraine' },
      { key: 'UG', value: 'UG-Uganda' },
      { key: 'UM', value: 'UM-United States Minde Outlying Island' },
      { key: 'US', value: 'US-United States' },
      { key: 'UY', value: 'UY-Uruguay' },
      { key: 'UZ', value: 'UZ-Uzbekistan' },
      { key: 'VA', value: 'VA-Vatican City State (Holy See)' },
      { key: 'VC', value: 'VC-Saint Vincint And The Grenadines' },
      { key: 'VE', value: 'VE-Venezuela' },
      { key: 'VG', value: 'VG-Virgin Island (British)' },
      { key: 'VI', value: 'VI-Virgin Island (Us)' },
      { key: 'VN', value: 'VN-Vietnam' },
      { key: 'VU', value: 'VU-Vanuatu' },
      { key: 'WF', value: 'WF-Wallis And Futura Island' },
      { key: 'WS', value: 'WS-Samoa' },
      { key: 'XF', value: 'XF-Estland' },
      { key: 'XK', value: 'XK-Kosovo' },
      { key: 'XL', value: 'XL-Letland' },
      { key: 'YE', value: 'YE-Yemen' },
      { key: 'YT', value: 'YT-Mayotte' },
      { key: 'YU', value: 'YU-Yugoslavia' },
      { key: 'ZA', value: 'ZA-South Africa' },
      { key: 'ZM', value: 'ZM-Zambia' },
      { key: 'ZR', value: 'ZR-Zaire' },
      { key: 'ZW', value: 'ZW-Zimbabwe' }
    ];
  }

  static getAllowedCountryCodesForAddress() {
    return [
      'AT', // Oostenrijk
      'BE', // België
      'BG', // Bulgarije
      'HR', // Kroatië
      'CY', // Cyprus
      'CZ', // Tsjechië
      'DK', // Denemarken
      'EE', // Estland
      'FI', // Finland
      'FR', // Frankrijk
      'DE', // Duitsland
      'GR', // Griekenland
      'HU', // Hongarije
      'IS', // IJsland
      'IE', // Ierland
      'IT', // Italië
      'LV', // Letland
      'LI', // Liechtenstein
      'LT', // Litouwen
      'LU', // Luxemburg
      'MT', // Malta
      'NL', // Nederland
      'NO', // Noorwegen
      'PL', // Polen
      'PT', // Portugal
      'RO', // Roemenië
      'SK', // Slowakije
      'SI', // Slovenië
      'ES', // Spanje
      'SE', // Zweden
      'GB', // Verenigd Koninkrijk
      'CH'  // Zwitserland
    ];
  }
}
