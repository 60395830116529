import ApiHelper from '@/helpers/api';
import Document from '@/constants/document';

export default {
  GetDownloadUrl (document) {
    const endpoint = Document.Endpoints.Download();
    const params = ApiHelper.toQueryString(document);
    return `${endpoint}?${params}`;
  }
};
