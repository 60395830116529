<template>
  <transition appear>
    <div class="relative" v-if="hasSelectedBasicInsurance && visibleOnCurrentPage">
      <!-- DON'T TOUCH style property -->
      <div class="absolute" :style="`top: ${top}px; height: ${height};`"> 
        <div
          v-if="!$screen.xs"
          class="floatingpremium"
          :class="shouldShowAnimation ? 'active-anim' : ''"
          @click="isOpen = !isOpen"
        >
          <button
            class="btn btn-accent d-flex align-items-center highlight-anim"
            :class="isOpen ? 'open' : ''"
          >
            <div class="col-2 pl-1">
              <Chevron
                bottom
                :size="20" 
                class="chevron" 
                :class="[(isOpen && !$screen.xs) || (!isOpen && $screen.xs) ? rotatedClass : '', !isOpen ? 'slide-down-anim' : '']"
              />
            </div>
            <div class="align-self-start text-left pl-0 flex-grow-1">
              <div class="div-text-sm no-wrap">{{ $tExistsOrDefault('calculatePremium.premiumOverview.button.totalPremium') }}</div>
              <div class="div-text-xs mt-n1">{{ $tExistsOrDefault(`calculatePremium.premiumOverview.button.term.${paymentTerm}`) }}</div>
            </div>
            <div class="col text-right div-text-lg single-pulse-anim no-wrap">
              {{ formatCurrency(paymentTermWithNetDiscount(paymentTerm).paymentTermNetPremiumWithOwnRiskDiscountTotal) }}
            </div>
          </button>
          <div
            v-show-slide="isOpen"
            @click.stop
          >
            <InsuranceOverview
              @onClose="hide()"
              class="popover-custom"
            />
          </div>
        </div>
        <VDropdown
          v-if="$screen.xs"
          placement="bottom-end"
          :triggers="['click']"
          :autoHide="false"
          :disabled="false"
          theme="oaz"
          ref="popper"
          distance="0"
          class="mobile floatingpremium"
          :class="shouldShowAnimation ? 'active-anim' : ''"
          :positioningDisabled="$screen.xs"
          :shown.sync="isOpen"
        >
          <button
            class="btn btn-accent d-flex align-items-center mobile highlight-anim"
            :class="{'open': isOpen}"
          >
            <div class="col-2 pl-1">
              <Chevron
                :size="20" :color="'var(--color-white)'"
                class="chevron"
                :class="[(isOpen && !$screen.xs) || (!isOpen && $screen.xs) ? rotatedClass : '']"
              />
            </div>
            <div class="flex-grow-1 ml-auto align-self-start text-left pl-0">
              <div class="div-mobile-text-xs">{{ $tExistsOrDefault('calculatePremium.premiumOverview.button.totalPremium') }}</div>
              <div class="div-mobile-text-sm mt-n1 single-pulse-anim">{{ formatCurrency(paymentTermWithNetDiscount(paymentTerm).paymentTermNetPremiumWithOwnRiskDiscountTotal) }}</div>
            </div>
          </button>
          <template
            #popper="{ hide }"
            class="v-popper--theme-oaz"
          >
            <InsuranceOverview
              @onClose="hide()"
              class="popover-custom"
            />
          </template>
        </VDropdown>
      </div>
    </div>
  </transition>
</template>
<script>
import { formatCurrency2 } from '@/helpers/formatCurrency';
import { mapGetters } from 'vuex';
import InsuranceOverview from '@/components/shared/2.0-import/InsuranceOverview.vue';
import PaymentTermHelpers from '@/helpers/payment-term';
import Chevron from '@/assets/icons/Chevron.vue';
import { sum, map } from 'lodash-es';

export default {
  name: 'FloatingPremium',
  components: {
    InsuranceOverview,
    Chevron
  },
  data() {
    return {
      formatCurrency: formatCurrency2,
      rotatedClass: 'rotated',
      height: '90vh',
      top: 10,
      isOpen: false,
      paymentTermWithNetDiscount: PaymentTermHelpers.paymentTermWithNetDiscount,
      shouldShowAnimation: false
    };
  },
  computed: {
    ...mapGetters({
      nettoPremiums: 'nettoPremiums',
      insured: 'insured',
      paymentTerm: 'selectedPaymentTermInConfiguration'
    }),
    hasSelectedBasicInsurance() {
      return this.insured.some(i => i.chosenInsurances.some(c => c.packageTypeForWebApplications === 'MB'));
    },
    visibleOnCurrentPage() {
      if (this.$route.name === 'configuration' && parseInt(this.$route.params.activationStep) === 4) return false;
      return this.$route.name === 'configuration' || this.$route.name === 'personal_data' || this.$route.name === 'personal_extra_data';
    },
    nettoPremiums () {
      return this.$store.getters.nettoPremiums;
    },
    totalBasePremium() {
      return sum(map(this.nettoPremiums.persons, person => person.premiums.totalNettoPremium + person.premiums.totalDiscount));
    }
  },
  methods: {
    async recalculateHeight() {
      await this.$nextTick();
      this.height = document.getElementById('floatingPremiumHeightTarget').scrollHeight && this.$screen.gt.xs
        ? `${document.getElementById('floatingPremiumHeightTarget').scrollHeight - this.top}px` 
        : this.height;
    },
    showAnimation() {
      this.shouldShowAnimation = true;
      setTimeout(() => { this.shouldShowAnimation = false }, 1200);
    }
  },
  watch:{
    async $route () {
      await this.$nextTick();
      this.isOpen = false;
      this.recalculateHeight();
    },
    totalBasePremium() {
      this.showAnimation();
    },
    paymentTerm() {
      this.showAnimation();
    },
    isOpen() {
      this.$emit('activeFloatingPremium', this.isOpen);
    }
  }, 
  async updated() {
    this.recalculateHeight();
  }
};
</script>
<style lang="scss" scoped>
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
:not(.app-mobile) .floatingpremium {
  position: -webkit-sticky;
  position: sticky;
  top: 8px;
  z-index: 10;
  pointer-events: auto;
}
.btn-accent {
  width: 333px;
  min-height: 54px;
  border-radius: var(--border-radius);
  background-color: var(--color-primary-900);
  padding: 5px;
  color: white;
  &.open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &.mobile {
    height: 65px;
    width: 100%;
    position: fixed;
    bottom: 0px; 
    left: 0;
    right: 0;
    z-index: 10000 !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    &.open {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
  &:focus {
    box-shadow: none;
  }
}
.popover-custom {
  min-width: 333px;
  width: 333px;
  &.mobile {
    margin-bottom: 65px;
    width: 100vw !important;
    height: 93dvh;
    position: fixed;
    top: 0;
    bottom: 0;
  }
}
.div-text-lg {
  font-size: 28px;
}
.div-text-sm {
  font-size: 18px;
}
.div-text-xs {
  font-size: 16px;
  font-family: var(--text-font);
  font-weight: normal;
}
.div-mobile-text-xs {
  height: 20px;
  font-weight: normal;
  font-size: 16px;
  font-family: var(--text-font);
}
.div-mobile-text-sm {
  font-size: 28px;
}
.chevron {
  transition: all 0.2s ease-in;
}
.rotated {
  transform-origin: center;
  transform: rotateZ(-180deg);
}
:not(.app-mobile) {
  .v-enter-active {
    transition: all .5s ease-out;
    will-change: transform;
  }
  .v-leave-active {
    transition: all .5s ease-in;
  }
  .v-enter,
  .v-leave-to {
    transform: translateY(-200px);
  }
}
.no-wrap {
  white-space: nowrap;
}
</style>