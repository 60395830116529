<template>
  <div>
    <p>{{ $tExistsOrDefault('calculatePremium.additionalInsurance.mostChosen') }}</p>
  </div>
</template>
<script>
export default {
  name: 'MostChosenIndicator',
};
</script>
<style lang='scss' scoped>
div{
    background-color: var(--color-positive);
    border-radius: 3px;
    padding:6px;
    p{
        font-family: var(--text-font-semibold);
        margin-bottom: 0px;
        color: white;
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
    }
}
</style>
