const Actions = {
  SET_SETTINGS: 'a.cl.set-settings'
};

const Mutations = {
  SET_SETTINGS: 'm.cl.set-settings',
};

export default {
  Actions,
  Mutations
};
