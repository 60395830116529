<template>
  <div class="block-wrapper">
    <div>
      <H2>{{ $tExistsOrDefault('personal.employer') }}</H2>
      <div class="main-form mt-3">
        <div class="form-group row mx-0">
          <label for="subcontractSelectionInput">{{ $tExistsOrDefault('personal.form.subcontract.label') }}</label>
          <div class="col-12 pl-0" v-if="subcontracts.length > 1">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <select
                v-model="form.targetGroupCode"
                class="form-control active"
                :class="{'error-border': errors[0]}"
                name="industry"
                @change="changeForm"
                id="subcontractSelectionInput"
              >
                <option
                  v-for="option in subcontracts"
                  :key="`subcontract-${option.key}`"
                  :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.subcontract.required') }}
              </span>
            </ValidationProvider>
            <div class="select-arrow">
              <Chevron :size="20" :color="'var(--color-grey-900)'" />
            </div>
          </div>
          <span v-else class="col-12 pl-0">{{ form.targetGroupName }}</span>
        </div>
        <div
          class="form-group"
          v-if="selectedContract.employeeNumberRequired"
        >
          <label for="employeeNumberInput">{{ $tExistsOrDefault('personal.form.employeeNumber.label') }}</label>
          <div class="col-12 pl-0">
            <ValidationProvider
              rules="required|employeeNumber"
              v-slot="{ errors }"
            >
              <input
                id="employeeNumberInput"
                v-model="form.employeeNumber"
                type="text"
                class="form-control"
                :class="{'error-border': errors[0]}"
                maxlength="25"
                @change="changeForm"
              />
              <span v-if="errors[0] === 'invalidEmployeeNumber'" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.employeeNumber.invalid') }}
              </span>
              <span v-if="errors[0] && errors[0] !== 'invalidEmployeeNumber'" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.employeeNumber.required') }}
              </span>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ValidationProvider } from 'vee-validate';
import Contracts from '@/constants/contracts';
import Chevron from '@/assets/icons/Chevron.vue';
import CircleXMark from '@/assets/icons/CircleXMark.vue';
import H2 from '@/components/shared/2.0-import/text/H2.vue';

export default {
  name: 'PersonalDataEmployer',
  components: {
    ValidationProvider,
    Chevron,
    CircleXMark,
    H2
  },
  props: {
    initialForm: Object
  },
  data() {
    return {
      form: { ...this.initialForm }
    };
  },
  computed: {
    ...mapGetters(['selectedContract', 'subcontracts'])
  },
  mounted() {
    this.$store.dispatch(Contracts.Actions.LOAD_SUBCONTRACTS_BY_CONTRACTCODE).then(() => {
      if (this.subcontracts.length === 1) {
        this.form.targetGroupCode = this.subcontracts[0].key;
        this.form.targetGroupName = this.subcontracts[0].value;
        this.changeForm();
      }
    });
  },
  methods: {
    changeForm() {
      this.$emit('formChange', this.form);
    }
  }
};
</script>

<style lang="scss" scoped>

.select-arrow {
  padding: 10px 25px;
}

select.form-control {
  font-size: 16px;
}
</style>
