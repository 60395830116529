<template>
  <div class="row mb-3">
    <div class="col-12 col-md-4">
      <PlainText type="secondary">{{ headerTitle }}</PlainText>
    </div>
    <div class="col-8 col-md-5">
      <PlainText type="primary" :class="{'mobile': $screen.xs}">{{ headerValue }}</Plaintext>
    </div>
    <div class="col-4 col-md-3">
      <div v-if="priceIsFreeOrDiscount">
        <PlainText type="positive" class="text-right">{{ formatPrice }}</PlainText>
      </div>
      <div v-else>
        <PlainText type="primary" class="text-right">{{ formatPrice }}</PlainText>
      </div>
    </div>
  </div>
</template>

<script>
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import formatCurrencyHelper from '@/helpers/formatCurrency';

export default {
  name: 'PersonPremiumCardRow',
  components: {
    PlainText
  },
  props: {
    headerTitle: String,
    headerValue: String,
    price: Number,
    isChild: Boolean
  },
  computed: {
    formatPrice() {
      if (this.isChild) return null;
      return this.price === 0 ? this.$tExistsOrDefault('price.freeInCapitals') : this.formatCurrency(this.price);
    },
    priceIsFreeOrDiscount() {
      return this.price === 0 || this.price < 0;
    }
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale() ? this.$i18n.locale() : 'NL');
    }
  }

}
</script>

<style lang="scss" scoped>
.mobile {
  padding-left: 15px;
}
</style>