<template>
 <div class="d-flex">
    <div v-if="isActive" class="pill">
      {{ stepName }}
    </div>
    <div class="circle" :class="{'circle-disabled': showCircleInGrey, 'circle-hide': hideActiveCircle}">
      <StepCheck v-if="showCheckIcon" class="check" />
      <div v-else>
        {{ stepNumber }}
      </div>
    </div>
  </div>

</template>

<script>
import StepCheck from '@/assets/icons/StepCheck.vue';

export default {
  name: 'StepsMobile',
  components: {
    StepCheck
  },
  props: {
    stepNumber: Number,
    stepName: String,
    isVisitedOrActive: Object,
    maxSteps: Number,
    currentStep: Number
  },
  computed: {
    isActive() {
      return this.isVisitedOrActive.isActive;
    },
    isVisited() {
      return this.isVisitedOrActive.isVisited;
    },
    showCheckIcon() {
      return !this.isActive && this.isVisited;
    },
    showCircleInGrey() {
      return !this.isActive && !this.isVisited;
    },
    hideActiveCircle() {
      return this.isActive;
    }
  }
};
</script>

<style lang="scss" scoped>
.pill {
  height: 25px;
  width: auto;
  border-radius: 15px;
  padding: 1px 10px;
  margin-right: 7px;
  font-family: var(--text-font);
  font-size: 14px;
  background-color: var(--color-primary-900);
  border: solid 1px var(--color-primary-900);
  color: var(--color-white);
}
.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  padding: 1px 7px;
  margin-right: 7px;
  font-family: var(--text-font);
  font-size: 14px;
  background-color: var(--color-primary-900);
  border: solid 1px var(--color-primary-900);
  color: var(--color-white);
  &.circle-disabled {
    background-color: var(--color-grey-400);
    border: solid 1px var(--color-grey-400);
    color: var(--color-white);
  }
  &.circle-hide {
    display: none;
  }
}
.check {
  margin-top: -4px;
  margin-left: -6px;
}
</style>