<template>
  <Teleport
    to="#app-base"
    v-show="modelValue"
  >
    <FullscreenOverlay
      :value="modelValue"
    >
      <div class="mw-100vw">
        <CloseButtonStickyHeader @close="onClose">
          <TitleAndParagraph
            :title="`${$tExistsOrDefault('calculatePremium.downloads.title')} ${product.title}`"
            :paragraph="$tExistsOrDefault('calculatePremium.downloads.paragraph')"
          />
          <div>
            <nav class="mb-1">
              <div
                class="nav"
                id="nav-tab"
                role="tablist"
                :class="{'d-flex flex-column': $screen.lt.sm}"
              >
                <div
                  v-for="iframe in iframeData"
                  :key="iframe.ref"
                >
                  <button
                    v-if="iframe.src"
                    class="nav-link"
                    :class="{'show active': iframe.ref === activeRef}"
                    role="tab"
                    @click="setActiveTab(iframe.ref)"
                  >
                    {{ iframe.title }}
                  </button>
                </div>
              </div>
            </nav>
            <div class="d-flex justify-content-center">
              <div
                class="tab-pane fade"
                :class="{'show active': iframe.ref === activeRef, 'w-75': $screen.gt.sm, 'w-100': !$screen.gt.sm}"
                v-show="iframe.ref === activeRef"
                v-for="iframe in iframeData"
                :key="iframe.ref"
              >
                <iframe
                  style="height:75vh;"
                  width="100%"
                  height="100%"
                  :id="iframe.ref"
                  :ref="iframe.ref"
                  :src="iframe.type === 'pdf' ? iframe.src+'#view=FitH&toolbar=0&pagemode=bookmarks' : iframe.src"
                  :title="iframe.title"
                />
              </div>
            </div>
          </div>
        </CloseButtonStickyHeader>
      </div>
    </FullscreenOverlay>
  </Teleport>
</template>

<script>
import TitleAndParagraph from '@/components/shared/2.0-import/TitleAndParagraph.vue';
import FullscreenOverlay from '@/components/shared/2.0-import/FullscreenOverlay.vue';
import DocumentUtils from '@/utils/document.js';
import Teleport from 'vue2-teleport';
import CloseButtonStickyHeader from '@/components/shared/2.0-import/CloseButtonStickyHeader.vue';

export default {
  name: 'IFramesOverlay',
  components: {
    TitleAndParagraph,
    FullscreenOverlay,
    Teleport,
    CloseButtonStickyHeader
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    product: Object,
  },
  data() {
    return {
      activeRef: '',
    };
  },
  computed: {
    iframeData() {
      return [
        {
          src: this.conditionsDocument(),
          ref: 'insuranceCardRef',
          title: this.$tExistsOrDefault('calculatePremium.downloads.coverageDocument'),
          type: 'pdf',
          numPages: undefined,
        },
        {
          src: this.coverDocument(),
          ref: 'insuranceCardRef2',
          title: this.$tExistsOrDefault('calculatePremium.downloads.coverDocument'),
          type: 'pdf',
        },
        {
          src: this.product.insuranceCardLink,
          ref: 'insuranceCardRef3',
          title: this.$tExistsOrDefault('calculatePremium.downloads.insuranceCard'),
          type: 'iframe',
        },
      ];
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    setActiveTab(iframeRef) {
      this.activeRef = iframeRef;
    },
    onClose() {
      this.modelValue = false;
    },
    coverDocument() {
      if (!this.product.documentConditionsPolicy) {
        return null;
      }
      return DocumentUtils.GetDownloadUrl(
        this.product.documentConditionsPolicy
      );
    },
    conditionsDocument() {
      if (!this.product.documentInsurancePolicy) {
        return null;
      }
      return DocumentUtils.GetDownloadUrl(this.product.documentInsurancePolicy);
    },
  },
  watch: {
    modelValue(v) {
      if (v) this.activeRef = this.iframeData.find((i) => !!i.src)?.ref;
      else this.activeRef = undefined;
    },
  },
  beforeMount() {
    this.activeRef = this.iframeData.find((i) => !!i.src)?.ref;
  },
  beforeDestroy() {
    this.modelValue = false;
  },
};
</script>

<style lang='scss' scoped>
.mw-100vw {
  max-width: 100vw;
}
nav {
  border-bottom: 2px solid var(--color-primary);
}
.nav-link {
  background-color: white;
  min-width: 10rem;
  border: none;
  margin-right: 2em;
  &.active {
    font-weight: bold;
    color: var(--color-primary);
    border-bottom: 3px solid var(--color-primary);
  }
  &:hover {
    background-color: var(--color-grey-200);
  }
}
iframe {
  border: 1px solid rgba(1, 1, 1, 0.1);
}
.tp {
  max-width: 700px;
}
</style>
