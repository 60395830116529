<template>
  <div class="d-flex flex-column">
    <CloseButton class="align-self-end" v-if="modalData.showClose" @click.native="handleClose()" />

    <div class="modal-header">
      <H1>{{ modalData.title }}</H1>
    </div>

    <div class="modal-body">
      <PlainText v-if="modalData.text" v-html="modalData.text" />
      <PlainText v-if="modalData.text2" v-html="modalData.text2" class="mt-2" />
      <div v-if="modalData.formComponent">
        <component @formSubmitted="handleClose" :is="modalData.formComponent" />
      </div>
    </div>

    <div v-if="modalData.buttons?.length > 0" class="modal-buttons pt-3 d-flex">
      <Button
        v-for="(button, index) in modalData.buttons" :key="index"
        :layoutType="button.isPrimary ? 'primary' : 'secondary'"
        @click.native="button.action"
      >
        {{ button.text }}
      </Button>
    </div>
  </div>
</template>

<script>
import Modal from '@/constants/modal';
import EmailFormModal from './EmailFormModal.vue';
import CloseButton from '@/components/shared/button/CloseButton.vue';
import H1 from '@/components/shared/2.0-import/text/H1.vue';
import Button from '@/components/shared/button/Button.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';

export default {
  components: {
    CloseButton,
    EmailFormModal,
    H1,
    Button,
    PlainText
  },
  props: {
    modalData: Object
  },
  methods: {
    handleClose() {
      this.$store.dispatch(Modal.Actions.CLOSE);
      if (this.modalData.closeAction) {
        this.modalData.closeAction();
      }
    }
  }
};
</script>

<style scoped lang="scss" >
.modal-header {
  border-radius: 0px;
  border: 0;
}

.modal-buttons {
  padding-left: 1rem;
  flex-flow: wrap;
  gap: 15px;
}
</style>