<template>
  <div class="position-relative">
    <input
      @focus="hasFocus = true" @blur="blur()"
      :placeholder="placeholder"
      v-model="modelValue" class="form-control" />

    <DropdownList
      v-model="dropdownListSelect" 
      :shouldFilterByValue="shouldFilterByValue"
      :minimumLengthShowDropdown="minimumLengthShowDropdown" 
      :openDropdown="hasFocus" 
      :useTranslationKeys="false"
      :options="optionsValueText"
    />
  </div>
</template>

<script>
import DropdownList from '@/components/shared/form/DropdownList.vue';

export default {
  name: 'InputField',
  components: {
    DropdownList
  },
  emits : ['selected-value'],
  props: {
    useTranslationKeys: {
      type: Boolean,
      default: true
    },
    value: null,
    placeholder: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    isOptionsStringArray: {
      type: Boolean,
      default: true
    },
    shouldFilterByValue: {
      type: Boolean,
      default: function () { return this.isOptionsStringArray }
    },
    minimumLengthShowDropdown: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      hasFocus: false
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);

        if (!v){
          this.$emit('selected-value', null);
        }
      },
    },
    dropdownListSelect : {
      get() {
        return this.isOptionsStringArray ? this.modelValue : this.optionsValueText.find(x => x.text === this.modelValue)?.value
      },
      set(v) {
        this.modelValue = this.isOptionsStringArray ? v : this.optionsValueText.find(x => x.value === v).text;

        this.$emit('selected-value', v);
      }
    },
    optionsValueText() {
      return this.isOptionsStringArray ? this.options.map(x => ({ value: x, text: x})) : this.options;
    }
  },
  methods: {
    blur() {
      this.hasFocus = false;
      this.$emit('blur');
    }
  },
};
</script>

<style lang='scss' scoped>
input{
  margin-bottom: 0;
}
</style>