import Vue from 'vue';

import ContractsUtils from '@/utils/contracts';

import Contracts from '@/constants/contracts';
import Logo from '@/constants/logos';
import BusinessRule from '@/constants/business-rule';

import CalculatePremiumHelpers from '@/helpers/calculate-premium';
import router from '@/router';

const state = {
  contracts: [],
  beforeChangeContracts: [],
  contractsLoading: {
    'LOAD_ALL': false,
    'LOAD_BY_LANDINGSNAME': false,
    'SEARCH': false
  },
  selectedContract: null,
  contractSearch: false,
  selectedContractCode: null,
  subcontracts: [],
  isEjw: false,
  lastPolicyYearAvailable: false,
  reimbursements: [],
  selectedCollectivityChoice : null
};

const getters = {
  contracts: state => state.contracts,
  beforeChangeContracts: state => state.beforeChangeContracts,
  contractsLoading: state => state.contractsLoading,
  selectedContract: state => state.selectedContract,
  selectedContractCode: state => state.selectedContractCode,
  contractSearch: state => state.contractSearch,
  subcontracts: state => state.subcontracts,
  isEjw: state => state.isEjw,
  lastPolicyYearAvailable: state => state.lastPolicyYearAvailable,
  reimbursements: state => state.reimbursements,
  selectedCollectivityChoice: state => state.selectedCollectivityChoice
};

const actions = {
  [Contracts.Actions.LOAD_BY_LANDINGSNAME]({ commit }, landingsName) {
    return ContractsUtils.LoadByLandingsname(landingsName)
      .then((response) => commit(Contracts.Mutations.SET, response.data));
  },

  [Contracts.Actions.SEARCH]({ commit }, data) {
    return ContractsUtils.Search(data)
      .then((response) => {
        commit(Contracts.Mutations.SET, response.data);
      });
  },

  [Contracts.Actions.LOAD_BY_SELECTED_CONTRACT]({ commit, dispatch }, contract) {
    // TODO: Is this still used?
    dispatch(Logo.Actions.LOAD_BY_CONTRACT_CODE, contract);
    ContractsUtils.LoadBySelectedContract(contract)
      .then((response) => commit(Contracts.Mutations.SET_SELECTED, response.data));
  },

  [Contracts.Actions.CLEAR_SEARCH]({ commit }) {
    commit(Contracts.Mutations.CLEAR_SEARCH);
  },

  [Contracts.Actions.LOAD_SUBCONTRACTS_BY_CONTRACTCODE]({ commit }) {
    return ContractsUtils.LoadSubcontractsByContractCode(this.getters.selectedContractCode, this.getters.lastPolicyYear)
      .then(response => {
        commit(Contracts.Mutations.SET_SUBCONTRACTS, response.data);
      });
  },

  [Contracts.Actions.LOAD_CONTRACT]({ commit }, lastPolicyYear) {
    return ContractsUtils.LoadContract(this.getters.selectedContractCode, lastPolicyYear, this.getters.labelSettings.label)
      .then((response) => {
        commit(Contracts.Mutations.SET_CONTRACT, response.data);
      })
      .catch((error) => {
        if(error.response && error.response.status === 404) {
          router.replace({ name: 'notFound', params: { lang: window.location.pathname.split('/')[1] } });
        }
      });
  },

  [Contracts.Actions.LOAD_EJW_INFO]({ commit, getters }, shouldUpdateStartdate = true) {
    if (!getters.selectedContractCode) {
      commit(Contracts.Mutations.SET_EJW_INFO, { isEjw: false, isLastPolicyYearAvailable: false });
    } 
    else {      
      return ContractsUtils.LoadEjwInfo(getters.selectedContractCode)
        .then((response) => {
          commit(Contracts.Mutations.SET_EJW_INFO, response.data);
          if(getters.temporaryStartDate && shouldUpdateStartdate) {
            const startDate = CalculatePremiumHelpers.determineStartDate(response.data.isEjw);
            commit(BusinessRule.Mutations.SET_START_DATE, startDate);
          }
        });
    }
  },

  [Contracts.Actions.LOAD_REIMBURSEMENT_CATEGORIES]({ commit }) {
    return ContractsUtils.LoadReimbursementCategories()
      .then((response) => {
        commit(Contracts.Mutations.SET_REIMBURSEMENTS, response.data);
      });
  },
  [Contracts.Actions.SET_COLLECTIVITY_CHOICE]({ commit }, collectivityChoice) {
    commit(Contracts.Mutations.SET_COLLECTIVITY_CHOICE, collectivityChoice);
  }
};

const mutations = {
  [Contracts.Mutations.SET](state, payload) {
    state.contracts = payload;
  },

  [Contracts.Mutations.SET_SELECTED](state, payload) {
    state.contracts = payload['contractModel'];
  },

  [Contracts.Mutations.SET_SELECTED_ID](state, id) {
    state.selectedContractCode = id;
  },

  [Contracts.Mutations.SET_LOADING](state, payload) {
    // Boilerplate code, but according to the following page more safe: https://eslint.org/docs/rules/no-prototype-builtins
    if (Object.prototype.hasOwnProperty.call(state.contractsLoading, payload.type)) {
      Vue.set(state.contractsLoading, payload.type, payload.value);
      state.contractSearch = true;
    }
  },

  [Contracts.Mutations.CLEAR_SEARCH](state) {
    state.contracts = [];
    state.contractSearch = false;
  },

  [Contracts.Mutations.CLEAR](state) {
    state.contracts = [];
    state.contractsLoading = {
      'LOAD_ALL': false,
      'LOAD_BY_LANDINGSNAME': false,
      'SEARCH': false
    };
    state.selectedContractCode = null;
    state.selectedContract = null;
    state.contractSearch = false;
    state.subcontracts = [];
    state.reimbursements = [];
  },

  [Contracts.Mutations.SET_SUBCONTRACTS](state, payload) {
    state.subcontracts = [];
    for (var key in payload) {
      state.subcontracts.push({
        key: key,
        value: payload[key]
      });
    }
  },

  [Contracts.Mutations.SET_CONTRACT](state, payload) {
    state.selectedContract = payload;
  },

  [Contracts.Mutations.SET_EJW_INFO](state, payload) {
    state.isEjw = payload['isEjw'];
    state.lastPolicyYearAvailable = payload['isLastPolicyYearAvailable'];
  },

  [Contracts.Mutations.SET_REIMBURSEMENTS](state, payload) {
    state.reimbursements = payload;
  },

  [Contracts.Mutations.SET_COLLECTIVITY_CHOICE](state, payload) {
    state.selectedCollectivityChoice = payload;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
