<template>
  <Container>    
    <div class="contract-content" :class="{ 'column-reverse': hasLogoOnTop }" v-if="contractCode && contract !== null">
      <div>
        <div class="contract-header" :class="{ 'mobile' : $screen.xs }" v-if="!isLaefContract">
          <H1 class="d-inline contract-name">{{ contract.contractName }}</H1>
          <PlainText class="d-inline">{{ $tExistsOrDefault('main.collectivityNumber') }} {{ contract.contractCode }}</PlainText>
        </div>
        <div class="contract-usps">
          <div
            v-for="usp in $tExistsOrDefault('main.collectivityAdvantage.usps')" 
            :key="usp"
          >
            <li v-if="usp.trim()">
              <CheckInCircle class="d-inline" />
              <PlainText class="d-inline" v-html="usp" />
            </li>
          </div>
        </div>
      </div>
      
      <Logo class="contract-logo" :contractCode="contractCode" />
    </div>
    <div v-else class="icon-anim d-flex justify-content-center">
      <font-awesome-icon icon="circle-notch" size="4x" spin />
    </div>    
  </Container>
</template>
  
<script>
import Container from '@/components/shared/2.0-import/Container.vue';
import ContractCodes from '@/constants/ContractCodes';

import H1 from '@/components/shared/2.0-import/text/H1.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';

import CheckInCircle from '@/assets/icons/CheckInCircle.vue';

import Logo from '@/components/shared/Logo.vue';
export default {
  name: 'ContractAdvantages',
  props: {
    contractCode: Number,
    contract: Object,
    logoPosition: {
      type: String,
      default: 'right',
      validator: function (value) {
        return ['right', 'top'].includes(value);
      },
    }
  },
  components: {
    Container,
    H1,
    PlainText,
    CheckInCircle,
    Logo
  },
  computed: {
    isLaefContract() {
      return this.contractCode === ContractCodes.Laef;
    },
    hasLogoOnTop() {
      return this.logoPosition === 'top' || this.$screen.xs;
    }
  }
};
</script>
  
<style lang="scss" scoped>
.contract-header {
  margin-bottom: 24px;
}

.contract-header.mobile {
  display: flex;
  flex-direction: column;
}

.contract-name {
  margin-right: 10px;
}

.contract-usps {
  li {
    list-style-type: none;
    display: flex;
    gap: 14px;
  }

  li > * {
    min-width: 23px;
  }

  div:not(:last-child) {
    margin-bottom: 13px;
  }
}

.contract-content {
  display: flex;
  gap: 50px;
}

.contract-content.column-reverse {
  flex-direction: column-reverse;
  gap: 20px;
}
.contract-logo {
  max-width: 150px;
}

.icon-anim {
  animation: appear 0.5s forwards linear;
}
</style>
  