import Zorgzoeker from '@/constants/zorgzoeker';
import ZorgzoekerUtils from '@/utils/zorgzoeker';

const state = {
  cities: []
};

const getters = {
  cities: state => state.cities
};

const actions = {
  [Zorgzoeker.Actions.GET_CITIES]({ commit, state }) {
    if (state.cities.length == 0){
    ZorgzoekerUtils.Get_Cities()
      .then(response => {
        commit(Zorgzoeker.Mutations.SET_CITIES, response.data.map(x => x.name));
      });
    }
  }
};

const mutations = {
  [Zorgzoeker.Mutations.SET_CITIES](state, payload) {
    state.cities = payload;
  }
};

export default {
  namespaced: false,
  getters,
  state,
  actions,
  mutations
};