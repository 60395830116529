<template>
  <RectangleWithShadow :padded="false">
    <LoadingOverlay 
      :showOverlay="isBusinessRulePostLoading"
    > 
      <div
        id="card-content" class="card-width"
      >
        <div id="card-header">
          <CardHeader>{{ $tExistsOrDefault('calculatePremium.ownRisk.title') }}</CardHeader>
          <CardAmountVue>{{ formatCurrency(ownRiskAmount) }}</CardAmountVue>
        </div>
        <PlainText
          class="text-right" 
          :class="{'invisible' : ownRiskAmount === 385}"
        >
          {{ $tExistsOrDefault('calculatePremium.ownRisk.premiumDiscount') }} 
        </PlainText>

        <SelectProductForPersonCheckbox
          v-for="(person, i) in product.persons"
          class="person-checkbox"
          :key="'select-box-for-' + product.productComponentCode + '-' + i"
          :product="product"
          :person="person"
          :showTooltip="false"
          isRadio
        />
      </div>
    </LoadingOverlay>
  </RectangleWithShadow>
</template>

<script>
import SelectProductForPersonCheckbox from '@/components/configuration/SelectProductForPersonCheckbox.vue';
import formatCurrencyHelper from '@/helpers/formatCurrency';
import LoadingOverlay from '@/components/shared/LoadingOverlay.vue';
import { mapGetters } from 'vuex';
import RectangleWithShadow from '@/components/shared/RectangleWithShadow.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import CardHeader from '@/components/shared/2.0-import/text/CardHeader.vue';
import CardAmountVue from '@/components/shared/2.0-import/text/CardAmount.vue';

export default {
  name: 'OwnRiskComponent',
  components: {
    SelectProductForPersonCheckbox,
    LoadingOverlay,
    RectangleWithShadow,
    PlainText,
    CardHeader,
    CardAmountVue
  },
  props: {
    product: Object
  },
  computed: {
    ...mapGetters(['isBusinessRulePostLoading']),
    insuredPersons() {
      return this.$store.getters.insuredPersons;
    },
    agreement() {
      return this.$store.getters.agreement;
    },
    ownRiskAmount() {
      return parseInt(this.$tExistsOrDefault('calculatePremium.ownRisk.ownRiskValue')) + this.product.ownRiskAmount;
    },
    discount() {
      return Math.abs(this.product.ownRiskDiscount);
    }
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale() ? this.$i18n.locale() : 'NL');
    }
  }
};
</script>

<style lang="scss" scoped>

#card-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.card-width {
  width: 267px;
  padding:24px;
}

</style>
