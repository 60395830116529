<template>
  <div class="healtcare-provider-info">
    <div 
      v-for="healthCareProvider in healthCareProviders" 
      :key="healthCareProvider.uniqueId"
      class="healthcareProviderTriangle" 
      @click="$emit('clickHealthCareProvider', healthCareProvider)" 
    >
      <div class="healthcare-provider-content">
        <PlainText class="healthcare-provider-name">{{ healthCareProvider.name }}</PlainText>
        <div class="icon-and-text">
          <HealthcareProviderContracted :year="selectedYear" :contractedType="healthCareProvider.contractType" />
        </div>

        <div class="icon-and-text">
          <Location />
          <PlainText>{{ healthCareProvider.street }} {{ healthCareProvider.housenumber }}, {{ healthCareProvider.zipcode }} {{ healthCareProvider.city }}</PlainText>
        </div>          
      </div>

      <div class="center-chevron">
        <Chevron :size="12" :right="true" :color="chrevonColor()" />
      </div>      
    </div>
  </div>  
</template>
  
<script>
import HealthcareProviderContracted from '@/components/healthcare-provider/HealthcareProviderContracted.vue';
import Chevron from '@/assets/icons/Chevron.vue';
import Location from '@/assets/icons/Location.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';

export default {
  name: 'HealthcareProviderInfo',
  emits: ['clickHealthCareProvider'],
  props: {
    healthCareProviders: [],
    selectedYear: {
      required: true,
      type: Number
    }
  },
  components: {
    HealthcareProviderContracted,
    Chevron,
    Location,
    PlainText,
  },
  methods: {
    chrevonColor() {
			return 'var(--color-primary)';
		}
  }
};
</script>
  
<style scoped lang="scss" >
.healtcare-provider-info {
  cursor: pointer;
}
.healthcareProviderTriangle {
  padding: 15px;
  margin-bottom: 14px;
  border-radius: 3px;
  border: solid 1px var(--color-grey-300);  
  display: flex;
  justify-content: space-between;
}
.healthcare-provider-content {
  > * {
    margin-bottom: 5px;
  }
}
.healthcare-provider-name {
  font-weight: bold;
}
.icon-and-text {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  :first-child{
    flex-shrink: 0;
    height: 22px;
  }
}

.center-chevron {
  align-self: center;
}
</style>