<template>
  <div id="oval" :class="{ 'isActive': isActive }">
  </div>
</template>
    
<script>
const possibleWidthsForSize = [4, 7];

export default {
  name: 'HorizontalScrollOval',
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    size: {
      type: Number,
      required: true,
      validator: function (value) {
        return typeof possibleWidthsForSize[value] !== 'undefined';
      }
    }
  },
  computed: {
    width() {
      return possibleWidthsForSize[this.size] + 'px';
    }
  }
};
</script>
  
<style lang="scss">
#oval {
  border-radius: 100%;
  width: v-bind(width);
  height: v-bind(width);
  background-color: var(--color-grey-300);
  transition: all 0.1s ease-out;
}

#oval.isActive {
  background-color: var(--color-primary-900);
}
</style>
    