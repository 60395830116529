<template>
  <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <circle fill="#23B623" cx="10.5" cy="10.5" r="10.5" />
      <path d="m5.833 10.73 3.017 3.452 6.983-7.349" stroke="#FFF" stroke-linecap="round" stroke-width="2" />
    </g>
  </svg>
</template>
      
<script>

export default {
  name: 'Compensated'
};
</script>