export default class PhoneHelpers {
  /**
   * Normalize a phone number to it can be send in the agreementrequest.
   * @param {String} phoneNumber The phone number to format
   */
  static normalizePhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return phoneNumber;
    }

    return phoneNumber.replace(/\s/g, '');
  }
}
