<template>
  <div class="premium-wrapper">
    <div>
      <p class="premium-from" :style="{visibility: !premiumIsEqualForAllPersons ? 'visible' : 'hidden'}">
        {{ $tExistsOrDefault('calculatePremium.general.premiumFrom') }}
      </p>
      <p v-if="isFree" class="free">
        {{ $tExistsOrDefault('price.freeInCapitals') }}
      </p>
      <p v-else>
        <span class="premium-price">{{ formatCurrency(minPremium) }}</span>
        <span class="premium-period"> {{ $tExistsOrDefault('calculatePremium.general.perMonth') }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import formatCurrencyHelper from '@/helpers/formatCurrency';
import productHelper from '@/helpers/product';
export default {
  name: 'ProductPremium',
  props: {
    product: Object,
  },
  computed: {
    minPremium() {
      return productHelper.minPremium(this.product);
    },
     premiumIsEqualForAllPersons() {
      return productHelper.premiumIsEqualForAllPersons(this.product);
    },
    isFree() {
      return productHelper.isFree(this.product);
    },

  },
  methods:{
    formatCurrency(value) {
      return formatCurrencyHelper(
        value,
        this.$i18n.locale() ? this.$i18n.locale() : 'NL'
      );
    }
  }
};
</script>

<style lang='scss' scoped>
.premium-price {
    font-size: 26px;
    font-family: var(--text-font-semibold);
    color: var(--color-primary);
    letter-spacing: 0;
}
.premium-from{
    font-size: 15px;
    padding-bottom: 0px;
    margin-bottom: 0;
    color: var(--color-primary);
    position: relative;
    top: 8px;
}

.premium-period{
  font-family: var(--text-font);
  font-size: 16px;
  color: var(--color-grey-600);
}

.free {
  display: block;
  font-size: 26px;
  font-family: var(--text-font-semibold);
  color: var(--color-primary-900);
  text-align: center;
}

.premium-wrapper{
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
p{
  margin: 0;
}
</style>
