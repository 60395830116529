<template>
  <button
    :class="layoutType"
    :disabled="this.disabled"
    id="slot-wrapper"
    :type="type"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: "Button",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    layoutType: {
      type: String,
      default: "primary",
      validator: function (value) {
        return ["primary", "secondary", "action"].includes(value);
      },
    },
    type: {
      type: String,
      default: "button",
      validator: function (value) {
        return ["button", "submit", "reset"].includes(value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  padding: 7px 16px 8px;
  border-radius: var(--button-border-radius-top-left)
    var(--button-border-radius-top-right)
    var(--button-border-radius-bottom-right)
    var(--button-border-radius-bottom-left);
  border: none;
  font-size: 15px;
  font-family: var(--text-font);
  user-select: none;
  transition: all 0.2s ease-in-out;
  &[disabled] {
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
  }
}

#slot-wrapper.primary {
  background-color: var(--color-primary-900);
  color: var(--color-white);
  &:hover {
    background-color: var(--color-primary-600);
  }
  &[disabled] {
    background-color: var(--color-grey-400);
  }
  &::v-deep * {
    color: var(--color-white);
  }
}

#slot-wrapper.secondary {
  background-color: var(--color-grey-200);
  color: var(--color-primary-900);
  &::v-deep * {
    color: var(--color-primary-900);
  }
  &:hover {
    background-color: var(--color-grey-100);
  }
  &[disabled] {
    background-color: var(--color-grey-100);
    color: var(--color-grey-500);
    &::v-deep * {
      color: var(--color-grey-500);
    }
  }
}

#slot-wrapper.action {
  background-color: var(--color-accent);
  color: var(--color-grey-900);
  font-family: var(--text-font-semibold);
  &::v-deep * {
    color: var(--color-grey-900);
  }
  &:hover {
    background-color: var(--color-primary-200);
  }
  &[disabled] {
    background-color: var(--color-grey-100);
    color: var(--color-grey-500);
    &::v-deep * {
      color: var(--color-grey-500);
    }
  }
}
</style>
