<template>
  <div class="item__content row2">
    <div class="row">
      <div class="col-md-10"><strong>{{ label }}</strong></div>
      <div class="col-md-2"><span>€ {{ person.netPremiumWithDiscountTotal }}</span></div>
    </div>
  </div>
</template>

<script>
import Dates from '@/helpers/dates';

export default {
  name: 'PremiumCalculation',
  props: {
    person: Object
  },
  computed: {
    agreement () {
      return this.$store.getters.agreement;
    },
    insuredPersons () {
      return this.$store.getters.agreement.insuredPersons;
    },
    label () {
      let result = '';
      switch (this.person.typeOfInsured) {
        case 'H':
          result += this.$tExistsOrDefault('calculatePremium.general.main') + ': ';
          break;
        case 'P':
          result += this.$tExistsOrDefault('calculatePremium.general.partner') + ': ';
          break;
        case 'M':
          result += this.$tExistsOrDefault('calculatePremium.general.child') + ': ';
          break;
      }
      return result + this.person.dateOfBirth;
    }
  }
};
</script>
