<template>
  <div class="section-wrapper">
    <div class="slot-wrapper">
      <slot class="slot" />
    </div>
    <hr
      class="section-separator"
      v-if="horizontalDevider"
    />
  </div>
</template>

<script>
export default {
  name: 'CardSection',
  props: {
    horizontalDevider: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  --spacing: 20px;
  &:not(:first-child) {
    margin-top: var(--spacing);
  }
  &:not(:last-child) {
    margin-bottom: var(--spacing);
  }
  .section-separator {
    color: var(--tertiary-border-color);
    margin-top: var(--spacing);
  }
}

::v-deep .slot-wrapper {
  text-align: center;
}
</style>