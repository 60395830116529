<template>
  <svg 
    width="20" 
    height="20" 
    viewBox="0 0 20 20" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
        <path 
          d="M20 20H0V0h20z" 
        />
        <path 
          d="m15.167 15-5-5 5-5m-10 10 5-5-5-5" 
          stroke="currentColor" 
          stroke-width="2" 
          stroke-linecap="round" 
          stroke-linejoin="round" 
        />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'DisabledPackageClose'
};
</script>
