import Address from '@/constants/address';

const state = {
  disableAddress: false
};

const getters = {
  disableAddress: state => state.disableAddress
};

const actions = {
  [Address.Actions.SET_DISABLED_ADDRESS]({ commit }, value) {
    commit(Address.Mutations.SET_DISABLED_ADDRESS, value);
  }
};

const mutations = {
  [Address.Mutations.SET_DISABLED_ADDRESS](state, payload) {
    state.disableAddress = payload;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};