<template>
  <div>
    <div>
      <Checkbox
        :value="!!insuredPartner"
        :checkBoxSize="24"
        @change="handlePersonChange"
        :class="{'mb-3' : !insuredPartner}"
      >
        <PlainText class="label-text">{{ $tExistsOrDefault('insured.partner') }}</PlainText>
      </Checkbox>
      <div class="row mb-3" v-if="insuredPartner">
        <PlainText class="col-12 mt-2 col-md-3  mb-2" id="input-label">{{ $tExistsOrDefault('insured.dateOfBirth') }}</PlainText>
        <ManualDateInput
          class="col"
          v-model="partnerDateOfBirth" 
          :minDate="partnerValidDateRange.to"
          :maxDate="partnerValidDateRange.from"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { addYears } from 'date-fns';
import { mapGetters } from 'vuex';

import Modal from '@/constants/modal';
import BusinessRule from '@/constants/business-rule';
import CalculatePremiumHelpers from '@/helpers/calculate-premium';

import ManualDateInput from '@/components/shared/2.0-import/ManualDateInput.vue';

import ModalDataFactory from '@/components/modals/ModalDataFactory';
import Checkbox from '@/components/shared/Checkbox.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';

export default {
  name: 'CalculatePremiumInsuredPartner',
  components: {
    ManualDateInput,
    Checkbox,
    PlainText
  },
  computed: {
    ...mapGetters(['insured', 'startDate', 'getDateOfBirth']),
    insuredPartner() {
      return this.insured.find(i => i.typeOfInsured === 'P');
    },
    partnerValidDateRange() {
      return CalculatePremiumHelpers.partnerDisabledDates(
        this.startDate
      );
    },
    partnerDateOfBirth: {
      get() {
        return this.getDateOfBirth(this.insuredPartner.id);
      },
      set(value) {
        let calcDate = addYears(value, 18);

        if (this.startDate && calcDate.getFullYear() === this.startDate.getFullYear() && calcDate > this.startDate) {
          this.$store.dispatch(Modal.Actions.OPEN, { 
            modalData: ModalDataFactory.create(
              this.$tExistsOrDefault('insured.PremiumObliged.title'), 
              this.$tExistsOrDefault('insured.PremiumObliged.message'))
          });
        }

        this.$store.dispatch(BusinessRule.Actions.SET_DATE_OF_BIRTH_FOR_INSURED, {
          insuredId: this.insuredPartner.id,
          dateOfBirth: value
        });
      }
    }
  },
  methods: {
    handlePersonChange() {
      if(this.insuredPartner) {
         this.$store.commit(BusinessRule.Mutations.REMOVE_INSURED, this.insuredPartner.id);
      } else {
        this.$store.commit(BusinessRule.Mutations.ADD_INSURED, { typeOfInsured: 'P' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.label-text{
  margin-left: 13px;
}

.row{
  margin-left: 22px;
}
</style>
