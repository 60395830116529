import store from '@/store';
import { api } from '@/utils/api';

import BusinessRule from '@/constants/business-rule';

const isLoading = function (type, value) {
  store.commit(BusinessRule.Mutations.SET_LOADING, {
    type: type,
    value: value
  });
};

export default {
  Post(businessRule) {
    isLoading('POST', true);

    const endpoint = BusinessRule.Endpoints.Post();

    return api.post(endpoint, businessRule)
      .finally(() => isLoading('POST', false));
  },

  CalculatePremiums(request) {
    isLoading('POST', true);

    const endpoint = BusinessRule.Endpoints.CalculatePremiums();

    return api.post(endpoint, request)
      .finally(() => isLoading('POST', false));
  },

  CalculatePaymentTerms(agreement) {
    isLoading('POST', true);

    const endpoint = BusinessRule.Endpoints.CalculatePaymentTerms();

    return api.post(endpoint, agreement)
      .finally(() => isLoading('POST', false));
  },

  SubmitAgreement(agreement) {
    isLoading('POST', true);

    const endpoint = BusinessRule.Endpoints.SubmitAgreement();

    return api.post(endpoint, agreement)
      .finally(() => isLoading('POST', false));
  },

  LoadPaymentInfoBySubcontract (contractCode, skoCode, lastPolicyYear) {
    isLoading('LOAD_PAYMENT_INFO_BY_SUBCONTRACT', true);

    const endpoint = BusinessRule.Endpoints.LoadPaymentInfoBySubcontract(contractCode, skoCode, lastPolicyYear);

    return api.get(endpoint, { })
      .finally(() => isLoading('LOAD_PAYMENT_INFO_BY_SUBCONTRACT', false));
  }
};
