import store from '@/store';

export default {
  monthlyPremium(term) {
    return this.totalBasePremium(term) / term;
  },
  totalBasePremium(term) {
    return this.paymentTermWithNetDiscount(term)
      .paymentTermNetPremiumWithOwnRiskDiscountTotal;
  },
  paymentTermDiscount(term) {
    let yearAmountWithoutDiscount = this.paymentTermWithNetDiscount(1).paymentTermNetPremiumWithOwnRiskDiscountTotal * term;
    return yearAmountWithoutDiscount - this.paymentTermWithNetDiscount(term).paymentTermNetPremiumWithOwnRiskDiscountTotal;
  },
  paymentTermWithNetDiscount(term) {
    let paymentTermWithNetDiscount = store.getters.agreement.paymentTermNetDiscounts.find(pt => pt.paymentTerm === term);
    if (paymentTermWithNetDiscount) return paymentTermWithNetDiscount;
    paymentTermWithNetDiscount = store.getters.agreement.paymentTermNetDiscounts.find(pt => pt.paymentTerm === 0);
    if (paymentTermWithNetDiscount) return paymentTermWithNetDiscount;
    else return { paymentTermNetPremiumWithOwnRiskDiscountTotal: 0 };
  }
};