<template>
  <ExtraInformationContainer
    alwaysUsePopup 
    :text="text"
    :title="title"
    class="pointer mr-1 d-inline"
  >
    <svg
      class="info-icon"
      viewBox="0 0 24 29" xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid meet"
    >
      <g transform="translate(0 .75)" fill="none" fill-rule="evenodd">
        <circle
          stroke="currentColor" cx="12"
          cy="14.25"
          r="11.5"
        />
        <text
          font-family="SourceSansPro-Bold, Source Sans Pro" font-size="22"
          font-weight="bold"
          fill="currentColor"
        >
          <tspan x="8.928" y="22">i</tspan>
        </text>
      </g>
    </svg>
  </ExtraInformationContainer>
</template>

<script>
import ExtraInformationContainer from '@/components/shared/ExtraInformationContainer.vue';

export default {
  name: 'InformationIcon',
  components: { ExtraInformationContainer },
  props: {
    text: {
      type: String,
      required: true
    },
    title : {
      type: String,
      required: false
    }
  }
};
</script>

<style lang='scss' scoped>
.info-icon {
  width: 26px;
  aspect-ratio: 1;
  color: var(--color-primary);
}
</style>
