import { subYears } from 'date-fns';
import DateHelper from '@/helpers/dates';


export default {
  getAdultInsureds (startDate, persons) {
    let minimumDate = subYears(startDate, 18);
    return persons.filter(p => DateHelper.dateStringToDate(p.dateOfBirth) <= minimumDate);
  },

  isAdult (startDate, dateOfBirth) {
    let minimumDate = subYears(startDate, 18);
    return dateOfBirth <= minimumDate;

  },

  isActive (product, agreement) {
    let result = '';
    let minimumDate = subYears(DateHelper.dateStringToDate(agreement.startDate), 18);

    agreement.insuredPersons.forEach(function (item) {
      if(product.packageTypeForWebApplications !== 'TA' || product.packageTypeForWebApplications === 'TA' && DateHelper.dateStringToDate(item.dateOfBirth) <= minimumDate) {
        let chosenProduct = item.chosenInsurances.find(ci => ci.productComponentCode === product.productComponentCode && ci.packageTypeForWebApplications === product.packageTypeForWebApplications);
        if (chosenProduct !== undefined) {
          result = 'active';
        }
      }
    });

    return result;
  },

  // Legacy, unsure where used. Please use the isFree method from the product.js helper file.
  isFree (product) {
    return product.persons.every((person) => (person.grossPremium === 0 || person.percentagePaidByEmployer === 100));
  },

  validateInsurance (insuredPersons, type) {
    let valid = true;
    insuredPersons.forEach(function (item) {
      let ap = item.chosenInsurances.find(ci => ci.packageTypeForWebApplications === type);
      if (ap === undefined) {
        valid = false;
      }
    });
    return valid;
  },

  validateFinalAgreementModel (agreement, dentalInsurancesAvailable, additionalInsurancesAvailable) {
    let valid = true;
    agreement.insuredPersons.forEach(function (item) {
      let mb = item.chosenInsurances.find(ci => ci.packageTypeForWebApplications === 'MB');
      let ap = item.chosenInsurances.find(ci => ci.packageTypeForWebApplications === 'AP');

      if ((ap === undefined && additionalInsurancesAvailable) || mb === undefined) {
        valid = false;
      }
    });

    this.getAdultInsureds(DateHelper.dateStringToDate(agreement.startDate), agreement.insuredPersons).forEach(function (item) {
      let ta = item.chosenInsurances.find(ci => ci.packageTypeForWebApplications === 'TA');
      if (ta === undefined && dentalInsurancesAvailable) {
        valid = false;
      }
    });
    return valid;
  },

  hasMedicalSelection (agreement) {
    let persons = agreement.insuredPersons;

    let medicalSelection = false;
    persons.forEach(function (item) {
      let insuranceWithMedicalSelection = item.chosenInsurances.find(ci => ci.hasMedicalSelection);
      if (insuranceWithMedicalSelection !== undefined) {
        medicalSelection = true;
      }
    });
    return medicalSelection;
  },
  hasFilledMedicalSelection(medicalSelection) {
    return (medicalSelection.question1 !== null &&
      medicalSelection.question2 !== null &&
      medicalSelection.question3 !== null &&
      medicalSelection.additionalInfoQuestion3 !== null &&
      medicalSelection.question4 != null &&
      medicalSelection.additionalInfoQuestion4 !== null &&
      medicalSelection.question5.length !== 0);
  }
};
