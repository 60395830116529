<template>
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path d="m6 6 7.582 7.382M14 6l-7.582 7.382" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'Delete'
}
</script>