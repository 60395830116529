<template>
  <LinkText 
    class="email-flex"
    :class="color"
    @click.native="openMailModel"
  >
    <Envelope />
    {{ $tExistsOrDefault('calculatePremium.premiumOverview.sendEmail') }}
  </LinkText>
</template>
<script>
import Envelope from '@/assets/icons/Envelope.vue';
import Modal from '@/constants/modal';
import ModalDataFactory from '@/components/modals/ModalDataFactory';
import LinkText from '@/components/shared/2.0-import/text/LinkText.vue';

export default {
  name: 'EmailCalculation',
  components: {
    Envelope,
    LinkText
  },
  props: {
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'white'].includes(value);
      }
    }
  },
  methods: {
    openMailModel() {
      this.$store.dispatch(Modal.Actions.OPEN, { 
          modalData: ModalDataFactory.createSendEmail()
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.email-flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

.white {
  color: var(--color-white) !important;
}

</style>