<template>
  <div class="block-wrapper">
    <div>
      <H2>{{ $tExistsOrDefault('personal.contract') }}</H2>
      <div class="main-form mt-3">
        <div class="form-group mb-2">
          <label for="telephoneInput">{{ $tExistsOrDefault('personal.form.phone.label') }}</label>
          <ValidationProvider :rules="{ required: true, phone: true }" mode="lazy" v-slot="{ errors }">
            <vue-tel-input
              id="telephoneInput"
              :class="{'error-border': errors[0]}"
              style="height: auto !important;"
              class="mb-2"
              v-model="form.telephoneNumber" 
              v-bind="telInputProps"
              @blur="changeForm" 
              @input="val => { form.telephoneNumber = filterPhoneNumber(val) }"
              maxLen="15"
            />
            <span v-if="errors[0]" :class="{'error': errors[0]}">
              <CircleXMark iconInText="true" />
              {{ $tExistsOrDefault('personal.form.phone.required') }}
            </span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label class="col-sm-4 pl-0">{{ $tExistsOrDefault('personal.form.correspondentieLanguage.label') }}</label>
          <div class="col-sm-8 pl-0">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="horizontal-radio mb-2">
                <div class="radio-button-group">
                  <RadioButton
                    v-for="language in ['NL','EN']"
                    :radioValue="language"
                    v-model="form.languageCode"
                    @change="changeForm"
                    :key="language"
                    name="correspondentieLanguage"
                  >
                    {{ `personal.languages.${language}` | translate }}
                  </RadioButton>
                </div>
              </div>
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.correspondentieLanguage.required') }}
              </span>
            </ValidationProvider>
          </div>
        </div>
        <div>
          <div class="col-md-8 mt-2 pl-0">
            <span v-html="$tExistsOrDefault(`personal.corresponding.info.${labelSettings.label}`)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import CircleXMark from '@/assets/icons/CircleXMark.vue';
import prettySelectOnEnter from '@/mixins/prettySelectOnEnter';
import H2 from '@/components/shared/2.0-import/text/H2.vue';
import RadioButton from '@/components/shared/RadioButton.vue';
import { filterPhoneNumber } from '@/utils/phone-number';

export default {
  name: 'PersonalDataContact',
  components: {
    ValidationProvider,
    CircleXMark,
    H2,
    RadioButton
  },
  props: {
    initialForm: Object
  },
  mixins: [prettySelectOnEnter],
  data() {
    return {
      form: { ...this.initialForm },
      telInputProps: {
        mode: 'international',
        defaultCountry: 'NL',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: '6 1234 5678',
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ['NL', 'BE', 'DE'],
        name: 'telephone',
        maxLen: 20,
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        },
        validCharactersOnly: true
      }
    };
  },
  computed: {
    ...mapGetters(['labelSettings'])
  },
  methods: {
    changeForm() {
      this.$emit('formChange', this.form);
    },
    filterPhoneNumber,
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0rem;
}


.flex-1 {
  padding-left: 25px;
}
</style>

<style lang="scss">
.vue-tel-input {
  border-radius: 0.25rem;
  font-size: 1rem;
  border: 1px solid var(--color-grey-400) !important;
  background-color: var(--color-white) !important;
  &:focus-within {
    border-color: var(--color-primary-800) !important;
    box-shadow: none !important;
  }
  .vti__dropdown, input {
    padding: 8px;
    color: var(--color-grey-700);
  }
  .highlighted{
    background-color: var(--color-primary-800) !important;
    color: var(--color-white) !important;
  }
  .vti__flag{
    box-shadow: none;
  }
  strong {
    font-weight: normal;
  }
}

</style>
