<template>
  <Dropdown v-model="modelValue" :options="basicInsurances"></Dropdown>
</template>
      
<script>
import { mapGetters } from 'vuex';

import ProductComponentCodes from '@/constants/ProductComponentCodes';

import Dropdown from '@/components/shared/form/Dropdown.vue';

export default {
  name: 'BasicInsuranceDropdown',
  components: {
    Dropdown
  },
  data() {
    return {
      basicInsuranceOptions: [
        { value: ProductComponentCodes.NaturaSelect, text: 'calculatePremium.basicInsurance.hospitalsOverlay.basicInsurances.limitedChoice' },
        { value: ProductComponentCodes.Natura, text: 'calculatePremium.basicInsurance.hospitalsOverlay.basicInsurances.wideChoice' },
        { value: ProductComponentCodes.Combinatie, text: 'calculatePremium.basicInsurance.hospitalsOverlay.basicInsurances.broadestChoice' },
        { value: ProductComponentCodes.BewustVerzekerdNatura, text: 'calculatePremium.basicInsurance.hospitalsOverlay.basicInsurances.awareInsuredNatura' },
        { value: ProductComponentCodes.BewustVerzekerdRestitutie, text: 'calculatePremium.basicInsurance.hospitalsOverlay.basicInsurances.awareInsuredResitutie' },
        { value: ProductComponentCodes.BewustVerzekerdNaturaSelect, text: 'calculatePremium.basicInsurance.hospitalsOverlay.basicInsurances.awareInsuredNaturaSelect' }
      ],
    }
  },
  props: {
    value: Number,
  },
  computed: {
    ...mapGetters(['productList']),
    modelValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    selectableProductComponentCodes(){
      return this.productList?.filter(group => group.packageType === 'MB')
        ?.flatMap(group => group.products)
        .map(product => parseInt(product.productComponentCode));
    },
    basicInsurances(){
      return this.basicInsuranceOptions.filter(x => this.selectableProductComponentCodes.includes(x.value));
    }
  }
};
</script>
      
<style lang='scss' scoped></style>