<template>
    <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <path stroke="currentColor" stroke-width="2" stroke-linecap="round" d="m5 11.085 3.62 4.143L17 6.409"/>
            <path d="M0 .41h20v20H0z"/>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'StepCheck'
}
</script>
