import { extend } from 'vee-validate';
import { email, max, required, min } from 'vee-validate/dist/rules';
import Dates from '@/helpers/dates';
import BSNService from '@/helpers/bsn-service';
import Vue from 'vue';
import ValidationRulesConstants from '@/constants/ValidationRules'
import { filterPhoneNumber } from '@/utils/phone-number';

// install rules
extend('max', max);

extend('min', min);

extend('required', {
  ...required,
  message: () => Vue.i18n.translate('personal.form.required')
});

extend('requiredChoice', {
  ...required,
  message: () => Vue.prototype.$tExistsOrDefault('medicalSelection.errorRequiredChoice')
});

extend('requiredExplanation', {
  ...required,
  message: () => Vue.i18n.translate('medicalSelection.errorRequiredExplanation')
});

extend('requiredTreatments', {
  params: ['atLeastOneTreatment'],
  validate: (value, { atLeastOneTreatment }) => {
    return atLeastOneTreatment;
  },
  message: () => Vue.i18n.translate('medicalSelection.errorRequiredTreatments')
});

extend('email', {
  ...email,
  message: () => Vue.i18n.translate('personal.form.email.message')
});

extend('validBSN', {
  validate: (value, { allBsns }) => BSNService.verifyBSN(value, allBsns),
  params: ['allBsns'],
  message: () => Vue.i18n.translate('personal.form.socialSecurityNumber.message')
});

extend(ValidationRulesConstants.ValidLocationOrZipcode, {
  validate: (value, { options }) => {
    return value.length > 0 && ((value.match(/^\d/) && value.length >= 4) || options.map(x => x.toLowerCase()).includes(value.toLowerCase())) },
  params: ['options'],
  message: () => 'validationMessages.cityOrPostalCodeRequired',
  computesRequired: true
});

extend('validateTerms', {
  validate: value => value,
  message: () => 'overview.termsRequired'
});

extend('validateThruthfullyCondition', {
  validate: value => value,
  message: () => 'overview.questionTruthfullyRequired'
});

extend('name', {
  validate: (value) => {
    const regex = /^[a-zA-ZÀ-ÿ-, ']*$/;
    return regex.test(value);
  },
  message: () => Vue.i18n.translate('personal.form.onlyNumbersAndSpecialCharacters')
});

extend('namePrefix', {
  validate: (value) => {
    const regex = /^[a-zA-ZÀ-ÿ-,' ]*$/;
    return regex.test(value);
  },
  message: () => Vue.i18n.translate('personal.form.onlyNumbersAndSpacesAndSpecialCharacters')
});

extend('zipCode', {
  validate: (value) => {
    const regex = /^\d{4}\s?\w{2}$/;
    return regex.test(value);
  },
  message: () => Vue.i18n.translate('personal.form.zipcode.message')
});

extend('housenumber', {
  validate: (value) => {
    const regex = /^[0-9]{1,6}$/;
    return regex.test(value);
  },
  message: () => Vue.i18n.translate('personal.form.houseNumber.message')
});

extend('housenumberAddition', {
  validate: (value) => {
    const regex = /^[a-zA-ZÀ-ÿ0-9 ]{1,20}$/;
    return regex.test(value);
  },
  message: () => Vue.i18n.translate('personal.form.onlyNumbersAndSpacesAndSpecialCharacters')
});

extend('street', {
  validate: (value) => {
    const regex = /^[a-zA-ZÀ-ÿ-, ']*$/;
    return regex.test(value);
  },
  ...required,
  message: () => 'requiredStreet'
});

extend('streetpobox', {
  validate: (value) => {
    const valueToCheck = value.toLowerCase();

    return !valueToCheck.includes('postbus') && !valueToCheck.includes('antwoordnummer');
  },
  message: () => 'invalidStreet'
});

extend('phone', {
  validate: (val) => {
    val = filterPhoneNumber(val);

    // Validates international phone number
    const length = val.length;
    if (val.startsWith('+31')) return length === 12;
    return val.startsWith('+') && length > 9 && length <= 15;
  },
  message: () => Vue.i18n.translate('personal.form.phone.message')
});

extend('employeeNumber', {
  validate: (value) => {
    const regex = /^[a-zA-ZÀ-ÿ0-9]{1,25}$/;
    return regex.test(value);
  },
  message: () => 'invalidEmployeeNumber'
});

extend('validDate', {
  validate(value) {  
    if (!value) return true;
    if (value.length < 10) return false;
    const [dayString, monthString, yearString] = value.split('-');
    const day = parseInt(dayString);
    const month = parseInt(monthString) - 1;
    const year = parseInt(yearString);
    const d = new Date(year, month, day);
    return d.getFullYear() === year && d.getMonth() === month && d.getDate() === day;
  },
  message: () => Vue.i18n.translate('field.invalid')
});

extend('minDate', {
  params: ['minDate'],
  validate(value, { minDate }) {  
    if (!value || !minDate) return true;
    minDate = new Date(minDate.setHours(0,0,0,0));
    return Dates.dateStringToDate(value) >= minDate; 
  },
  message: (value, params) => Vue.i18n.translate('field.minDate', { date: Dates.formatDate(params.minDate) })
});

extend('maxDate', {
  params: ['maxDate'],
  validate(value, { maxDate }) { 
    if (!value || !maxDate) return true;
    maxDate = new Date(maxDate.setHours(0,0,0,0));
    return Dates.dateStringToDate(value) <= maxDate;
  },
  message: (value, params) => Vue.i18n.translate('field.maxDate', { date: Dates.formatDate(params.maxDate) })
});

extend('minLengthSearchCollectivity', {
  validate: value => value.length >= 3,
  message: () => 'main.messages.minimal'
});

extend('requiredCare', {
  validate: (value, { careId }) => !!careId,
  params: ['careId'],
  message: () => 'validationMessages.requiredCare',
  computesRequired: true
});