<template>
  <div 
    v-if="$route.meta.banner" 
    class="d-flex justify-content-center"
    :class="{
      'banner-768': $screen.xs, 
      'banner-1200': $screen.sm,
      'banner': $screen.gt.sm
    }" 
  >
    <img
      class="banner-img w-100"
      :class="{'alpha-target': $screen.gt.xs}" 
      :src="bannerPath"
      alt="banner"
    />
    <div 
      class="d-flex justify-content-center w-100 usp-wrapper" 
    >
      <div class="container">
        <img 
          :src="bannerUspPath" 
          alt="usp-banner"
          class="ml-3"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Banner',
  computed: {
    ...mapGetters(['language', 'labelSettings']),
    bannerPath() {
      const imageName = this.labelSettings.theme
        ? `${this.labelSettings.theme}.png`
        : 'aev.png';
      const sizeSelect = this.$screen.lt.sm ? '_768px' : '';
      return `/images/whiteLabeling/banner/${this.labelSettings.theme}/banner${sizeSelect}_${imageName}`;
    },
    bannerUspPath() {
      const imageName = this.labelSettings.theme
        ? `${this.labelSettings.theme}.png`
        : 'aev.png';
      const sizeSelect = this.$screen.lt.sm ? '_mobile' : '';
      return `/images/whiteLabeling/banner/${this.labelSettings.theme}/usps_${this.language.toLowerCase()}${sizeSelect}_${imageName}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.banner, .banner-1200, .banner-768 {
  position: relative;
  min-width: 100%;
}
.banner-768 {
  margin-bottom: -74px;
}
.banner-1200 {
  margin-bottom: -105px;
}
.banner {
  margin-bottom: -81px;
}
.banner-img {
  display: block;
  max-width: 1720px;
  min-height: 345px;
  object-fit: cover;
}
.usp-wrapper {
  position: absolute;
  top: 26px;
}
.alpha-target {
  mask-image: url(/images/mask.png);
  mask-mode: alpha;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;

  -webkit-mask-image: url(/images/mask.png);
  -webkit-mask-mode: alpha;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;

  -moz-mask-image: url(/images/mask.png);
  -moz-mask-mode: alpha;
  -moz-mask-repeat: no-repeat;
  -moz-mask-size: 100% 100%;
}

</style>