<template>
  <div v-if="!labelsLoading.LOAD_BY_LANGUAGE" class="loading-icon flex w-100 justify-content-center" style="height:100%;">
    <font-awesome-icon
      icon="circle-notch"
      size="3x"
      spin
    />
  </div>
</template>

<script>
import Revisit from '@/constants/revisit';
import NProgress from 'nprogress';
import Modal from '@/constants/modal';
import BusinessRule from '@/constants/business-rule';
import Logo from '@/constants/logos';
import Contracts from '@/constants/contracts';
import { mapGetters } from 'vuex';
import ModalDataFactory from '@/components/modals/ModalDataFactory';
import Dates from '@/helpers/dates';

export default {
  name: 'RevisitLoader',
  props: {
    id: {
      type: String,
      requried: true
    },
    type: {
      type: String,
      requried: true,
      validator: function (value) {
        return ['email', 'cookie'].includes(value);
      }
    }
  },
  computed: { 
    ...mapGetters(['labelSettings', 'labelsLoading', 'revisitStateItem', 'businessRule', 'revisitCookieName']) 
  },
  methods: {
    async loadNecessaryData() {
      this.$store.dispatch(Contracts.Actions.LOAD_REIMBURSEMENT_CATEGORIES);
      this.$store.commit(Contracts.Mutations.SET_SELECTED_ID, this.revisitStateItem.agreementModel.contractCode);
      await this.$store.dispatch(Contracts.Actions.LOAD_EJW_INFO, false);
      const currentYear = new Date().getFullYear();
      const startYear = Dates.dateStringToDate(this.revisitStateItem.agreementModel.startDate).getFullYear();
      this.$store.commit(BusinessRule.Mutations.SET_LASTPOLICYYEAR, { lastPolicyYear: this.$store.getters.lastPolicyYearAvailable === true && startYear === currentYear });
      
      this.$store.commit(BusinessRule.Mutations.SET_CONTRACT_CODE_IN_BR, this.revisitStateItem.agreementModel.contractCode);
      this.$store.dispatch(Logo.Actions.LOAD_BY_CONTRACT_CODE, this.revisitStateItem.agreementModel.contractCode);
      await this.$store.dispatch(Contracts.Actions.LOAD_CONTRACT, this.businessRule.lastPolicyYear); 

      NProgress.inc(0.1);

      this.$store.dispatch(BusinessRule.Actions.POST)
        .then(() => {
          this.$store.dispatch(Contracts.Actions.LOAD_CONTRACT, this.businessRule.lastPolicyYear);
          NProgress.inc(0.25);
        })
        .then(() => 
          NProgress.set(1.0))
        .finally(() =>{
          setTimeout(() => {
            NProgress.done();
            this.navigate();
          }, 250);
        }
      );
    },
    startLoadingIndicatorBar() {
      this.$store.commit(Modal.Mutations.SHOW_OVERLAY, true);
      NProgress.configure({
        parent: '#loading-indicator',
        showSpinner: false,
        trickleSpeed: 75,
        easing: 'ease', 
        speed: 275
      });
      NProgress.start();
    },
    navigate() {
      if (this.revisitStateItem.agreementModel.insuredPersons.some(x => this.hasFilledMedicalSelection(x.medicalSelection)))  {
        this.$store.commit(Revisit.Mutations.SET_AFTER_MEDICAL_REDIRECT, this.revisitStateItem.urlLastPage);
        this.revisitStateItem.agreementModel.insuredPersons.forEach(insuredPerson => {
          if (insuredPerson.medicalSelection.question5.length === 0) {}
          else if (insuredPerson.medicalSelection.question5?.includes('nee')) {
            this.$store.commit(BusinessRule.Mutations.SET_MEDICAL_SELECTION_ANSWER5_ENABLEMENT, { key: insuredPerson.id, value: false});
          }
          else {
            this.$store.commit(BusinessRule.Mutations.SET_MEDICAL_SELECTION_ANSWER5_ENABLEMENT, { key: insuredPerson.id, value: true});
          }
        });
        let splitUrl = this.revisitStateItem.urlLastPage.split('/');
        splitUrl = splitUrl.slice(1, 3);
        splitUrl.push('configuration/3/true');
        this.$router.replace({ path: `/${splitUrl.join('/')}` });
      } else {
        this.$router.replace({ path: this.revisitStateItem.urlLastPage });
      }
      this.$cookies.remove(this.revisitCookieName);
      this.$store.commit(Modal.Mutations.SHOW_OVERLAY, false);
    },
    hasFilledMedicalSelection(medicalSelection) {
      return (medicalSelection.question1 !== null ||
        medicalSelection.question2 !== null ||
        medicalSelection.question3 !== null ||
        medicalSelection.additionalInfoQuestion3 !== null ||
        medicalSelection.question4 != null ||
        medicalSelection.additionalInfoQuestion4 !== null ||
        medicalSelection.question5.length !== 0);
    }
  },
  async mounted () {
    if(!this.id || (this.type === 'cookie' && !this.$cookies.isKey(this.revisitCookieName))) this.$router.replace({ path: '/' });
    
    this.startLoadingIndicatorBar();     

    let isAgreementValid = true;
    await this.$store.dispatch(Revisit.Actions.GET_AGREEMENT_STATE, this.id)
      .catch(() => {
        isAgreementValid = false;
        this.$store.dispatch(Modal.Actions.OPEN, { 
          modalData: ModalDataFactory.createInvalidRevisit() 
        });
        NProgress.done();
      });
    if (!isAgreementValid) return;
    await this.$store.dispatch(BusinessRule.Actions.SET_AGREEMENT, this.revisitStateItem.agreementModel);

    if(this.type === 'email'){
      this.$cookies.remove(this.revisitCookieName);
    }
    this.$store.dispatch(Revisit.Actions.PATCH, { id: this.revisitStateItem.id, state: Revisit.RevisitStateEnum.recovered } );
    this.loadNecessaryData();
  },
  beforeDestroy() {
    this.$store.commit(Modal.Mutations.SHOW_OVERLAY, false);
  }
};
</script>
