<template>
  <div class="d-flex align-items-center">
    <div class="circle" :class="{'circle-disabled': showCircleOrTextInGrey}">
      <StepCheck v-if="showCheckIcon" class="check" />
      <div v-else>
        {{ stepNumber }}
      </div>
    </div>
    <div class="mr-2">
      <div :class="{'text-disabled': showCircleOrTextInGrey}">
        {{ stepName }}
      </div>
    </div>
  </div>
</template>

<script>
import StepCheck from '@/assets/icons/StepCheck.vue';

export default {
  name: 'StepsDesktop',
  components: { 
    StepCheck 
  },
  props: {
    stepNumber: Number,
    stepName: String,
    isVisitedOrActive: Object,
    maxSteps: Number,
    currentStep: Number
  },
  computed: {
    isActive() {
      return this.isVisitedOrActive.isActive;
    },
    isVisited() {
      return this.isVisitedOrActive.isVisited;
    },
    showCheckIcon() {
      return !this.isActive && this.isVisited;
    },
    showCircleOrTextInGrey() {
      return !this.isActive && !this.isVisited;
    }
  },
};
</script>

<style lang="scss" scoped>
.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  padding: 1px 7px 0px 7px;
  margin-right: 7px;
  font-family: var(--text-font);
  background-color: var(--color-primary-900);
  border: solid 1px var(--color-primary-900);
  color: var(--color-white);
  &.circle-disabled {
    background-color: var(--color-grey-400);
    border: solid 1px var(--color-grey-400);
  }
}
.check {
  margin-top: -4px;
  margin-left: -6px;
}
.text-disabled {
  color: var(--color-grey-500) !important;
}
</style>