import Config from '@/constants/config';

const RevisitStateEnum = {
  unused: 1,
  declined: 2,
  recovered: 3,
};

const RevisitStepEnum = {
  unknown: 0,
  basicInsurance: 1,
  extraAdditionalInsurance: 2,
  additionalInsurance: 3,
  dentalInsurance: 4,
  paymentTerm: 5,
  personalData: 6
}

const Actions = {
  SAVE: 'a.revisit.save',
  GET_AGREEMENT_STATE: 'a.revisit.set-agreement-state',
  PATCH: 'a.revisit.patch',
};

const Mutations = {
  SET_STATE_ITEM: 'm.revisit.set-state-item',
  SET_AFTER_MEDICAL_REDIRECT: 'm.revisit.set-after-medical-redirect'
};

const Endpoints = {
  Save: () => `${Config.API.BASE_URL}/api/State`,
  Get: () => `${Config.API.BASE_URL}/api/State`,
  Patch: () => `${Config.API.BASE_URL}/api/State`,
};

export default {
  RevisitStateEnum,
  RevisitStepEnum,
  Actions,
  Mutations,
  Endpoints
};
