<template>
  <div>
    <div class="mb-1">
      <PlainText> {{ $tExistsOrDefault('calculatePremium.paymentTerm.title') }} </PlainText>
      <PlainText class="text-box-size"> {{ $tExistsOrDefault('calculatePremium.paymentTerm.intro', { inserts: { korting_jaar: jaarKorting } }) }} </PlainText>
    </div>
    <PaymentTermSlot>
      <div class="radio-button-group" v-if="paymentTermNetDiscounts">
        <RadioButton
          v-for="{paymentTerm, paymentTermNetPremiumWithOwnRiskDiscountTotal} in paymentTermNetDiscounts"
          name="payment-term"
          :radioValue="paymentTerm"
          :key="paymentTerm"
          v-model="selectedPaymentTerm"
        >
          <PlainText>Per {{ $tExistsOrDefault(`calculatePremium.paymentTerm.paymentTerms.${paymentTerm}`) }}, {{ formatCurrency(paymentTermNetPremiumWithOwnRiskDiscountTotal) }} <span class="text-lowercase" v-if="paymentTerm == 12">({{ jaarKorting }}% {{ $tExistsOrDefault('calculatePremium.paymentTerm.discount') }})</span></PlainText>
        </RadioButton>
      </div>
    </PaymentTermSlot>
  </div>
</template>

<script>
import BusinessRule from '@/constants/business-rule';
import formatCurrencyHelper from '@/helpers/formatCurrency';
import { mapGetters } from 'vuex';
import PaymentTermSlot from "@/components/configuration/PaymentTermSlot.vue";
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import RadioButton from '@/components/shared/RadioButton.vue';

export default {
  name: 'PaymentTerm',
  components: {
    PaymentTermSlot,
    PlainText,
    RadioButton
  },
  props: {
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapGetters(['paymentTermNetDiscounts', 'selectedPaymentTermInConfiguration', 'selectedContract', 'language']),
    selectedPaymentTerm: {
      get() { 
        return this.selectedPaymentTermInConfiguration; 
      },
      set(v) {
        this.$store.commit(BusinessRule.Mutations.SET_PAYMENT_TERM_SELECTION, v);
      },
    },
    paymentTerms() { return this.paymentTermNetDiscounts.map(x => x.paymentTerm) },
    jaarKorting() {
      return this.selectedContract.paymentDiscountYear.toLocaleString(this.language);
    }
  },
  methods: {
    formatCurrency (value) {
      return formatCurrencyHelper(
        value,
        this.$i18n.locale() ? this.$i18n.locale() : 'NL'
      );
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
