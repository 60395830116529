<template>
  <svg width="20" height="24" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path d="M10 23.43C16.667 18.16 20 13.682 20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 3.682 3.333 8.159 10 13.43z"
        fill="#008FB4" />
      <circle fill="#FFF" cx="10" cy="10" r="4" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'Location'
}
</script>