<template>
  <div class="block-wrapper">
    <CardHeader class="mb-3">{{ getTitle() }} ( {{ formatDate() }} ) </CardHeader>
    <div>
      <div class="main-form">
        <div class="row">
          <div class="form-group col-12 col-md-8">
            <label :for="`firstNameInput-person-${id}`">{{ $tExistsOrDefault('personal.form.firstname.label') }}</label>
            <ValidationProvider
              rules="required"
              :debounce="200"
              mode="lazy"
              v-slot="{ errors }"
            >
              <input
                :id="`firstNameInput-person-${id}`"
                type="text"
                class="form-control"
                v-focus="id === 0 && !$screen.xs"
                :class="{'error-border': errors[0]}"
                v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
                maxlength="30"
                v-model="form.firstName"
                @change="trimTrailingSpaces(); changeForm()"
              />
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.firstname.required') }}
              </span>
            </ValidationProvider>
          </div>
          <div class="form-group col-12 col-md-4">
            <label :for="`initialsInput-person-${id}`">{{ $tExistsOrDefault('personal.form.initials.label') }}</label>
            <ValidationProvider
              rules="required"
              :debounce="200"
              mode="lazy"
              v-slot="{ errors }"
            >
              <input
                :id="`initialsInput-person-${id}`"
                type="text"
                class="form-control text-uppercase"
                :class="{'error-border': errors[0]}"
                v-mask="'A.A.A.A.A.A.'"
                v-model="form.initials"
                @change="endWithDot(); changeForm()"
              />
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.initials.required') }}
              </span>
            </ValidationProvider>
          </div>
          <div class="form-group col-12 col-md-4">
            <label :for="`lastNamePrefixInput-person-${id}`">{{ $tExistsOrDefault('personal.form.lastNamePrefix.label') }}</label>
            <ValidationProvider
              rules="namePrefix"
              :debounce="200"
              mode="lazy"
              v-slot="{ errors }"
            >
              <input
                :id="`lastNamePrefixInput-person-${id}`"
                type="text"
                class="form-control"
                v-mask="'XXXXXXXXXXXXXXX'"
                maxlength="15"
                v-model="form.lastNamePrefix"
                @change="trimTrailingSpaces(); changeForm()"
              />
            </ValidationProvider>
          </div>
          <div class="form-group col-12 col-md-8">
            <label :for="`lastNameInput-person-${id}`">{{ $tExistsOrDefault('personal.form.lastname.label') }}</label>
            <ValidationProvider
              rules="required|name"
              :debounce="200"
              mode="lazy"
              v-slot="{ errors }"
            >
              <input
                type="text"
                class="form-control"
                :class="{'error-border': errors[0]}"
                v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
                maxlength="60"
                v-model="form.lastName"
                @change="trimTrailingSpaces(); changeForm()"
                :id="`lastNameInput-person-${id}`"
              />
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.lastname.required') }}
              </span>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <label>{{ $tExistsOrDefault('personal.form.gender.label') }}</label>
            <ValidationProvider
              rules="required"
              :debounce="200"
              v-slot="{ errors }"
            >
              <div class="radio-button-group">
                <RadioButton
                  v-for="language in [{label: 'male', value: 'M'}, {label: 'female', value: 'V'}]"
                  :radioValue="language.value"
                  v-model="form.gender"
                  @change="changeForm"
                  :key="language.value"
                  :name="`gender-person-${id}`"
                >
                  {{ $tExistsOrDefault(`personal.form.gender.${language.label}`) }}
                </RadioButton>
              </div>
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.gender.required') }}
              </span>
            </ValidationProvider>
          </div>
          <div v-if="oldEnough(16)" class="form-group col-12">
            <label :for="`emailInput-person-${id}`">{{ $tExistsOrDefault('personal.form.email.label') }}</label>
            <ValidationProvider
              :rules="{ required: oldEnough(16) }"
              :debounce="200"
              v-slot="{ errors }"
              mode="lazy"
            >
              <input
                type="email"
                class="form-control"
                :class="{'error-border': errors[0]}"
                v-model="form.email"
                @change="changeForm"
                :id="`emailInput-person-${id}`"
              />
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.email.required') }}
              </span>
            </ValidationProvider>
          </div>
          <div class="form-group col-12">
            <label :for="`socialSecurityNumberInput-person-${id}`">
              {{ $tExistsOrDefault('personal.form.socialSecurityNumber.label') }}
              <information-icon 
                class="ml-2" 
                :title="$tExistsOrDefault('personal.form.socialSecurityNumber.label')" 
                :text="$tExistsOrDefault('personal.form.socialSecurityNumber.tooltip')"
              />
            </label>
            <ValidationProvider
              :rules="{ required: true, validBSN: allBsns }"
              :debounce="200"
              v-slot="{ errors }"
              mode="lazy"
            >
              <input
                type="text"
                class="form-control"
                :class="{'error-border': errors[0]}"
                v-mask="'#########'"
                v-model="form.socialSecurityNumber"
                @change="addLeadingZeroWhenNeeded(); changeForm()"
                :id="`socialSecurityNumberInput-person-${id}`"
              />
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('personal.form.socialSecurityNumber.required') }}
              </span>
            </ValidationProvider>
          </div>
          <div class="form-group col-12">
            <label>{{ $tExistsOrDefault('personal.form.nationality.label') }}</label>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <DropdownWithLabel 
                v-model="form.nationality"
                :options="nationalities.map(n => ({ label: n.value, value: n.key }))"
                :useTranslationKeys="false"
                @change="changeForm()"
              />
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import { differenceInYears } from 'date-fns';

import Dates from '@/helpers/dates';
import NationalityService from '@/helpers/nationality-service';
import CircleXMark from '@/assets/icons/CircleXMark.vue';
import prettySelectOnEnter from '@/mixins/prettySelectOnEnter';
import RadioButton from '@/components/shared/RadioButton.vue';
import DropdownWithLabel from '@/components/shared/2.0-import/DropdownWithLabel.vue';
import CardHeader from '@/components/shared/2.0-import/text/CardHeader.vue';

export default {
  name: 'PersonalDataInsured',
  components: {
    ValidationProvider,
    CircleXMark,
    RadioButton,
    DropdownWithLabel,
    CardHeader
  },
  props: {
    id: Number,
    initialForm: Object,
    allBsns: Array
  },
  mixins: [prettySelectOnEnter],
  data() {
    return {
      form: { ...this.initialForm }
    };
  },
  computed: {
    ...mapGetters(['agreement', 'startDate', 'getDateOfBirth']),
    insured() {
      return this.$store.getters.agreement.insuredPersons.find(i => i.id === this.id);
    },
    nationalities() {
      return NationalityService.getNationalities();
    }
  },
  methods: {
    formatDate() { return Dates.formatDate(this.getDateOfBirth(this.insured.id), 'dd MMMM yyyy') },
    oldEnough(age) { return differenceInYears(this.startDate, this.getDateOfBirth(this.insured.id)) >= age },
    getTitle() {
      if (this.insured != null) {
        switch (this.insured.typeOfInsured) {
          case 'H':
            return this.$tExistsOrDefault('personal.yourselfTitle');
          case 'P':
            return this.$tExistsOrDefault('personal.partnerTitle');
          case 'M':
            return this.$tExistsOrDefault('personal.childTitle');
        }
      }
    },
    endWithDot() {
      if (this.form.initials.slice(-1) !== '.' && this.form.initials.length > 0) {
        this.form.initials = this.form.initials + '.';
      }
    },
    changeForm() {
      this.$emit('formChange', this.form);
    },
    addLeadingZeroWhenNeeded() {
      if (this.form.socialSecurityNumber.length === 8) {
        this.form.socialSecurityNumber = '0' + this.form.socialSecurityNumber;
      }
    },
    trimTrailingSpaces() {
      if (this.form.firstName) {
        this.form.firstName = this.form.firstName.replace(/[^-,A-Z,a-z\u00C0-\u024F']/g, ' ');
        this.form.firstName = this.form.firstName.replace(/^./, this.form.firstName[0].toUpperCase()).trim();
      }
      
      this.form.lastNamePrefix = this.form.lastNamePrefix.replace(/[^A-Z,a-z ]/g, ' ').trim();

      if (this.form.lastName) {
        this.form.lastName = this.form.lastName.replace(/[^-,A-Z,a-z\u00C0-\u024F']/g, ' ');
        this.form.lastName = this.form.lastName.replace(/^./, this.form.lastName[0]?.toUpperCase()).trim();
      }      
    },
  },
};
</script>
<style lang='scss' scoped>
select.form-control {
  font-size: 16px;
}
</style>
