<template>
  <div class="row items items-small">
    <div class="col-md-4 flex">
      <div class="item">
        <div class="item__heading">
          {{ label }}
        </div>
        <div class="item__content row1">
          {{ basicInsurance.title }}
        </div>
        <div
          class="item__content row2" v-for="(xa, i) in extraAdditionalInsurances"
          :key="'insured-person-xa' + i"
        >
          {{ xa.title }}
        </div>
        <div class="item__content row3">
          {{ $tExistsOrDefault('calculatePremium.calculationOverview.ownRiskDiscount') }}
        </div>
        <div class="item__content row4">
          <strong>{{ $tExistsOrDefault('calculatePremium.calculationOverview.totalBasePremiumMonth') }}</strong>
        </div>
        <div class="item__content row5">
          <br />
        </div>
        <div
          class="item__content row6" v-for="(ap, i) in additionalInsurances"
          :key="'insured-person-ap' + i"
        >
          {{ ap.title }}
        </div>
        <div
          class="item__content row7" v-for="(ta, i) in dentalInsurances"
          :key="'insured-person-ta' + i"
        >
          {{ ta.title }}
        </div>
        <div class="item__content row8">
          <strong>{{ $tExistsOrDefault('calculatePremium.calculationOverview.totalAevitaeDiscount') }}</strong>
        </div>
        <hr />
        <div class="item__content row9">
          <hr />
        </div>
        <div class="item__content row10">
          <strong>{{ $tExistsOrDefault('calculatePremium.calculationOverview.totalPriceMonth') }}</strong>
        </div>
      </div>
    </div>

    <div class="col-md-2 flex">
      <div class="item">
        <div class="item__heading">
          {{ $tExistsOrDefault('calculatePremium.calculationOverview.originalPrice') }}
        </div>
        <div class="item__content row1">
          {{ formatCurrency(basicInsurance.netPremiumWithDiscount) }}
        </div>
        <div
          class="item__content row2" v-for="(xa, i) in extraAdditionalInsurances"
          :key="'insured-person-xa-gross' + i"
        >
          {{ formatCurrency(xa.netPremiumWithDiscount) }} <br />
        </div>
        <div class="item__content row3">
          {{ formatCurrency(priceObject().priceMinusExtraDiscount) }}
        </div>

        <div class="item__content row4">
          <br />
        </div>
        <div class="item__content row5">
          <br />
        </div>
        <div
          class="item__content row6" v-for="(ap, i) in additionalInsurances"
          :key="'insured-person-ap-gross' + i"
        >
          {{ formatCurrency(ap.grossPremium) }}
        </div>
        <div
          class="item__content row7" v-for="(ta, i) in dentalInsurances"
          :key="'insured-person-ta-gross' + i"
        >
          {{ formatCurrency(ta.grossPremium) }}
        </div>
        <div class="item__content row8">
          <br />
        </div>
        <hr />
        <div class="item__content row9">
          <hr />
        </div>
        <div class="item__content row10" />
      </div>
    </div>

    <div class="col-md-2 flex">
      <div class="item">
        <div class="item__heading">
          {{ $tExistsOrDefault('calculatePremium.calculationOverview.ourPrice') }}
        </div>
        <div class="item__content row1">
          {{ formatCurrency(basicInsurance.netPremiumWithDiscount) }}
        </div>
        <div
          class="item__content row2" v-for="(xa, i) in extraAdditionalInsurances"
          :key="'insured-person-xa-gross' + i"
        >
          {{ formatCurrency(priceObject().priceMinusExtraDiscount) }}
        </div>
        <div class="item__content row3">
          {{ formatCurrency(priceObject().priceMinusOwnRiskDiscount) }}
        </div>

        <div class="item__content row4">
          <strong>{{ formatCurrency(priceObject().priceMinusOwnRiskDiscount) }}</strong>
        </div>
        <div class="item__content row5">
          <br />
        </div>
        <div
          class="item__content row6" v-for="(ap, i) in additionalInsurances"
          :key="'insured-person-ap-gross' + i"
        >
          {{ formatCurrency(ap.netPremiumWithDiscount) }}
        </div>
        <div
          class="item__content row7" v-for="(ta, i) in dentalInsurances"
          :key="'insured-person-ta-gross' + i"
        >
          {{ formatCurrency(ta.netPremiumWithDiscount) }}
        </div>
        <div class="item__content row8">
          <br />
        </div>
        <hr />
        <div class="item__content row9">
          <hr />
        </div>
        <div class="item__content row10">
          <strong>{{ formatCurrency(person.netPremiumWithDiscountTotal) }}</strong>
        </div>
      </div>
    </div>

    <div class="col-md-2 flex">
      <div class="item">
        <div style="vertical-align: top;" class="item__heading">
          {{ $tExistsOrDefault('calculatePremium.calculationOverview.ownChoiceDiscount') }}
        </div>
        <div class="item__content row1" />
        <div
          v-if="this.extraAdditionalInsurances.length >= 1" class="item__content row2"
        >
          <br />
        </div>
        <div class="item__content row3">
          <strong>{{ formatCurrency(ownRisk.ownRiskDiscount) }}</strong>
        </div>
        <div class="item__content row4">
          <br />
        </div>
        <div class="item__content row5">
          <br />
        </div>
        <div
          class="item__content row6" v-for="(ap, i) in additionalInsurances"
          :key="'insured-person-ap' + i"
        >
          <br />
        </div>
        <div
          class="item__content row7" v-for="(ta, i) in dentalInsurances"
          :key="'insured-person-ta' + i"
        >
          <br />
        </div>
        <div class="item__content row8">
          <br />
        </div>
        <hr />
        <div class="item__content row9">
          <hr />
        </div>
        <div class="item__content row10">
          <br />
        </div>
      </div>
    </div>

    <div class="col-md-2 flex">
      <div class="item">
        <div class="item__heading">
          {{ $tExistsOrDefault('calculatePremium.calculationOverview.aevitaeDiscount') }}
        </div>
        <div class="item__content row1">
          {{ formatCurrency(basicInsurance.grossPremium - basicInsurance.netPremiumWithDiscount) }}
        </div>
        <div
          class="item__content row2" v-for="(xa, i) in extraAdditionalInsurances"
          :key="'insured-person-xa' + i"
        >
          {{ formatCurrency(xa.grossPremium) }}
        </div>
        <div class="item__content row3">
          <br />
        </div>
        <div class="item__content row4">
          <br />
        </div>
        <div class="item__content row5">
          <br />
        </div>
        <div
          class="item__content row6" v-for="(ap, i) in additionalInsurances"
          :key="'insured-person-ap' + i"
        >
          {{ formatCurrency(ap.grossPremium - ap.netPremiumWithDiscount) }}
        </div>
        <div
          class="item__content row7" v-for="(ta, i) in dentalInsurances"
          :key="'insured-person-ta' + i"
        >
          {{ formatCurrency(ta.grossPremium - ta.netPremiumWithDiscount) }}
        </div>
        <div class="item__content row8">
          <strong>{{ formatCurrency(this.person.grossPremiumTotal - this.person.netPremiumWithDiscountTotal) }}</strong>
        </div>
        <hr />
        <div class="item__content row9">
          <hr />
        </div>
        <div class="item__content row10">
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dates from '@/helpers/dates';
import formatCurrencyHelper from '@/helpers/formatCurrency';

export default {
  name: 'CalculationInsured',
  props: {
    person: Object
  },
  computed: {
    agreement () {
      return this.$store.getters.agreement;
    },
    label () {
      let result = '';
      switch (this.person.typeOfInsured) {
        case 'H':
          result += this.$tExistsOrDefault('calculatePremium.general.main') + ': ';
          break;
        case 'P':
          result += this.$tExistsOrDefault('calculatePremium.general.partner') + ': ';
          break;
        case 'M':
          result += this.$tExistsOrDefault('calculatePremium.general.child') + ': ';
          break;
      }
      return result + this.person.dateOfBirth;
    },
    basicInsurance () {
      let basicInsurance = this.person.chosenInsurances.find(ci => ci.packageTypeForWebApplications === 'MB');
      if (basicInsurance !== undefined) {
        return basicInsurance;
      } else {
        return {
          'title': '',
          'grossPremium': 0.0,
          'netPremiumWithDiscount': 0.0
        };
      }
    },
    ownRisk () {
      return {
        'ownRiskAmount': parseInt(this.$tExistsOrDefault('calculatePremium.ownRisk.ownRiskValue')) + this.person.chosenOwnRisk,
        'ownRiskDiscount': this.person.chosenOwnRiskDiscount
      };
    },
    extraAdditionalInsurances () {
      return this.person.chosenInsurances.filter(ci => ci.packageTypeForWebApplications === 'XA' || ci.packageTypeForWebApplications === 'AE');
    },
    additionalInsurances () {
      return this.person.chosenInsurances.filter(ci => ci.packageTypeForWebApplications === 'AP');
    },
    dentalInsurances () {
      return this.person.chosenInsurances.filter(ci => ci.packageTypeForWebApplications === 'TA');
    }
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(
        value,
        this.$i18n.locale() ? this.$i18n.locale() : 'NL'
      );
    },
    priceObject () {
      let discountExtraAdditionalInsurance = 0.0;

      if (this.person.chosenInsurances.find(ci => ci.packageTypeForWebApplications === 'XA' && ci.grossPremium < 0) !== undefined) {
        discountExtraAdditionalInsurance = this.person.chosenInsurances.find(ci => ci.packageTypeForWebApplications === 'XA' && ci.grossPremium < 0).grossPremium;
      }

      let priceMinusExtraDiscount = this.basicInsurance.netPremiumWithDiscount - discountExtraAdditionalInsurance;
      let priceMinusOwnRiskDiscount = priceMinusExtraDiscount - this.ownRisk.ownRiskDiscount;
      let totalDiscountAmount = this.basicInsurance.grossPremium - this.basicInsurance.netPremiumWithDiscount;
      totalDiscountAmount += discountExtraAdditionalInsurance;

      if (this.additionalInsurances.length > 0) {
        this.additionalInsurances.forEach(function (item) {
          totalDiscountAmount += (item.grossPremium - item.netPremiumWithDiscount);
        });
      }
      if (this.dentalInsurances.length > 0) {
        this.dentalInsurances.forEach(function (item) {
          totalDiscountAmount += (item.grossPremium - item.netPremiumWithDiscount);
        });
      }

      return {
        'priceMinusExtraDiscount': priceMinusExtraDiscount,
        'priceMinusOwnRiskDiscount': priceMinusOwnRiskDiscount,
        'totalDiscountAmount': totalDiscountAmount
      };
    }
  }
};
</script>

<style lang='scss' scoped>
  div.item {
    min-width: 100%;
    vertical-align: top;
  }
</style>
