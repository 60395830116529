import Config from '@/constants/config';

const Actions = {
  POST: 'a.br.post',
  CALCULATE_NETTO_PREMIUMS: 'a.br.calculate-netto-premiums',
  CALCULATE_PAYMENT_TERMS: 'a.br.calculate-payment-terms',
  SET_DATE_OF_BIRTH_FOR_INSURED: 'a.br.set-date-of-birth',
  SELECT_PRODUCT_FOR_PERSON: 'a.br.select-product-for-person',
  REMOVE_PRODUCT_FOR_PERSON: 'a.br.remove-product-for-person',
  SUBMIT_AGREEMENT: 'a.br.submit-agreement',
  LOAD_PAYMENT_INFO_BY_SUBCONTRACT: 'a.br.load-payment-info-by-subcontract',
  REVERT_TO_PREVIOUS_SELECTION_STATE: 'a.br.revert-to-previous-selection-state',
  SET_HAS_PACKAGE_SELECTION_CONFLICT_FALSE: 'a.br.set-has-package-selection-conflict-false',
  SET_AGREEMENT: 'a.br.set-agreement',
  SET_NEWSLETTER: 'a.br.set-newsletter',
  SET_DIGITAL_POLICY: 'a.br.set-digital-policy',
  ADD_VISITED_PACKAGE_TYPE: 'a.br.add-visited-package-type',
  RESET_VISITED_PACKAGE_TYPES: 'a.br.reset-visited-package-types',
};

const Mutations = {
  CLEAR: 'm.br.clear',
  UPDATE_BUSINESS_RULE: 'm.br.update-business-rule',
  SET_START_DATE: 'm.br.set-start-date',
  SET_CONTRACT_CODE_IN_BR: 'm.br.set-contract-code-in-br',
  ADD_INSURED: 'm.br.add-insured',
  REMOVE_INSURED: 'm.br.remove-insured',
  SET_DATE_OF_BIRTH_FOR_INSURED: 'm.br.set-date-of-birth',
  SET_SELECTIONHASCHANGED: 'm.br.set-selection-has-changed',
  SET_MEDICAL_SELECTION_ANSWERS: 'm.br.set-medical-selection-answers',
  SET_MEDICAL_SELECTION_ANSWER5_ENABLEMENT: 'm.br.set-medical-selection-answer5-enablement',
  RESET_MEDICAL_SELECTION_ANSWERS: 'm.br.reset-medical-selection-answers',
  SET_CHOSEN_INSURANCE: 'm.br.set-chosen-insurance',
  REMOVE_CHOSEN_INSURANCE: 'm.br.remove-chosen-insurance',
  REMOVE_DUPLICATE_PACKAGE_TYPES: 'm.br.remove-duplicate-package-types',
  CLEAR_PACKAGE_TYPE_FOR_PERSON: 'm.br.clear-package-type-for-person',
  SET_LOADING: 'm.br.set-loading',
  SET_PAYMENT_INFO: 'm.br.set-payment-info',
  SET_PAYMENT_TERM_SELECTION: 'm.br.set-payment-term-selection',
  UPDATE_PAYMENT_TERMS: 'm.br.update-payment-terms',
  SET_NETTO_PREMIUMS: 'm.br.set-netto-premiums',
  SET_PERSONAL_DATA: 'm.br.set-personal-data',
  SET_PERSONAL_EXTRA_DATA: 'm.br.set-personal-extra-data',
  SET_LASTPOLICYYEAR: 'm.br.set-last-policy-year',
  SET_SHOULDLOCKCONTRACT: 'm.br.set-should-lock-contract',
  SET_LANGUAGE: 'm.br.set-language',
  SET_PAYMENT_METHOD_AND_TERM: 'm.br.set-payment-method-and-term',
  SET_PREVIOUS_SELECTION_STATE: 'm.br.set-previous-selection-state',
  REVERT_TO_PREVIOUS_SELECTION_STATE: 'm.br.revert-to-previous-selection-state',
  SET_HAS_PACKAGE_SELECTION_CONFLICT_FALSE: 'm.br.set-has-package-selection-conflict-false',
  SET_COLLECTIVITY_LABEL: 'm.br.set-collectivity-label',
  SET_NEWSLETTER: 'm.br.set-newsletter',
  SET_DIGITAL_POLICY: 'm.br.set-digital-policy',
  SET_AGREEMENT: 'm.br.set-agreement',
  ADD_VISITED_PACKAGE_TYPE: 'm.br.add-visited-package-type',
  REMOVE_ALL_PACKAGES_WHEN_DISABLED_BASIC_INSURANCE_SELECTED: 'm.br.remove-all-packages-when-disabled-basic-insurance-selected',
  SET_VISITED_PACKAGE_TYPES: 'm.br.set-visited-package-types',
};

const Endpoints = {
  Post: () => `${Config.API.BASE_URL}/api/BusinessRule`,
  CalculatePremiums: () => `${Config.API.BASE_URL}/api/BusinessRule/Premiums`,
  CalculatePaymentTerms: () => `${Config.API.BASE_URL}/api/BusinessRule/PremiumPerPaymentTerm`,
  SubmitAgreement: () => `${Config.API.BASE_URL}/api/Agreement`,
  LoadPaymentInfoBySubcontract: (contractCode, skoCode, lastPolicyYear) => `${Config.API.BASE_URL}/api/contract/${contractCode}/subcontracts/${skoCode}/paymentInfo?lastPolicyYear=${lastPolicyYear}`,
};

export default {
  Actions,
  Mutations,
  Endpoints
};
