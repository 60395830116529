import {
  addMonths,
  startOfMonth,
  subYears,
  addYears,
  startOfYear
} from 'date-fns';
import Dates from '@/helpers/dates';

export default class CalculatePremiumHelpers {
  // These functions are used in the CalculatePremiumInsured components. That is why the 'to' and 'from' properties seem weird.

  static mainDisabledDates (agreementStartDate) {
    return {
      to: subYears(agreementStartDate, 125),
      from: subYears(agreementStartDate, 18)
    };
  }

  static partnerDisabledDates (agreementStartDate) {
    return {
      to: subYears(agreementStartDate, 125),
      from: subYears(agreementStartDate, 16)
    };
  }

  static childrenDisabledDates (agreementStartDate) {
    return {
      to: subYears(agreementStartDate, 125),
      from: new Date()
    };
  }

  /**
   * Determine the default/initial start date for the agreement.
   * @param {Boolean} isEjw Should the start date be determined for the EJW period.
   */
  static determineStartDate (isEjw) {
    if (!isEjw) {
      if(Dates.isJanuary(new Date()))
      {
        return addMonths(startOfMonth(new Date()), 0);
      }
      return addMonths(startOfMonth(new Date()), 1);
    }

    return addYears(startOfYear(new Date()), 1);
  }

  /**
   * Reset the dateOfBirth property of the passed insured to null if the dateOfBirth is outside the allowed date range.
   * @param {{dateOfBirth: Date, typeOfInsured: String}} insured
   * @param {Date} agreementStartDate
   */
  static resetDateOfBirthIfNoLongerValid (insured, agreementStartDate) {
    if (!agreementStartDate) {
      return;
    }

    if (!insured.dateOfBirth) {
      // The date is probably already null, but reset it anyway just to be sure.
      insured.dateOfBirth = null;
    }

    let disabledDates = null;

    if (insured.typeOfInsured === 'H') {
      disabledDates = this.mainDisabledDates(agreementStartDate);
    } else if (insured.typeOfInsured === 'P') {
      disabledDates = this.partnerDisabledDates(agreementStartDate);
    } else if (insured.typeOfInsured === 'M') {
      disabledDates = this.childrenDisabledDates(agreementStartDate);
    }

    // The 'to' and 'from' properties are a little confusing.
    // from: Disable all dates after this date.
    // to: Disable all dates before this date.
    // The period after 'to' and before 'from' is allowed.
    const parsedDateOfBirth = Dates.dateStringToDate(insured.dateOfBirth);
    if (parsedDateOfBirth < disabledDates.to ||
        parsedDateOfBirth > disabledDates.from) {
      insured.dateOfBirth = null;
    }
  }
}
