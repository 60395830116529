import i18n from 'vuex-i18n';

const formatCurrency = function (currency, locale) {
  switch (typeof currency) {
    case 'string':
      return currency.toLocaleString(locale, {
        style: 'currency',
        currency: 'EUR'
      });
    case 'number':
      if(Math.sign(currency) === -1) {
        return new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: 'EUR'
        }).format(currency).replace('€','_€_').replace('-','€').replace('_€_','-');
      } else {
        return locale === 'en' ? 
          new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(currency).replace('€', '€ ') : 
          new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(currency);
      }
    default:
      break;
  }
};
export default formatCurrency;

const formatCurrency2 = function (value) {
  return formatCurrency(
    value,
    i18n.store.state.locale ? i18n.store.state.locale : 'NL'
    );
};

export { formatCurrency2 };