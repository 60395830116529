<template>
  <Overlay :showOverlay="showOverlay">
    <template #content>
      <slot />
    </template>
    <template #overlay>
      <div class="overlay-content text-center mt-5 pt-5">
        <font-awesome-icon 
          class="icon-anim text-center overlay-content"
          icon="circle-notch"
          size="4x"
        />
      </div>
    </template>
  </Overlay>
</template>

<script>
import Overlay from '@/components/shared/Overlay.vue';

export default {
  name: 'LoadingOverlay',
  components: { Overlay },
  props: {
    showOverlay: Boolean
  }
};
</script>

<style lang="scss" scoped>
.icon-anim {
  animation: appear 1.5s infinite forwards linear;
  opacity: .8;
}
@keyframes appear {
  from { transform: rotate(0deg); }
  to   { transform: rotate(360deg); }
}
</style>
