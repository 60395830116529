<template>
  <div>
    <ul>
      <!-- Basic Insurance -->
      <div v-if="basicProduct">
        <li class="d-flex product pt-2">
          <div>
            {{
              $tExistsOrDefault(
                "calculatePremium.premiumOverview.insuranceTitle.basic"
              )
            }}
          </div>
        </li>
        <li class="d-flex flex-column product">
          <div class="d-flex justify-content-between">
            <div>
              <BulletPoint class="bullet-point" />
              {{ basicInsuranceProductTitle }}
            </div>
            <div :style="premiumStyle">
              {{
                basicProduct
                  ? formatCurrency(nettoPremiums.nettoPremiumBasicInsurance)
                  : ""
              }}
            </div>
          </div>
          <!-- Own Risk -->
          <div class="d-flex justify-content-between">
            <div>
              <BulletPoint class="bullet-point" />
              {{
                isChildAndYoungerThan18
                  ? $tExistsOrDefault(
                      "calculatePremium.premiumOverview.ownRisk.none"
                    )
                  : `${$tExistsOrDefault(
                      "calculatePremium.premiumOverview.ownRisk.title"
                    )} € ${ownRisk}`
              }}
            </div>
            <div
              :style="premiumStyle"
              v-if="ownRisk !== 385"
              :class="ownRiskDiscount < 0 ? 'accent-text-color' : ''"
            >
              {{ ownRiskDiscount ? formatCurrency(ownRiskDiscount) : "" }}
            </div>
          </div>
        </li>
      </div>

      <!-- Additional Insurance - Title -->
      <li v-if="hasVisitedXA" class="d-flex product mt-2">
        <div>
          {{
            $tExistsOrDefault(
              "calculatePremium.premiumOverview.insuranceTitle.additional"
            )
          }}
        </div>
      </li>

      <!-- Additional Insurance -->
      <li
        v-if="additionalInsuranceProducts.length > 0 && hasVisitedXA"
        class="d-flex flex-column product"
      >
        <div
          v-for="additionalInsurance in additionalInsuranceProducts"
          :key="
            'additionalInsurance-' + additionalInsurance.productComponentCode
          "
          class="d-flex justify-content-between"
        >
          <!-- Title Additional Insurance -->
          <div class="mr-5">
            <BulletPoint class="bullet-point" />
            {{ additionalInsurance.title }}
          </div>
          <!-- Nettopremium Additional Insurance -->
          <div
            :class="{
              'accent-text-color':
                additionalInsuranceNetPremium(additionalInsurance) ===
                $tExistsOrDefault('price.free').toUpperCase(),
            }"
          >
            {{
              isChildAndYoungerThan18
                ? ""
                : additionalInsuranceNetPremium(additionalInsurance)
            }}
          </div>
        </div>
      </li>
      <li v-else-if="hasVisitedXA">
        <div class="text-grey">
          <BulletPoint class="bullet-point" />
          {{ $tExistsOrDefault("calculatePremium.premiumOverview.noProduct") }}
        </div>
      </li>

      <!-- Dental Insurance -->
      <div v-if="dentalInsuranceProduct || childHasVisitedDentalProducts">
        <li class="d-flex product mt-2">
          <div>
            {{
              $tExistsOrDefault(
                "calculatePremium.premiumOverview.insuranceTitle.dental"
              )
            }}
          </div>
        </li>
        <li class="d-flex flex-column product">
          <div
            class="d-flex justify-content-between"
            :class="{ 'text-grey': showDentalTextGreyedOut }"
          >
            <div v-if="childWithoutDentalInsurance">
              <BulletPoint class="bullet-point" />
              {{
                $tExistsOrDefault(
                  "calculatePremium.premiumOverview.notAvailable"
                )
              }}
            </div>
            <div v-else>
              <BulletPoint class="bullet-point" />
              {{ dentalInsuranceProductTitle }}
            </div>
            <div :style="premiumStyle">
              {{ dentalInsuranceNetPremium }}
            </div>
          </div>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatCurrency2 } from "@/helpers/formatCurrency";
import Dates from "@/helpers/dates";
import BulletPoint from "@/assets/icons/BulletPoint.vue";

export default {
  name: "SelectedProductsSummary",
  components: {
    BulletPoint,
  },
  props: {
    products: Array,
    ownRiskBaseValue: Number,
    ownRiskDiscount: Number,
    insuredPersonType: String,
    insuredPersonId: Number,
    showPackagePremiums: Boolean,
  },
  data() {
    return {
      formatCurrency: formatCurrency2,
    };
  },
  computed: {
    ...mapGetters(["visitedPackageTypes", "startDate", "getDateOfBirth"]),
    basicProduct() {
      return this.products.find(
        (x) => x.packageTypeForWebApplications === "MB"
      );
    },
    additionalInsuranceProducts() {
      return this.products.filter(
        (x) =>
          ((x.packageTypeForWebApplications === "AP" && this.hasVisitedAP) ||
            x.packageTypeForWebApplications === "XA" ||
            x.packageTypeForWebApplications === "AE") &&
          x.title
      );
    },
    dentalInsuranceProduct() {
      return this.products.find(
        (x) => x.packageTypeForWebApplications === "TA"
      );
    },
    ownRisk() {
      return (
        parseInt(
          this.$tExistsOrDefault("calculatePremium.ownRisk.ownRiskValue")
        ) + this.ownRiskBaseValue
      );
    },
    isChildAndYoungerThan18() {
      return (
        this.insuredPersonType === "M" &&
        !Dates.isOldEnough(
          this.getDateOfBirth(this.insuredPersonId),
          this.startDate,
          18
        )
      );
    },
    childWithoutDentalInsurance() {
      return (
        this.isChildAndYoungerThan18 &&
        !this.products.find((x) => x.packageTypeForWebApplications === "TA")
      );
    },
    basicInsuranceProductTitle() {
      return (
        this.basicProduct?.title ||
        this.$tExistsOrDefault("calculatePremium.premiumOverview.noProduct")
      );
    },
    dentalInsuranceNetPremium() {
      if (!this.dentalInsuranceProduct?.productComponentCode) return;
      if (this.isFree(this.dentalInsuranceProduct))
        return this.$tExistsOrDefault("price.free").toUpperCase();
      return this.formatCurrency(
        this.getProductComponentPremium(
          this.dentalInsuranceProduct?.productComponentCode
        )
      );
    },
    dentalInsuranceProductTitle() {
      return (
        this.dentalInsuranceProduct?.title ||
        this.$tExistsOrDefault("calculatePremium.premiumOverview.noProduct")
      );
    },
    childHasVisitedDentalProducts() {
      return (
        this.isChildAndYoungerThan18 && this.visitedPackageTypes.includes("TA")
      );
    },
    hasVisitedXA() {
      return this.visitedPackageTypes.includes("XA");
    },
    hasVisitedAP() {
      return this.visitedPackageTypes.includes("AP");
    },
    nettoPremiums() {
      return this.$store.getters.nettoPremiums.persons.find(
        (p) => p.personId === this.insuredPersonId
      ).premiums;
    },
    showDentalTextGreyedOut() {
      return (
        (this.dentalInsuranceProduct && !this.dentalInsuranceProduct.title) ||
        this.dentalInsuranceProductTitle ===
          this.$tExistsOrDefault("calculatePremium.premiumOverview.noProduct")
      );
    },
    premiumStyle() {
      return {
        visibility: this.showPackagePremiums ? "visible" : "hidden",
      };
    },
  },
  methods: {
    additionalInsuranceNetPremium(inputAdditionalInsuranceProduct) {
      if (!inputAdditionalInsuranceProduct?.productComponentCode) return;
      if (this.isFree(inputAdditionalInsuranceProduct))
        return this.$tExistsOrDefault("price.free").toUpperCase();
      return this.formatCurrency(
        this.getProductComponentPremium(
          inputAdditionalInsuranceProduct?.productComponentCode
        )
      );
    },
    getProductComponentPremium(productComponentCode) {
      if (!productComponentCode) return 0;

      let premium =
        this.nettoPremiums.extraProductComponentDescriptions.find(
          (pc) => pc.productComponentCode === productComponentCode
        ) ||
        this.nettoPremiums.productComponentDescriptions.find(
          (pc) => pc.productComponentCode === productComponentCode
        );
      return premium ? premium.nettoPremium : null;
    },
    isFree(insuranceProduct) {
      return (
        insuranceProduct.grossPremium === 0 ||
        insuranceProduct.percentagePaidByEmployer === 100
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.accent-text-color {
  color: var(--color-positive);
}
.text-grey {
  color: var(--color-grey-500);
}
.product {
  font-family: var(--text-font);
  line-height: 23px;
  font-size: 16px;
  color: var(--color-grey-700);
}
.person,
.product * {
  margin-bottom: 0px;
}

.bullet-point {
  color: var(--color-primary-800);
  margin-right: 8px;
}
</style>
