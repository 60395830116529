<template>
  <!-- Contact details -->
  <section class="contact-details-container" :class="{ 'contact-details-mobile': $screen.lt.sm }">
    <div>
      <H3 class="mb-3">{{ $tExistsOrDefault('overview.addressAndContact') }}</H3>
      <PlainText>{{ `${agreement.address.street} ${agreement.address.housenumber}
              ${agreement.address.housenumberAddition}` }}
      </PlainText>
      <PlainText>{{ `${agreement.address.zipcode} ${agreement.address.city}` }}</PlainText>
      <PlainText v-if="country">
        {{ country }}
      </PlainText>
      <div>
        <PlainText class="d-inline" type="secondary">{{ 'overview.email' | translate }}: </PlainText>
        <PlainText class="d-inline">{{ getMainInsured.email }}</PlainText>
      </div>
      <div>
        <PlainText class="d-inline" type="secondary">{{ 'overview.phone' | translate }}: </PlainText>
        <PlainText class="d-inline">{{ agreement.address.telephoneNumber }}</PlainText>
      </div>
      <div>
        <PlainText class="d-inline" type="secondary">{{ 'overview.correspondentie' | translate }}: </PlainText>
        <PlainText class="d-inline">{{ agreement.languageCode }}</PlainText>
      </div>
    </div>

    <ButtonPrevious @click.native="gotoPersonalData"> {{ $tExistsOrDefault('overview.button.changeContactDetails') }}
    </ButtonPrevious>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';

import H3 from '@/components/shared/2.0-import/text/H3.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import NationalityService from '@/helpers/nationality-service';

export default {
  name: 'OverviewContactDetails',
  components: {
    ButtonPrevious,
    H3,
    PlainText
  },
  computed: {
    ...mapGetters(['agreement']),
    getMainInsured() {
      return this.agreement.insuredPersons.find(p => p.typeOfInsured === 'H');
    },
    country() {
      if (this.agreement.address.country === 'NL') return
      return NationalityService.getNationalities().find(c => c.key == this.agreement.address.country)?.value;
    }
  },
  methods: {
    gotoPersonalData(event) {
      if (event) event.preventDefault();
      this.$router.push({ path: `/${this.$store.getters.language}/${this.$store.getters.selectedContractCode}/personal_data` });
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-details-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.contact-details-mobile {
  flex-direction: column;
  gap: 24px;
}
</style>
