var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',[(_vm.basicProduct)?_c('div',[_c('li',{staticClass:"d-flex product pt-2"},[_c('div',[_vm._v("\n          "+_vm._s(_vm.$tExistsOrDefault(
              "calculatePremium.premiumOverview.insuranceTitle.basic"
            ))+"\n        ")])]),_c('li',{staticClass:"d-flex flex-column product"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('BulletPoint',{staticClass:"bullet-point"}),_vm._v("\n            "+_vm._s(_vm.basicInsuranceProductTitle)+"\n          ")],1),_c('div',{style:(_vm.premiumStyle)},[_vm._v("\n            "+_vm._s(_vm.basicProduct
                ? _vm.formatCurrency(_vm.nettoPremiums.nettoPremiumBasicInsurance)
                : "")+"\n          ")])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('BulletPoint',{staticClass:"bullet-point"}),_vm._v("\n            "+_vm._s(_vm.isChildAndYoungerThan18
                ? _vm.$tExistsOrDefault(
                    "calculatePremium.premiumOverview.ownRisk.none"
                  )
                : `${_vm.$tExistsOrDefault(
                    "calculatePremium.premiumOverview.ownRisk.title"
                  )} € ${_vm.ownRisk}`)+"\n          ")],1),(_vm.ownRisk !== 385)?_c('div',{class:_vm.ownRiskDiscount < 0 ? 'accent-text-color' : '',style:(_vm.premiumStyle)},[_vm._v("\n            "+_vm._s(_vm.ownRiskDiscount ? _vm.formatCurrency(_vm.ownRiskDiscount) : "")+"\n          ")]):_vm._e()])])]):_vm._e(),(_vm.hasVisitedXA)?_c('li',{staticClass:"d-flex product mt-2"},[_c('div',[_vm._v("\n        "+_vm._s(_vm.$tExistsOrDefault(
            "calculatePremium.premiumOverview.insuranceTitle.additional"
          ))+"\n      ")])]):_vm._e(),(_vm.additionalInsuranceProducts.length > 0 && _vm.hasVisitedXA)?_c('li',{staticClass:"d-flex flex-column product"},_vm._l((_vm.additionalInsuranceProducts),function(additionalInsurance){return _c('div',{key:'additionalInsurance-' + additionalInsurance.productComponentCode,staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"mr-5"},[_c('BulletPoint',{staticClass:"bullet-point"}),_vm._v("\n          "+_vm._s(additionalInsurance.title)+"\n        ")],1),_c('div',{class:{
            'accent-text-color':
              _vm.additionalInsuranceNetPremium(additionalInsurance) ===
              _vm.$tExistsOrDefault('price.free').toUpperCase(),
          }},[_vm._v("\n          "+_vm._s(_vm.isChildAndYoungerThan18
              ? ""
              : _vm.additionalInsuranceNetPremium(additionalInsurance))+"\n        ")])])}),0):(_vm.hasVisitedXA)?_c('li',[_c('div',{staticClass:"text-grey"},[_c('BulletPoint',{staticClass:"bullet-point"}),_vm._v("\n        "+_vm._s(_vm.$tExistsOrDefault("calculatePremium.premiumOverview.noProduct"))+"\n      ")],1)]):_vm._e(),(_vm.dentalInsuranceProduct || _vm.childHasVisitedDentalProducts)?_c('div',[_c('li',{staticClass:"d-flex product mt-2"},[_c('div',[_vm._v("\n          "+_vm._s(_vm.$tExistsOrDefault(
              "calculatePremium.premiumOverview.insuranceTitle.dental"
            ))+"\n        ")])]),_c('li',{staticClass:"d-flex flex-column product"},[_c('div',{staticClass:"d-flex justify-content-between",class:{ 'text-grey': _vm.showDentalTextGreyedOut }},[(_vm.childWithoutDentalInsurance)?_c('div',[_c('BulletPoint',{staticClass:"bullet-point"}),_vm._v("\n            "+_vm._s(_vm.$tExistsOrDefault(
                "calculatePremium.premiumOverview.notAvailable"
              ))+"\n          ")],1):_c('div',[_c('BulletPoint',{staticClass:"bullet-point"}),_vm._v("\n            "+_vm._s(_vm.dentalInsuranceProductTitle)+"\n          ")],1),_c('div',{style:(_vm.premiumStyle)},[_vm._v("\n            "+_vm._s(_vm.dentalInsuranceNetPremium)+"\n          ")])])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }