<template>
  <!-- Payment details -->
  <div class="block-wrapper mb-0">
    <div class="pb-1">
      <H2>{{ $tExistsOrDefault('overview.paymentDetails') }}</H2>
      <form class="main-form  mt-3">
        <!-- IBAN -->
        <ValidationProvider ref="IBANValidationRef" rules="required|validateIBAN" v-slot="{ errors }" mode="lazy">
          <div class="form-group">
            <label for="ibanInput">{{ 'overview.ibanAndBic.label' | translate }}</label>
            <div class="col-12 pl-0">
              <input
                id="ibanInput"
                v-model="form.IBAN"
                placeholder="NL00 BANK 0123 4567 89"
                type="text"
                class="form-control text-uppercase"
                :class="{'error-border': errors[0]}"
                v-mask="form.IBAN.toUpperCase().startsWith('NL') ? 'AA## AAAA #### #### ##' : 'AA## NNNN NNNN NNNN NNNN NNNN NNNN NNN'"
                @blur="formChange"
              />
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('overview.ibanAndBic.required') }}
              </span>
              <br />
              <span>{{ $tExistsOrDefault('overview.ibanText') }}</span>
            </div>
          </div>
        </ValidationProvider>
        <div class="mt-3">
          <p class="borderel-intermediar-background" v-if="agreement.possiblePaymentMethods.includes('I')">{{ $tExistsOrDefault('calculatePremium.paymentTerm.intermediary') }}</p>
          <p class="borderel-intermediar-background" v-else-if="agreement.possiblePaymentMethods.includes('B')">{{ $tExistsOrDefault('calculatePremium.paymentTerm.employer') }}</p>
        </div>
        <!-- Payment method -->
        <div class="form-group">
          <PlainText>{{ paymentMethodLabel | translate }}</PlainText>
          <div class="col-12 mt-2 pl-0">
            <!-- Payment method choice -->
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="radio-button-group">
                <RadioButton
                  v-for="language in [{label: 'automaticCollection', value: 'AUT'}, {label: 'ideal', value: 'IDEAL'}]"
                  :radioValue="language.value"
                  v-model="form.paymentMethod"
                  @change="formChange"
                  :key="language.value"
                  name="paymentMethod"
                >
                  {{ $tExistsOrDefault(`overview.${language.label}`) }}
                </RadioButton>
              </div>
              <span v-if="errors[0]" :class="{'error': errors[0]}">
                <CircleXMark iconInText="true" />
                {{ $tExistsOrDefault('overview.selectPayment.select-error') }}
              </span>
            </ValidationProvider>
            <!-- Automatic payment extra information -->
            <Teleport 
              to="#teleport-validation-message-mobile"  
              v-if="form.paymentMethod === 'AUT'" 
              class="info-automatic-collection mt-2"  
              :disabled="true"
            >
              <ValidationProvider rules="validateAutomaticCollection" v-slot="{ errors }" ref="autoCollectValidationProvider">
                <p-check v-show="false" v-model="form.hasAgreedToAutomaticCollection" />
                <div v-if="hasVisitedAutomaticCollectionOverlay && !showAutomaticCollectionOverlay" class="pt-1" id="automatic-collection-result-wrapper">
                  <div> 
                    <span v-if="form.hasAgreedToAutomaticCollection" class="positive-text-color">
                      <img src="/images/icons/check-new.svg" alt="checkmark" /> {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.accepted') }}
                    </span>
                    <span v-else :class="{'error': errors[0]}">
                      <CircleXMark class="mr-1 mt-n1" /> 
                      {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.required') }}
                    </span>
                  </div>
                  <component :is="form.hasAgreedToAutomaticCollection? 'ButtonPrevious': 'ButtonNext'" @click.native="showAutomaticCollectionOverlay = true"> {{ $tExistsOrDefault('overview.selectPayment.automaticCollectionInfo.goto-mandate') }}</component>
                </div>
              </ValidationProvider>
            </Teleport>
            <AutomaticCollectionOverlay 
              v-model="showAutomaticCollectionOverlay" 
              @onAccept="automaticCollectionFormChange" 
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider, extend } from 'vee-validate';
import formatCurrencyHelper from '@/helpers/formatCurrency';
import BusinessRule from '@/constants/business-rule';
import IBAN from 'iban';
import Vue from 'vue';
import CircleXMark from '@/assets/icons/CircleXMark.vue';
import AutomaticCollectionOverlay from '@/components/personal-data/AutomaticCollectionOverlay.vue';
import Teleport from 'vue2-teleport';
import prettySelectOnEnter from '@/mixins/prettySelectOnEnter';
import ButtonNext from '@/components/shared/button/ButtonNext.vue';
import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';
import RadioButton from '@/components/shared/RadioButton.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import H2 from '@/components/shared/2.0-import/text/H2.vue';

function validateIBAN (iban) {
  return IBAN.isValid(iban);
}

extend('validateIBAN', {
  validate: value => validateIBAN(value),
  message: () => Vue.i18n.translate('overview.ibanNotValid')
});
extend('validateAutomaticCollection', {
  validate: value => value,
  message: () => Vue.i18n.translate('overview.selectPayment.automaticCollectionInfo.required')
});

export default {
  name: 'PersonalDataPayment',
  components: {
    ValidationProvider,
    AutomaticCollectionOverlay,
    CircleXMark,
    Teleport,
    ButtonNext,
    ButtonPrevious,
    RadioButton,
    PlainText,
    H2
  },
  props: {
    initialForm: Object
  },
  mixins: [prettySelectOnEnter],
  data() {
    return {
      showAutomaticCollectionOverlay: false,
      hasVisitedAutomaticCollectionOverlay: false,
      form: { ...this.initialForm }
    };
  },
  computed: {
    ...mapGetters(['agreement', 'selectedPaymentTermInConfiguration']),
    paymentTerm() { return this.selectedPaymentTermInConfiguration },
    paymentTermWithNetDiscount() {
      return this.agreement.paymentTermNetDiscounts.find(pt => pt.paymentTerm === this.selectedPaymentTermInConfiguration);
    },
    paymentTermMonthWithNetDiscount() {
      return this.agreement.paymentTermNetDiscounts.find(pt => pt.paymentTerm === 1);
    },
    paymentTermDiscount() {
      let yearAmountWithoutDiscount = this.paymentTermMonthWithNetDiscount.paymentTermNetPremiumWithOwnRiskDiscountTotal * this.selectedPaymentTermInConfiguration;
      return yearAmountWithoutDiscount - this.paymentTermWithNetDiscount.paymentTermNetPremiumWithOwnRiskDiscountTotal;
    },
    paymentMethod() {
      return this.form.paymentMethod;
    },
    paymentMethodLabel(){
      if(this.agreement.possiblePaymentMethods.includes('I') || this.agreement.possiblePaymentMethods.includes('B')){
        return 'overview.paymentmethod.labelRisk';
      }
      return 'overview.paymentmethod.label';
    }
  },
  methods: {
    formChange(){
      this.$emit('formChange', this.form);
      this.storePaymentInfo();
    },
    automaticCollectionFormChange(event) {
      this.form.hasAgreedToAutomaticCollection = event;
      this.hasVisitedAutomaticCollectionOverlay = true;
      this.storePaymentInfo();
      this.$refs['autoCollectValidationProvider'].validate();
    },
    formatCurrency (value) {
      return formatCurrencyHelper(
        value,
        this.$i18n.locale() ? this.$i18n.locale() : 'NL'
      );
    },
    validIban (iban) {
      return validateIBAN(iban);
    },
    gotoConfiguration (event) {
      if (event) event.preventDefault();
      this.$router.push({ path: `/${this.$store.getters.language}/${this.$store.getters.selectedContractCode}/configuration` });
    },
    async storePaymentInfo() {
      const IBANValidationObject = await this.$refs.IBANValidationRef.validate();

      if (IBANValidationObject.valid) {
        this.$store.commit(BusinessRule.Mutations.SET_PAYMENT_INFO, this.form);
      }      
    },
    revokeAuthorizationAutomaticCollection() {
      this.hasVisitedAutomaticCollectionOverlay = true;
      this.form.hasAgreedToAutomaticCollection = false;
    }
  },
  mounted() {
    if(this.paymentMethod === 'AUT') {
      this.revokeAuthorizationAutomaticCollection();
      if(this.form.IBAN) {
        this.showAutomaticCollectionOverlay = true;
      }
    } 
  },
  watch: {
    paymentMethod(method) {
      if(method === 'AUT' && !this.form.hasAgreedToAutomaticCollection) {
        this.showAutomaticCollectionOverlay = true;
      } else if(method === 'IDEAL') {
        this.revokeAuthorizationAutomaticCollection();
      }
    },
    showAutomaticCollectionOverlay(value) {
      if (!value) {
        this.hasVisitedAutomaticCollectionOverlay = true;
      }   
    }
  }
};
</script>

<style lang='scss' scoped>
#automatic-collection-result-wrapper{
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    gap:8px;
  }
}

.positive-text-color {
  color: var(--color-positive); 
  img {
    vertical-align: baseline;
    margin-right: 5px;
  }
}
</style>
