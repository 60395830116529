<template>
  <svg
    width="20" height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    preserve-aspect-ratio="xMidYMid meet"
  ><g fill="none" fill-rule="evenodd">
    <path
      d="M 4 4 L 16 16 M 16 4 L 4 16" stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round" stroke-linejoin="round"
      opacity=".197"
    />
  </g>
  </svg>
</template>
<script>
export default {
  name: 'Unchecked'
};
</script>