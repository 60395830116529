<template>
  <div>
    <CardHorizontalRule />
    <!-- Basic insurance -->
    <PersonPremiumCardRow 
      :headerTitle="$tExistsOrDefault('calculatePremium.previewOverview.packageTypes.MB')" 
      :headerValue="basicInsuranceTitle" 
      :price="nettoPremiumBasicInsurance"
      :isChild="isChildYoungerThan18" 
    />

    <!-- Own Risk -->
    <PersonPremiumCardRow 
      :headerTitle="$tExistsOrDefault('calculatePremium.previewOverview.packageTypes.OW')" 
      :headerValue="chosenOwnRisk"
      :price="ownRiskDiscount"
      :isChild="isChildYoungerThan18" 
    />

    <!-- Extra additional insurance -->
    <PersonPremiumCardRow 
      v-for="extraAdditionalInsurance in extraAdditionalInsurances"
      :headerTitle="$tExistsOrDefault('calculatePremium.previewOverview.packageTypes.XA')" 
      :headerValue="extraAdditionalInsurance.title" 
      :price="getProductComponentPremiumOfPerson(extraAdditionalInsurance.productComponentCode)"
      :isChild="isChildYoungerThan18"
    />

    <!-- Additional insurance -->
    <PersonPremiumCardRow
      v-if="!additionalInsurances[0]?.title && !additionalInsurances[0]?.productComponentCode"
      :headerTitle="$tExistsOrDefault('calculatePremium.previewOverview.packageTypes.AP')" 
      :headerValue="$tExistsOrDefault('calculatePremium.previewOverview.noInsurance')" 
      :isChild="isChildYoungerThan18"
    />
    <template v-else>
      <PersonPremiumCardRow
        v-for="additionalInsurance in additionalInsurances" 
        :headerTitle="$tExistsOrDefault('calculatePremium.previewOverview.packageTypes.AP')" 
        :headerValue="additionalInsurance.title" 
        :price="getProductComponentPremiumOfPerson(additionalInsurance.productComponentCode)" 
        :isChild="isChildYoungerThan18"
      />
    </template>

    <!-- Dental insurance -->
    <PersonPremiumCardRow
      v-if="!dentalInsurance && isChildYoungerThan18" 
      :headerTitle="$tExistsOrDefault('calculatePremium.previewOverview.packageTypes.TA')"
      :headerValue="$tExistsOrDefault('calculatePremium.previewOverview.dentalInsuranceNotAvailable')"
      :isChild="isChildYoungerThan18"
    />
    <PersonPremiumCardRow
      v-else-if="dentalInsurance && !dentalInsurance.title"
      :headerTitle="$tExistsOrDefault('calculatePremium.previewOverview.packageTypes.TA')"
      :headerValue="$tExistsOrDefault('calculatePremium.previewOverview.noInsurance')"
    />
    <PersonPremiumCardRow
      v-else
      :headerTitle="$tExistsOrDefault('calculatePremium.previewOverview.packageTypes.TA')" 
      :headerValue="dentalInsurance.title" 
      :price="getProductComponentPremiumOfPerson(dentalInsurance.productComponentCode)" 
      :isChild="isChildYoungerThan18"
    />

    <CardHorizontalRule />

    <!-- Total premium -->
    <PersonPremiumCardTotalRow 
      :headerTitle="$tExistsOrDefault('calculatePremium.previewOverview.totalPremiumPerMonth')" 
      :price="totalNettoPremiumOfPersonPerMonth" 
    />

    <div class="advantage-section mb-3" v-if="allPremiumsOfPerson.totalDiscount">
      <PlainText type="secondary">{{ $tExistsOrDefault('overview.advantage') }}</PlainText>
      <PlainText>{{formatCurrency(allPremiumsOfPerson.totalDiscount)}}</PlainText>
    </div>
  </div>
</template>

<script>
import CardHorizontalRule from '@/components/configuration/insuranceCard/CardHorizontalRule.vue';
import PersonPremiumCardRow from '@/components/configuration/previewOverview/PersonPremiumCardRow.vue';
import PersonPremiumCardTotalRow from '@/components/configuration/previewOverview/PersonPremiumCardTotalRow.vue';
import { mapGetters } from 'vuex';
import Insurance from '@/helpers/insurance';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import formatCurrencyHelper from '@/helpers/formatCurrency';

export default {
  name: 'PersonPremiumCard',
  components: {
    CardHorizontalRule,
    PersonPremiumCardRow,
    PersonPremiumCardTotalRow,
    PlainText
},
  props: {
    insuredPerson: Object,
  },
  computed: {
    ...mapGetters(['nettoPremiums', 'getDateOfBirth', 'startDate', 'getIsChildYoungerThan18']),
    basicInsurance() {
      return this.insuredPerson.chosenInsurances.find(insurance => insurance.packageTypeForWebApplications === 'MB');
    },
    basicInsuranceTitle() {
      return Insurance.basicInsuranceTitle(this.basicInsurance);
    },
    chosenOwnRisk() {
      if(!this.insuredPerson.canChooseOwnRisk && this.isChildYoungerThan18) return this.$tExistsOrDefault('calculatePremium.previewOverview.ownRiskNone');
      else if(this.insuredPerson.canChooseOwnRisk && !this.isChildYoungerThan18 && this.insuredPerson.chosenOwnRisk === 0) return this.$tExistsOrDefault('calculatePremium.previewOverview.ownRiskValue385');
      else if(this.insuredPerson.canChooseOwnRisk && !this.isChildYoungerThan18 && this.insuredPerson.chosenOwnRisk === 500) return this.$tExistsOrDefault('calculatePremium.previewOverview.ownRiskValue885');
    },
    ownRiskDiscount() {
      return this.insuredPerson.canChooseOwnRisk && this.insuredPerson.chosenOwnRisk === 500 && this.insuredPerson.ownRiskDiscount !== 0 ?
        this.insuredPerson.chosenOwnRiskDiscount : null;
    },
    extraAdditionalInsurances() {
      return this.insuredPerson.chosenInsurances.filter(insurance => insurance.packageTypeForWebApplications === 'XA');
    },
    additionalInsurances() {
      return this.insuredPerson.chosenInsurances.filter(insurance => insurance.packageTypeForWebApplications === 'AP');
    },
    dentalInsurance() {
      return this.insuredPerson.chosenInsurances.find(insurance => insurance.packageTypeForWebApplications === 'TA');
    },
    allPremiumsOfPerson() {
      return this.nettoPremiums.persons.find(person => person.personId === this.insuredPerson.id).premiums;
    },
    allProductComponentDescriptionsOfPerson() {
      return [...this.allPremiumsOfPerson.productComponentDescriptions, ...this.allPremiumsOfPerson.extraProductComponentDescriptions];
    },
    nettoPremiumBasicInsurance() {
      return this.allPremiumsOfPerson.nettoPremiumBasicInsurance;
    },
    totalNettoPremiumOfPersonPerMonth() {
      return this.allPremiumsOfPerson.totalNettoPremium;
    },
    isChildYoungerThan18() {
      return this.getIsChildYoungerThan18(this.insuredPerson.id);
    }
  },
  methods: {
    getProductComponentPremiumOfPerson(productComponentCode) {
      if (!productComponentCode) return 0;
      var premium = this.allProductComponentDescriptionsOfPerson.find(pc => pc.productComponentCode === productComponentCode);
      return premium ? premium.nettoPremium : null;
    },
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale() ? this.$i18n.locale() : 'NL');
    }
  }
}
</script>

<style lang="scss" scoped>
.advantage-section {
  display: flex;
  justify-content: space-between;
}
</style>