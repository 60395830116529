<template>
  <svg
    width="100%" height="100"
    viewBox="0 0 249 100"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"	
  >
    <g fill="none" fill-rule="evenodd" width="100%">
      <rect
        width="100%"
        class="fill" y="55.69"
        height="44" rx="3"
      />
      <g class="stroke" preserveAspectRatio="xMinYMid meet">
        <path d="M67.227 4.274C72.562 1.046 78.365.354 84.143 1.55 90.64 2.892 97.1 6.601 102.87 11.775c11.809 10.59 20.747 27.332 21.117 42.912-27.347-.177-46.36-7.516-56.88-16.95-6.363-5.706-9.594-12.165-9.548-18.203.046-5.939 3.294-11.405 9.667-15.26z" />
        <path d="M125 54.69c2.866-9.525 10.2-14.72 22-15.585 20.637-1.512 33.125 15.585 46.837 15.585 9.979 0 16.332-3.803 19.06-11.41" stroke-linecap="round" stroke-linejoin="round" />
        <g>
          <path d="M182.23 4.274c-5.335-3.228-11.137-3.92-16.916-2.725-6.496 1.343-12.957 5.052-18.727 10.226-11.81 10.59-20.748 27.332-21.117 42.912 27.346-.177 46.36-7.516 56.88-16.95 6.363-5.706 9.593-12.165 9.547-18.203-.045-5.939-3.294-11.405-9.666-15.26z" />
          <path d="M124.457 54.69c-2.866-9.525-10.2-14.72-22-15.585C81.82 37.593 69.332 54.69 55.621 54.69c-9.98 0-16.333-3.803-19.061-11.41" stroke-linecap="round" stroke-linejoin="round" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'GiftLid',
};
</script>

<style lang="scss" scoped>
.stroke {
  stroke: var(--color-primary-200);
  stroke-width: 2;
}
.fill {
  fill: var(--color-primary-200);
}
</style>
