<template>
  <div v-if="listOfProducts">
    <div>
      <div
        class="d-flex justify-content between align-items-center pb-2"
        @click="isOpen = !isOpen"
      >
        <CircleChevron class="mr-1" :class="{'rotated': isOpen}" style="color: var(--color-primary-800)" />
        <span class="ml-2 person">
          <div v-if="insuredPerson.typeOfInsured === 'H'">{{ $tExistsOrDefault('calculatePremium.premiumOverview.insuredPerson.you') }}</div>
          <div v-else-if="insuredPerson.typeOfInsured === 'P'">{{ $tExistsOrDefault('calculatePremium.premiumOverview.insuredPerson.partner') }}</div>
          <div v-else-if="insuredPerson.typeOfInsured === 'M'">
            <span>{{ `${$tExistsOrDefault('calculatePremium.premiumOverview.insuredPerson.child')} ` }}</span>
            <span class="date">{{ dateLabelChild }}</span>
          </div>
        </span>
        <span 
          v-if="showPremium" 
          class="ml-auto amount"
        >
          {{ premiumForPerson }}
        </span>
      </div>
    </div>
    <SelectedProductsSummary
      v-show-slide="isOpen"
      :products="listOfProducts"
      :ownRiskBaseValue="insuredPerson.chosenOwnRisk"
      :ownRiskDiscount="insuredPerson.chosenOwnRiskDiscount"
      :insuredPersonType="insuredPerson.typeOfInsured"
      :insuredPersonId="insuredPerson.id"
      :showPackagePremiums="showPackagePremiums"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { formatCurrency2 } from '@/helpers/formatCurrency';
import SelectedProductsSummary from '@/components/shared/SelectedProductsSummary.vue';
import Dates from '@/helpers/dates';
import CircleChevron from '@/assets/icons/CircleChevron.vue';

export default {
  name: 'InsurancePersonSummary',
  props: {
    insuredPerson: Object,
    id: Number
  },
  components: {
    SelectedProductsSummary,
    CircleChevron
  },
  data() {
    return {
      overlayProduct: undefined,
      formatCurrency: formatCurrency2,
      isOpen: this.id === 0,
    };
  },
  computed: {
    ...mapGetters(['nettoPremiums', 'productList', 'visitedPackageTypes', 'isBusinessRulePostLoading', 'startDate', 'getDateOfBirth']),
    premiumForPerson(){
      const premium = this.nettoPremiumForPerson;

      if(premium === null || premium === 0){
        return this.$tExistsOrDefault('price.free');
      }else{
        return this.formatCurrency(premium);
      }
    },
    nettoPremiumForPerson(){
      return this.nettoPremiums?.persons?.find(person =>
        this.insuredPerson.id === person.personId
      )?.premiums.totalNettoPremium;
    },
    showPackagePremiums(){
      return !!this.nettoPremiumForPerson;
    },
    listOfProducts() {
      return this.insuredPerson.chosenInsurances.filter((p) => !!p.productComponentCode || this.visitedPackageTypes.includes(p.packageTypeForWebApplications));
    },
    dateLabelChild() {
      if (this.insuredPerson.typeOfInsured === 'M') return this.insuredPerson.dateOfBirth;
      return '';
    },
    isAdult() {
      return Dates.isOldEnough(this.getDateOfBirth(this.insuredPerson.id), this.startDate, 18);
    },
    showPremium(){
      return this.insuredPerson.chosenInsurances.some(x => x.productComponentCode) && !this.isBusinessRulePostLoading;
    }
  }
};
</script>
<style lang="scss" scoped>
.person {
  font-family: var(--text-font-semibold);
  font-size: 18px;
  color: var(--color-grey-900);
}
.amount {
  font-family: var(--text-font-semibold);
  font-size: 18px;
  color: var(--color-grey-900);
}
.date {
  font-family: var(--text-font);
  font-size: 15px;
}
.person,
.product * {
  margin-bottom: 0px;
}
.rotated {
  transform-origin: center;
  transform: rotateZ(-180deg);
}
img {
    transition: all 0.2s ease-in;
}

.accent-text-color {
  color: var(--color-positive);
}
</style>
