<template>
  <div class="block-wrapper">
    <H2>{{ $tExistsOrDefault("personal.address") }}</H2>
    <div class="main-form mt-3">
      <div class="row">
        <div class="form-group col-12">
          <label>{{
            $tExistsOrDefault("personal.form.country.label")
          }}</label>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <DropdownWithLabel v-model="form.country" :options="nationalities.map((n) => ({ label: n.value, value: n.key }))
              " :useTranslationKeys="false" @change="changeForm" />
          </ValidationProvider>
        </div>
        <div class="form-group col-6 col-lg-3">
          <label for="zipCodeInput">{{
            $tExistsOrDefault("personal.form.zipcode.label")
          }}</label>
          <ValidationProvider ref="zipCodeRef" :rules="selectedCountryIsNetherlands ? 'required|zipCode' : 'required|min:2|max:12'"
            :debounce="200" v-slot="{ errors }" mode="lazy">
            <input id="zipCodeInput" type="text" v-focus="!$screen.xs"
              :placeholder="selectedCountryIsNetherlands ? '1234 AB' : ''" class="form-control text-uppercase"
              :class="{ 'error-border': errors[0] }" v-mask="selectedCountryIsNetherlands ? '#### AA' : ''"
              v-model="form.zipcode" @blur="loadAddress(form.zipcode, form.housenumber)" />
            <span v-if="errors[0]" :class="{ error: errors[0] }">
              <CircleXMark iconInText="true" />
              {{ $tExistsOrDefault("personal.form.zipcode.required") }}
            </span>
          </ValidationProvider>
        </div>
        <div class="col-6 col-lg-3">
          <label for="houseNumberInput">{{
            $tExistsOrDefault("personal.form.houseNumber.label")
          }}</label>
          <ValidationProvider rules="required|housenumber" :debounce="200" v-slot="{ errors }">
            <input id="houseNumberInput" type="text" class="form-control" :class="{ 'error-border': errors[0] }"
              v-mask="'######'" v-model="form.housenumber" @blur="loadAddress(form.zipcode, form.housenumber)" />
            <span v-if="errors[0]" :class="{ error: errors[0] }">
              <CircleXMark iconInText="true" />
              {{ $tExistsOrDefault("personal.form.houseNumber.required") }}
            </span>
          </ValidationProvider>
        </div>
        <div class="col-12 col-lg-6">
          <label for="houseNumberAdditionInput">{{
            $tExistsOrDefault("personal.form.houseNumber.additionalLabel")
          }}</label>
          <div>
            <ValidationProvider rules="housenumberAddition" :debounce="200" v-slot="{ errors }">
              <input id="houseNumberAdditionInput" autocomplete="off" type="text" class="form-control" v-mask="'NNNNNNNN'"
                v-model="form.housenumberAddition" @change="changeForm"  @input="houseNumberInputChanged" />
            </ValidationProvider>
          </div>
        </div>
      </div>
      <ValidationObserver ref="addressStreetDataObserver" v-slot="{ invalid }">
        <div class="form-group">
          <label for="streetNameInput">{{
            $tExistsOrDefault("personal.form.street.label")
          }}</label>
          <ValidationProvider rules="streetpobox|street" :debounce="200" v-slot="{ errors }">
            <input id="streetNameInput" type="text" class="form-control" :class="{ 'error-border': errors[0] }"
              maxlength="50" v-model="form.street" @change="changeForm" :disabled="disableAddress" />
            <span v-if="errors[0] === 'requiredStreet'" :class="{ error: errors[0] }">
              <CircleXMark iconInText="true" />
              {{ $tExistsOrDefault("personal.form.street.required") }}
            </span>
            <span v-if="errors[0] === 'invalidStreet'" :class="{ error: errors[0] }">
              <CircleXMark iconInText="true" />
              {{ $tExistsOrDefault("personal.form.street.invalid") }}
            </span>
          </ValidationProvider>
        </div>
      </ValidationObserver>
      <div class="form-group">
        <label for="cityInput">{{
          $tExistsOrDefault("personal.form.city.label")
        }}</label>
        <ValidationProvider rules="required" :debounce="200" v-slot="{ errors }">
          <input id="cityInput" type="text" class="form-control" :class="{ 'error-border': errors[0] }" maxlength="50"
            v-model="form.city" @change="changeForm" :disabled="disableAddress" />
          <span v-if="errors[0]" :class="{ error: errors[0] }">
            <CircleXMark iconInText="true" />
            {{ $tExistsOrDefault("personal.form.city.required") }}
          </span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import AddressUtils from "@/utils/address";
import CircleXMark from "@/assets/icons/CircleXMark.vue";
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import AddressConst from "@/constants/address";
import H2 from "@/components/shared/2.0-import/text/H2.vue";
import NationalityService from '@/helpers/nationality-service';
import DropdownWithLabel from '@/components/shared/2.0-import/DropdownWithLabel.vue';

export default {
  name: "PersonalDataAddress",
  components: {
    ValidationProvider,
    ValidationObserver,
    CircleXMark,
    H2,
    DropdownWithLabel
  },
  props: {
    initialForm: Object,
  },
  data() {
    return {
      form: { ...this.initialForm },
    };
  },
  computed: {
    ...mapGetters(["disableAddress"]),
    nationalities() {
      var allNationalities = NationalityService.getNationalities();
      var allowedCountries = NationalityService.getAllowedCountryCodesForAddress();
      return allNationalities.filter((n) => allowedCountries.includes(n.key));
    },
    selectedCountryIsNetherlands() {
      return this.selectedCountry === "NL";
    },
    selectedCountry() {
      return this.form.country;
    }
  },
  watch: {
    selectedCountryIsNetherlands() {
      this.changeForm();
      this.$store.dispatch(AddressConst.Actions.SET_DISABLED_ADDRESS, false);
    },
    selectedCountry() {
      this.form.zipcode = null;
      this.form.housenumber = null;
      this.form.housenumberAddition = null;
      this.form.street = null;
      this.form.city = null;
    }
  },
  methods: {
    changeForm() {
      this.$emit("formChange", this.form);
    },
    houseNumberInputChanged() {
      this.form.housenumberAddition = this.form.housenumberAddition?.toUpperCase();
    },  
    isValidStreet() {
      return this.$refs.addressStreetDataObserver.validate();
    },
    async loadAddress(zipCode, housenumber) {
      if (!this.selectedCountryIsNetherlands) {
        return;
      }

      if (
        this.initialForm.zipcode === zipCode &&
        this.initialForm.housenumber === housenumber
      ) {
        return;
      }

      if (!zipCode || !housenumber) {
        this.form.street = null;
        this.form.city = null;
        this.$store.dispatch(AddressConst.Actions.SET_DISABLED_ADDRESS, false);
        return;
      }

      const zipCodeValidationObject = await this.$refs.zipCodeRef.validate();

      if (!zipCodeValidationObject.valid) {
        return;
      }

      AddressUtils.Load(zipCode.toUpperCase(), housenumber)
        .then((response) => {
          if (response.data) {
            this.form.street = response.data.street;
            this.form.city = response.data.city;
            setTimeout(async () => {
              const isValid = await this.isValidStreet();
              this.$store.dispatch(
                AddressConst.Actions.SET_DISABLED_ADDRESS,
                isValid
              );
            }, 200);
          }
        })
        .catch(
          () => (this.form.street = null),
          (this.form.city = null),
          this.$store.dispatch(AddressConst.Actions.SET_DISABLED_ADDRESS, false)
        )

        .finally(() => {
          this.changeForm();
        });

      this.changeForm();
    },
  },
};
</script>

<style lang="scss" scoped></style>
