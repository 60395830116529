<template>
  <div class="input-wrapper">
    <label class="w-100">
      <div v-if="label">
        <font-awesome-icon
          icon="minus"
          class="remove-icon"
          @click="onRemove"
          v-if="removeable"
        />
        <span>
          {{ label }}
        </span>
      </div>
      <ValidationProvider
        v-slot="{ errors, valid }"
        mode="lazy"
        ref="dateInput"
        :debounce="200"
        :rules="{
          required:true,
          validDate:true,
          minDate:minDate,
          maxDate:maxDate
        }"
      >
        <input
          v-focus="!$screen.xs"
          type="text"
          class="form-control"
          v-model="textValue"
          v-mask="'##-##-####'"
          placeholder="dd-mm-jjjj"
          autocomplete="on"
          :class="{'invalid': errors.length > 0}"
        />
          <span class="error-message">{{ errors[0] }}</span>
      </ValidationProvider>
    </label>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import Dates from '@/helpers/dates';

export default {
  name: 'ManualDateInput',
  props: {
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    removeable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Date,
      default: null,
    },
  },
  components: { ValidationProvider },
  data() {
    return {
      textValue: this.value ? Dates.formatDate(this.value) : null,
    };
  },
  methods: {
    onRemove() {
      this.$emit('onRemove');
    }
  },
  watch: {
    async textValue(val) {
      if(val?.length === 10) {
        const valid = await this.$refs.dateInput.validate();
        this.$emit('input', valid ? Dates.dateStringToDate(val) : null);
      }
    }
  }
};
</script>
<style lang="scss" scoped>

input {
  &.invalid {
    border-color: var(--color-error);
    color: var(--color-error);
  }
}

label {
  display: inline-flex;
  flex-direction: column;
  gap: 0.5rem;
}

.error-message {
  color: var(--color-error);
  font-size: var(--text-font-size-14);
}

.remove-icon {
  cursor: pointer;
  color: var(--color-primary-400);
  display: inline;
  margin-right: 10px;
}
</style>