import axios from 'axios';

let instance = axios.create();

instance.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    // handle unauthorized.
  } else {
    if (error.response.status === 400) {
      // handle error
    }
  }
  return Promise.reject(error);
});

export const api = instance;
