<template>
	<svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" fill-rule="evenodd" stroke="#FA6400">
			<circle fill="#FAFAFA" cx="10.5" cy="10.5" r="10"/>
			<path stroke-width="2" stroke-linecap="round" d="M10.4 4v6.57l4.1 4.86"/>
		</g>
	</svg>
</template>
<script>
  export default {
    name: 'NotYetNotCompensated'
  };
</script>