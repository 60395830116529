<template>
  <Card class="card" :class="{'header-column': isHeaderColumn, 'mobile': $screen.xs}">
    <CardSection :horizontalDevider="!isHeaderColumn" class="header-section sticky">
      <div class="d-flex justify-content-end">
        <span 
          class="exclude-from-comparison mb-4" 
          :class="{'invisible': (isHeaderColumn || amountOfCardsCompared <= 2)}" 
          @click="$emit('excludeFromComparison', product.productComponentCode)"
        >
          <Delete />
        </span>
      </div>
      <CompareReimbursementHeader :product="product" :isHeaderColumn="isHeaderColumn" />
    </CardSection>
    <div class="reimbursements">
      <CardSection
        :horizontalDevider="false"
        v-for="(reimbursement, i) in new Array(13)"
        :key="i"
      >
        <CompareReimbursementRow
          v-if="typeOfInsurance !== 'TA'
            || (typeOfInsurance === 'TA' && dentalReimbursementIds.includes(i))"
          :text="getReimbursementValue(i+1)"
          :class="`row${i}`"
          :isHeaderColumn="isHeaderColumn"
          :innerClass="`${conditionalClass} reimbursement`"
        />
      </CardSection>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/shared/2.0-import/Card';
import CardSection from '@/components/shared/2.0-import/CardSection';
import CompareReimbursementRow from '@/components/shared/2.0-import/CompareReimbursementRow';
import CompareReimbursementHeader from '@/components/shared/2.0-import/CompareReimbursementHeader';
import Delete from '@/assets/icons/Delete.vue';

export default {
  name: 'CompareCard',
  components: {
    Card,
    CardSection,
    CompareReimbursementRow,
    CompareReimbursementHeader,
    Delete
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    isHeaderColumn: {
      type: Boolean,
      default: false
    },    
    typeOfInsurance: {
      type: String,
      required: true
    },
    amountOfCardsCompared: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      dentalReimbursementIds: [8,9,10]
    };
  },
  computed: {
    conditionalClass() {
      return `${this.isHeaderColumn ? 'header-column pr-3' : ''}`; 
    }
  },
  methods: {
    getReimbursementValue(key) {
      const reimbursement = this.product.reimbursementTypes.find(r => r.key === key);
      if (!reimbursement || !reimbursement.value) {
        return this.$tExistsOrDefault('calculatePremium.additionalInsurance.noCompensation');
      }
      return reimbursement.value;
    },
  },
};
</script>

<style lang="scss" scoped>

.card {
  min-width: 270px;
  width: 270px;
  border-radius: 0px;
  border: none;
  background-color: var(--color-grey-100);

  &.header-column {
    background: var(--color-primary-050);

    &:not(.mobile) {
      padding-left: 4rem;
      width: calc(270px + 4rem);
    }
    &.mobile {
      min-width: 170px;
      width: 170px;
    }
  }
}

.card:nth-child(even),
.card:nth-child(even) .header-section {
  background-color: white;
}
.card:nth-child(odd):not(.header-column),
.card:nth-child(odd):not(.header-column) .header-section {
  background-color: var(--color-grey-100);
}

.exclude-from-comparison {
  color: var(--color-primary-900);
  cursor: pointer; 
}
// .sticky {
//   position: -webkit-sticky; /* Safari */
//   position: sticky !important;
//   top: -32px !important; 
//   width: 100%;
//   align-self: flex-start; 
// }

</style>