<template>
  <div class="indicator-container">
    <div
      :class="getIndicatorClass(i)"
      :style="getIndicatorStyle(i)"
      v-for=" i in maxLevel"
      :key="i"
    />
  </div>
</template>

<script>
export default {
  name: 'LevelIndicator',
  props:{
    level: {
        type: Number,
        required: true,
    },
    maxLevel: {
        type: Number,
        required: true,
    }
  },
  methods: {
    getIndicatorClass(level){
      return level > this.level ? 'indicator' : 'indicator filled';
    },
    getIndicatorStyle(level){
      return {
        height: `${(level + 1) / (this.maxLevel + 1) * 100}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator-container {
    margin-left: 8px;
    display: inline-flex;
    flex-direction: row;
    align-items: baseline;
    gap: 2px;
    height: 17px;
     .indicator {
        width: 5px;
        background-color: var(--color-grey-100);
    }
    .filled {
        background-color: var(--color-accent);
    }
}
</style>
