<template>
  <Container :padded="false" v-if="!$screen.xs">
    <RectangleWithShadow>
      <slot  />
    </RectangleWithShadow>
  </Container>
  <Container v-else :background-alt="true">
    <slot />
  </Container>
</template>

<script>
import RectangleWithShadow from '@/components/shared/RectangleWithShadow.vue';
import Container from '@/components/shared/2.0-import/Container.vue';

export default {
  name: 'RectangleWithShadowDesktopOnly',
  components: {
    RectangleWithShadow,
    Container
  }
};
</script>