<template>
  <PartialOverlayWithCloseButton
    v-model="modelValue"
  >
    <div>
      <section id="section-introduction">
        <H1 id="overlay-title">{{ $tExistsOrDefault('calculatePremium.basicInsurance.ownRiskOverlay.title') }}</H1>
        <PlainText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.ownRiskOverlay.introduction') }}</PlainText>
      </section>
      <section id="section-explanation">
        <H2>{{ $tExistsOrDefault('calculatePremium.basicInsurance.ownRiskOverlay.sectionExplanation.title') }}</H2>
        <ul>
          <li v-for="item in $tExistsOrDefault('calculatePremium.basicInsurance.ownRiskOverlay.sectionExplanation.items')" :key="item">
            <BulletPoint />
            <PlainText display="inline">{{ item }}</PlainText>
          </li>
        </ul>
      </section>
    </div>
  </PartialOverlayWithCloseButton>
</template>

<script>
import PartialOverlayWithCloseButton from '@/components/shared/2.0-import/PartialOverlayWithCloseButton.vue';
import H1 from '@/components/shared/2.0-import/text/H1.vue';
import H2 from '@/components/shared/2.0-import/text/H2.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import BulletPoint from '@/assets/icons/BulletPoint.vue';

export default {
  name: 'OwnRiskOverlay',
  components: {
    PartialOverlayWithCloseButton,
    H1,
    H2,
    PlainText,
    BulletPoint
  },
  props: {
    value: Boolean
  },
  data() {
    return {
    };
  },
  computed: {
    modelValue: {
      get() { 
        return this.value; 
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    onClose() {
      this.modelValue = false;
    },
  },
};
</script>

<style lang='scss' scoped>
#overlay-title {
  margin-bottom: 14px;
}

section {
  margin-bottom: 24px;
}
H2{
    margin-bottom: 14px;
}

ul{
    list-style: none;
    li{
        display: flex;
        flex-direction: row;
        gap:8px;
        color: var(--color-primary-800);
        margin-bottom: 4px;
        :first-child{
          margin-top: 8px;
          flex-shrink: 0;
        }
    }
}

</style>
