<template>
  <div class="vh-100">
    <div id="component-header" class="text-right text-md-left">
      <CloseButton @click.native="onClose" />
    </div>
    <div id="component-body" :class="{'padded': padded}">
      <slot />
    </div>
  </div>
</template>

<script>
import CloseButton from '@/components/shared/button/CloseButton.vue';

export default {
  name: 'CloseButtonStickyHeader',
  emits: ['close'],
  components: {
    CloseButton
  },
  props: {
    padded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang='scss' scoped>
#component-header {
    padding: 24px 40px;
    @media screen and (max-width: 767px) {
        padding: 10px 24px;
    }
}
#component-body{
    height: calc(100% - 85px);
    &.padded {
        padding: 0 40px;
        @media screen and (max-width: 767px) {
            padding: 0 24px;
        }
    }
    overflow-y: auto;
}
</style>
