import ProductComponentCodes from '@/constants/ProductComponentCodes';
import Vue from 'vue';

export default class Insurance {
  static basicInsuranceTitle(basicInsurance) {
    const allDefaultProductComponentCodes = Object.values(ProductComponentCodes);

    if (allDefaultProductComponentCodes.find(x => x.toString() === basicInsurance.productComponentCode)) {
        return Vue.prototype.$tExistsOrDefault(`calculatePremium.previewOverview.basicInsuranceTitle.${basicInsurance.productComponentCode}`)
    }
    else {
        return basicInsurance.title;
    }
  }
}
