<template>
  <div class="card-wrapper">
    <slot class="card" />
  </div>
</template>

<script>
export default {
  name: 'Card',
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  border-radius: 5px;
  padding: 20px;
}
</style>