<template>
  <div class="form-content">
    <div>
      <ValidationObserver
        ref="RevisitEmailObserver" tag="form"
        v-slot="{ invalid }"
        @submit.prevent="sendMail"
      >
        <div class="form-group row">
          <label class="col-12 col-md-4 col-form-label">{{ $tExistsOrDefault('personal.form.email.label') }}</label>
          <div class="col-12 col-md-8">
            <ValidationProvider rules="required" v-slot="{ errors }" mode="lazy">
              <input
                type="email" class="form-control mb-2 "
                v-model="form.emailaddress"
                :class="{'error-border': errors[0]}"
              />
              <span v-visible="errors[0]" class="error">
                <CircleXMark iconInText="true" />{{ $tExistsOrDefault('personal.form.email.required') }}
              </span>
            </ValidationProvider>
          </div>
        </div>
        <div :class="{ 'center-button': $screen.xs }">
          <Button
            @click.native="sendMail"
            :class="{ 'float-right': !$screen.xs }"
          >
            {{ $t('overview.revisit-email-popup.send-mail') }}
          </Button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';

import CircleXMark from '@/assets/icons/CircleXMark.vue';

import ModalDataFactory from '@/components/modals/ModalDataFactory';

import Revisit from '@/constants/revisit';
import Modal from '@/constants/modal';
import Button from '@/components/shared/button/Button.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CircleXMark,
    Button
  },
  data() {
    return {
      isValid: false,
      form: {
        emailaddress: ''
      }
    };
  },
  computed: {
    ...mapGetters(['agreement']),
    valid() {
      return this.$refs.RevisitEmailObserver.validate();
    },
  },
  methods: {
    async sendMail() {
      if (await this.valid) {
        this.isValid = true;
        this.$store.dispatch(Revisit.Actions.SAVE, { agreementModel: this.agreement, type: 'email', emailDetails: this.form, step: Revisit.RevisitStepEnum.unknown /* step is not important for email sending. */ });

        this.$store.dispatch(Modal.Actions.CLOSE);
        this.$store.dispatch(Modal.Actions.OPEN, {
          modalData: ModalDataFactory.createEmailSuccessfullySend()
        }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-content {
  padding-top: 20px;
}

.center-button {
  text-align: center;
}
</style>