import { max, cloneDeep } from 'lodash-es';
import store from '@/store';

import Vue from 'vue';

import BusinessRuleModels from '@/models/business-rule';
import BusinessRule from '@/constants/business-rule';
import BusinessRuleUtils from '@/utils/business-rule';

import Modal from '@/constants/modal';

import CalculatePremiumHelpers from '@/helpers/calculate-premium';
import PhoneHelpers from '@/helpers/phone';
import DateHelpers from '@/helpers/dates';

import ModalDataFactory from '@/components/modals/ModalDataFactory';

function createBusinessRule() {
  let br = BusinessRuleModels.BusinessRule.create();
  let insuredMain = BusinessRuleModels.Insured.create('H');
  // This is the default date that the user can still edit
  br.agreementModel.startDate = DateHelpers.formatDate(CalculatePremiumHelpers.determineStartDate(false));
  br.agreementModel.insuredPersons.push(insuredMain);
  return br;
}

const defaultVisistedPackageTypes = ['OW', 'MB'];

const state = {
  businessRule: createBusinessRule(),
  businessRuleBeforeChange: null,
  hasPackageSelectionConflict: false,
  temporaryStartDate: true,
  productList: [],
  nettoPremiums: {
    totalPremium: 0
  },
  isBusinessRuleLoading: {
    'POST': false
  },
  dentalInsuranceAvailable: true,
  additionalInsuranceAvailable: true,
  extraAdditionalInsuranceAvailable: true,
  visitedPackageTypes: [...defaultVisistedPackageTypes],
  enableAnswer5Options: {}
};

const getters = {
  businessRule: state => state.businessRule,
  businessRuleBeforeChange: state => state.businessRuleBeforeChange,
  hasPackageSelectionConflict: state => state.hasPackageSelectionConflict, 
  selectedPaymentTermInConfiguration: state => state.businessRule.agreementModel.paymentTerm,
  agreement: state => state.businessRule.agreementModel,
  insured: state => state.businessRule.agreementModel.insuredPersons,
  temporaryStartDate: state => state.temporaryStartDate,
  productList: state => state.productList,
  nettoPremiums: state => state.nettoPremiums,
  dentalInsuranceAvailable: state => state.dentalInsuranceAvailable,
  additionalInsuranceAvailable: state => state.additionalInsuranceAvailable,
  extraAdditionalInsuranceAvailable: state => state.extraAdditionalInsuranceAvailable,
  extraAdditionalPackageTypes: () => ['XA', 'AE'],
  startDate: state => DateHelpers.dateStringToDate(state.businessRule.agreementModel.startDate),
  isBusinessRulePostLoading: state => state.isBusinessRuleLoading['POST'],
  lastPolicyYear: (state, getters) => {
    return state.businessRule.lastPolicyYear === true &&  getters.startDate.getFullYear() === new Date().getFullYear();
  },
  visitedPackageTypes: state => state.visitedPackageTypes,
  enableAnswer5Options: state => state.enableAnswer5Options,
  getDateOfBirth: (_, getters) => (personId) => {
    return DateHelpers.dateStringToDate(getters.insured.find(ins => ins.id === personId).dateOfBirth);  
  },
  getIsChildYoungerThan18: (_, getters) => (personId) => {
    const insured = getters.insured.find(ins => ins.id === personId);

    return insured.typeOfInsured === 'M' && !(DateHelpers.isOldEnough(getters.getDateOfBirth(insured.id), getters.startDate, 18));
  },
  paymentTermNetDiscounts: state => state.businessRule.agreementModel.paymentTermNetDiscounts,
};

const actions = {
  [BusinessRule.Actions.SET_NEWSLETTER]({ commit }, payload) {
    commit(BusinessRule.Mutations.SET_NEWSLETTER, payload);
  },
  [BusinessRule.Actions.SET_DIGITAL_POLICY]({ commit }, payload) {
    commit(BusinessRule.Mutations.SET_DIGITAL_POLICY, payload);
  },
  [BusinessRule.Actions.SELECT_PRODUCT_FOR_PERSON]({ commit }, data) {
    commit(BusinessRule.Mutations.SET_PREVIOUS_SELECTION_STATE);
    commit(BusinessRule.Mutations.REMOVE_ALL_PACKAGES_WHEN_DISABLED_BASIC_INSURANCE_SELECTED, data);
    commit(BusinessRule.Mutations.REMOVE_DUPLICATE_PACKAGE_TYPES, data);
    commit(BusinessRule.Mutations.SET_CHOSEN_INSURANCE, data);
  },

  [BusinessRule.Actions.REMOVE_PRODUCT_FOR_PERSON]({ commit }, data) {
    commit(BusinessRule.Mutations.REMOVE_CHOSEN_INSURANCE, data);
  },

  [BusinessRule.Actions.ADD_VISITED_PACKAGE_TYPE]({ commit }, data){
    commit(BusinessRule.Mutations.ADD_VISITED_PACKAGE_TYPE, data);
  },

  [BusinessRule.Actions.RESET_VISITED_PACKAGE_TYPES]({ commit }){
    commit(BusinessRule.Mutations.SET_VISITED_PACKAGE_TYPES, defaultVisistedPackageTypes);
  },

  [BusinessRule.Actions.POST]({ commit, dispatch, state, getters }) {
    return BusinessRuleUtils.Post(state.businessRule)
      .then(response => {
        commit(BusinessRule.Mutations.UPDATE_BUSINESS_RULE, response.data);
      }).then(() => {
        dispatch(BusinessRule.Actions.CALCULATE_PAYMENT_TERMS);
        dispatch(BusinessRule.Actions.CALCULATE_NETTO_PREMIUMS);
        commit(BusinessRule.Mutations.SET_PAYMENT_METHOD_AND_TERM, {
          paymentMethod: getters.selectedContract.paymentMethodCode, 
          paymentTerm: getters.selectedPaymentTermInConfiguration
        });
        if(state.hasPackageSelectionConflict){
          dispatch(Modal.Actions.OPEN, { 
            modalData: ModalDataFactory.createConfigurationWarning(),
            isPersistent: true
          });
        }
      });
  },

  [BusinessRule.Actions.CALCULATE_NETTO_PREMIUMS]({ commit, getters }) {
    const request = {
      contractCode: getters.agreement.contractCode,
      startDate: getters.agreement.startDate,
      persons: getters.agreement.insuredPersons,
      lastPolicyYear: getters.lastPolicyYear
    };
    return BusinessRuleUtils.CalculatePremiums(request)
      .then((response) => commit(BusinessRule.Mutations.SET_NETTO_PREMIUMS, response.data));
  },

  /**
   * Set the date of birth of an insured and handle whether the partner should follow the main insured.
   * @param {{insuredId: Number, dateOfBirth: Date}} data
   */
  [BusinessRule.Actions.SET_DATE_OF_BIRTH_FOR_INSURED]({ commit, getters }, data) {
    commit(BusinessRule.Mutations.SET_DATE_OF_BIRTH_FOR_INSURED, { insuredId: data.insuredId, dateOfBirth: data.dateOfBirth });

    const insured = getters.insured.find(person => person.id === data.insuredId);
    if (insured.typeOfInsured !== 'H') {
      const selectionHasChangedValue = DateHelpers.isOldEnough(data.dateOfBirth, getters.startDate, 18);
      commit(BusinessRule.Mutations.SET_SELECTIONHASCHANGED, { insuredId: data.insuredId, value: selectionHasChangedValue });
    }
  },

  [BusinessRule.Actions.CALCULATE_PAYMENT_TERMS]({ commit, state }) {
    return BusinessRuleUtils.CalculatePaymentTerms(state.businessRule.agreementModel)
    .then((response) => commit(BusinessRule.Mutations.UPDATE_PAYMENT_TERMS, response.data));
  },

  [BusinessRule.Actions.CLEAR]({ commit }) {
    commit(BusinessRule.Mutations.CLEAR);
  },

  [BusinessRule.Actions.SUBMIT_AGREEMENT]({ getters }) {
    return BusinessRuleUtils.SubmitAgreement(getters.agreement);
  },

  [BusinessRule.Actions.LOAD_PAYMENT_INFO_BY_SUBCONTRACT]({ commit }) {
    return BusinessRuleUtils.LoadPaymentInfoBySubcontract(this.getters.selectedContractCode, this.getters.agreement.targetGroupCode, this.getters.lastPolicyYear)
      .then(response => {
        commit(BusinessRule.Mutations.SET_PAYMENT_METHOD_AND_TERM, response.data);
      });
  },
  [BusinessRule.Actions.REVERT_TO_PREVIOUS_SELECTION_STATE]({ commit }) {
    commit(BusinessRule.Mutations.REVERT_TO_PREVIOUS_SELECTION_STATE);
  },
  [BusinessRule.Actions.SET_HAS_PACKAGE_SELECTION_CONFLICT_FALSE]({ commit }) {
    commit(BusinessRule.Mutations.SET_HAS_PACKAGE_SELECTION_CONFLICT_FALSE);
  },
  [BusinessRule.Actions.SET_AGREEMENT]({ commit }, payload) {
    commit(BusinessRule.Mutations.SET_AGREEMENT, payload);
  },
};

const mutations = {
  [BusinessRule.Mutations.SET_COLLECTIVITY_LABEL](state) {
    state.businessRule.agreementModel.collectivityLabel = this.getters.labelSettings.label;
  },
  [BusinessRule.Mutations.SET_NEWSLETTER](state, payload) {
    state.businessRule.agreementModel.hasDigitalNews = payload;
  },
  [BusinessRule.Mutations.SET_DIGITAL_POLICY](state, payload) {
    state.businessRule.agreementModel.hasDigitalPolicy = payload;
  },
  [BusinessRule.Mutations.UPDATE_BUSINESS_RULE](state, payload) {
    let result = [];
    payload['productComponentCardCollectionPerPerson'].forEach(person => {
      person.productComponentsCardGroup.forEach(productGroup => {
        if (result.find(cardGroupId => cardGroupId === productGroup.cardGroupId) == null) {
          result.push({
            cardGroupId: productGroup.cardGroupId,
            packageSet: productGroup.packageSet,
            packageType: productGroup.packageType,
            products: []
          });
        }
        let resultProductGroup = result.find(pgroup => pgroup.cardGroupId === productGroup.cardGroupId);
        productGroup.productComponentCardCollection.forEach(product => {
          if (product.packageTypeForWebApplications !== 'OW') {
            if (resultProductGroup.products.find(p => p.productComponentCode === product.productComponentCode) == null) {
              resultProductGroup.products.push({
                title: product.title,
                uniqueSellingPoints: product.uniqueSellingPoints,
                reimbursementTypes: product.reimbursementTypes,
                productComponentSelectionStatus: product.productComponentSelectionStatus,
                isMostChosen: product.isMostChosen,
                isSelected: product.isSelected,
                hasMedicalSelection: product.hasMedicalSelection,
                productComponentCode: product.productComponentCode,
                typeOfBasicInsurance: product.typeOfBasicInsurance,
                documentInsurancePolicy: product.documentInsurancePolicy,
                documentConditionsPolicy: product.documentConditionsPolicy,
                packageSetNumber: product.packageSetNumber,
                ownRiskAmount: product.ownRiskAmount,
                ownRiskDiscount: product.ownRiskDiscount,
                packageTypeForWebApplications: product.packageTypeForWebApplications,
                shouldResetSelectedProductComponents: product.shouldResetSelectedProductComponents,
                insuranceCardLink: product.insuranceCardLink,
                persons: []
              });
            }
            let resultProduct = resultProductGroup.products.find(p => p.productComponentCode === product.productComponentCode);
            resultProduct.persons.push({
              id: person.personId,
              typeOfInsured: person.typeOfInsured,
              dateOfBirth: person.dateOfBirth,

              grossPremium: product.grossPremium,
              netPremiumWithDiscount: product.netPremiumWithDiscount,
              isDisabled: product.isDisabled,
              isMerged: product.isMerged,
              isSelected: product.isSelected,
              percentagePaidByEmployer: product.percentagePaidByEmployer,
              ownRiskAmount: product.ownRiskAmount,
              ownRiskDiscount: product.ownRiskDiscount,
              dentalExtractedPrice: product.dentalExtractedPrice,
              dentalExtractedPercentage: product.dentalExtractedPercentage
            });
          } else {
            if (resultProductGroup.products.find(p => p.ownRiskAmount === product.ownRiskAmount) == null) {
              resultProductGroup.products.push({
                isSelected: product.isSelected,
                ownRiskAmount: product.ownRiskAmount,
                ownRiskDiscount: product.ownRiskDiscount,
                isDisabled: product.isDisabled,
                packageTypeForWebApplications: product.packageTypeForWebApplications,
                persons: []
              });
            }
            let resultProduct = resultProductGroup.products.find(p => p.ownRiskAmount === product.ownRiskAmount);
            resultProduct.persons.push({
              id: person.personId,
              typeOfInsured: person.typeOfInsured,
              dateOfBirth: person.dateOfBirth,
              grossPremium: product.grossPremium,
              netPremiumWithDiscount: product.netPremiumWithDiscount,
              isDisabled: product.isDisabled,
              isMerged: product.isMerged,
              isSelected: product.isSelected,
              percentagePaidByEmployer: product.percentagePaidByEmployer,
              ownRiskAmount: product.ownRiskAmount,
              ownRiskDiscount: product.ownRiskDiscount,
              dentalExtractedPrice: product.dentalExtractedPrice,
              dentalExtractedPercentage: product.dentalExtractedPercentage
            });
          }
        });
      });
    });
    state.productList = result;
    state.businessRule.agreementModel = payload['agreementModel'];
    state.hasPackageSelectionConflict = state.businessRule.agreementModel.clearChosenInsurances;
    state.dentalInsuranceAvailable = result.find(group => group.packageType === 'TA') !== undefined;
    state.additionalInsuranceAvailable = result.find(group => group.packageType === 'AP') !== undefined;
    state.extraAdditionalInsuranceAvailable = result.find(group => this.getters.extraAdditionalPackageTypes.includes(group.packageType)) !== undefined;
  },

  [BusinessRule.Mutations.SET_PREVIOUS_SELECTION_STATE](state) {
    state.businessRuleBeforeChange = cloneDeep(state.businessRule);
  },

  [BusinessRule.Mutations.REVERT_TO_PREVIOUS_SELECTION_STATE](state) {
    state.hasPackageSelectionConflict = false;
    state.businessRule = cloneDeep(state.businessRuleBeforeChange);
  },

  [BusinessRule.Mutations.REMOVE_ALL_PACKAGES_WHEN_DISABLED_BASIC_INSURANCE_SELECTED](state, payload) {
    const person = payload.person;
    const product = payload.product;

    if (person.isDisabled && product.packageTypeForWebApplications === 'MB') {
      const chosenInsurances = state.businessRule.agreementModel.insuredPersons.find(insured => insured.id === person.id).chosenInsurances;
      const onlyBasicInsurance = chosenInsurances.filter(ci => ci.packageTypeForWebApplications === 'MB');
      state.businessRule.agreementModel.insuredPersons.find(insured => insured.id === person.id).chosenInsurances = onlyBasicInsurance;
    }
  },

  [BusinessRule.Mutations.SET_HAS_PACKAGE_SELECTION_CONFLICT_FALSE](state) {
    state.businessRule.agreementModel.clearChosenInsurances = false;
    state.hasPackageSelectionConflict = false;
  },

  [BusinessRule.Mutations.SET_START_DATE](state, payload) {
    state.businessRule.agreementModel.startDate = DateHelpers.formatDate(payload);

    state.temporaryStartDate = false;
    state.businessRule.agreementModel.insuredPersons.forEach(insured => {
      CalculatePremiumHelpers.resetDateOfBirthIfNoLongerValid(
        insured, this.getters.startDate);
    });
  },

  [BusinessRule.Mutations.SET_CONTRACT_CODE_IN_BR](state, payload) {
    state.businessRule.contractCode = payload;
    state.businessRule.agreementModel.contractCode = payload;
  },

  [BusinessRule.Mutations.ADD_INSURED](state, payload) {
    const maxId = state.businessRule.agreementModel.insuredPersons.length !== 0
      ? max(state.businessRule.agreementModel.insuredPersons.map(person => person.id))
      : 1;

    switch (payload.typeOfInsured) {
      case 'P':
        if (!state.businessRule.agreementModel.insuredPersons.some(i => i.typeOfInsured === 'P')) {
          state.businessRule.agreementModel.insuredPersons.push(
            BusinessRuleModels.Insured.create('P', maxId + 1));
        }
        break;
      case 'M':
        state.businessRule.agreementModel.insuredPersons.push(
          BusinessRuleModels.Insured.create('M', maxId + 1));
        break;
    }
  },

  [BusinessRule.Mutations.REMOVE_INSURED](state, payload) {
    // payload = insuredPerson.id
    const index = state.businessRule.agreementModel.insuredPersons
      .findIndex(insured => insured.id === payload);

    state.businessRule.agreementModel.insuredPersons.splice(index, 1);
  },

  /**
   * Set the dateOfBirth on an insured
   * @param {{insuredId: Number, dateOfBirth: Date | String}} payload
   */
  [BusinessRule.Mutations.SET_DATE_OF_BIRTH_FOR_INSURED](state, payload) {
    const insuredPerson = state.businessRule.agreementModel.insuredPersons
      .find(insured => insured.id === payload.insuredId);

    // Weird call, but apparently the most reliable way to check the type of an object.
    if (payload.dateOfBirth && Object.prototype.toString.call(payload.dateOfBirth) === '[object Date]') {
      insuredPerson.dateOfBirth = DateHelpers.formatDate(payload.dateOfBirth);
    } else {
      insuredPerson.dateOfBirth = payload.dateOfBirth;
    }
  },

  /**
   * Set the value selectionHasChanged on an insured to the passed value
   * @param {Object} payload
   * @param {Number} payload.insuredId The id of the insured to change
   * @param {Boolean} payload.value The value to set selectionHasChanged to
   */
  [BusinessRule.Mutations.SET_SELECTIONHASCHANGED](state, payload) {
    const insuredPerson = state.businessRule.agreementModel.insuredPersons
      .find(insured => insured.id === payload.insuredId);

    insuredPerson.selectionHasChanged = payload.value;
  },

  [BusinessRule.Mutations.SET_LOADING](state, payload) {
    Vue.set(state.isBusinessRuleLoading, payload.type, payload.value);
  },

  [BusinessRule.Mutations.SET_CHOSEN_INSURANCE](state, payload) {
    // Chosen Insurances updaten adhv Product en Person
    const insuredPerson = state.businessRule.agreementModel.insuredPersons
      .find(insured => insured.id === payload.person.id);

    if (insuredPerson.typeOfInsured !== 'H') {
      insuredPerson.selectionHasChanged = true;
    }

    if (payload.product.packageTypeForWebApplications === 'OW') {
      insuredPerson.chosenOwnRisk = payload.product.ownRiskAmount;
      insuredPerson.chosenOwnRiskDiscount = payload.product.ownRiskDiscount;
    } else {
      insuredPerson.chosenInsurances.push({
        title: payload.product.title,
        hasMedicalSelection: payload.product.hasMedicalSelection,
        productComponentCode: payload.product.productComponentCode,
        typeOfBasicInsurance: payload.product.typeOfBasicInsurance,
        packageSetNumber: payload.product.packageSetNumber,
        packageTypeForWebApplications: payload.product.packageTypeForWebApplications,
        shouldResetSelectedProductComponents: payload.product.shouldResetSelectedProductComponents,
        grossPremium: payload.person.grossPremium,
        netPremiumWithDiscount: payload.person.netPremiumWithDiscount,
        percentagePaidByEmployer: payload.person.percentagePaidByEmployer
      });
    }
  },

  [BusinessRule.Mutations.REMOVE_CHOSEN_INSURANCE](state, payload) {
    const insuredPerson = state.businessRule.agreementModel.insuredPersons
      .find(insured => insured.id === payload.person.id);

    insuredPerson.selectionHasChanged = true;
    insuredPerson.chosenInsurances = insuredPerson.chosenInsurances.filter(ci => ci.productComponentCode !== payload.product.productComponentCode);
  },

  [BusinessRule.Mutations.REMOVE_DUPLICATE_PACKAGE_TYPES](state, payload) {
    const insuredPerson = state.businessRule.agreementModel.insuredPersons
      .find(insured => insured.id === payload.person.id);
    const packageType = payload.product.packageTypeForWebApplications;
    const chosenProductComponentCode = payload.product.productComponentCode;
    let maxAmount = 1;

    if (packageType === 'AP') {
      maxAmount = this.getters.selectedContract.maximumAdditionalInsurances;
    } else if (packageType === 'TA') {
      maxAmount = this.getters.selectedContract.maximumDentistInsurances;
    }

    if (maxAmount === 1 || chosenProductComponentCode === null) {
      insuredPerson.chosenInsurances = insuredPerson.chosenInsurances.filter(ci => ci.packageTypeForWebApplications !== packageType);
    } else {
      let chosenInsuranceTypes = insuredPerson.chosenInsurances.filter(ci => ci.packageTypeForWebApplications === packageType);
      if (chosenInsuranceTypes.find(ci => ci.productComponentCode === null) !== undefined) {
        insuredPerson.chosenInsurances = insuredPerson.chosenInsurances.filter(ci => ci.packageTypeForWebApplications !== packageType);
      } else {
        if (chosenInsuranceTypes.length === maxAmount) {
          let chosenInsuranceToRemove = insuredPerson.chosenInsurances.find(ci => ci.packageTypeForWebApplications === packageType);
          insuredPerson.chosenInsurances = insuredPerson.chosenInsurances.filter(ci => ci.productComponentCode !== chosenInsuranceToRemove.productComponentCode);
        }
      }
    }
  },

  [BusinessRule.Mutations.CLEAR_PACKAGE_TYPE_FOR_PERSON](state, payload) {
    const insuredPerson = state.businessRule.agreementModel.insuredPersons
      .find(insured => insured.id === payload.person.id);
    insuredPerson.chosenInsurances = insuredPerson.chosenInsurances.filter(ci => ci.packageTypeForWebApplications !== payload.type);
  },

  [BusinessRule.Mutations.SET_MEDICAL_SELECTION_ANSWERS](state, { personId, answers }) {
    const insured = state.businessRule.agreementModel.insuredPersons.find(p => p.id === personId);
    Vue.set(insured.medicalSelection, 'question1', answers.answer1);
    Vue.set(insured.medicalSelection, 'question2', answers.answer2);
    Vue.set(insured.medicalSelection, 'question3', answers.answer3);
    Vue.set(insured.medicalSelection, 'additionalInfoQuestion3', answers.answer3Comment);
    Vue.set(insured.medicalSelection, 'question4', answers.answer4);
    Vue.set(insured.medicalSelection, 'additionalInfoQuestion4', answers.answer4Comment);
    Vue.set(insured.medicalSelection, 'question5', answers.answer5);
  },
  [BusinessRule.Mutations.SET_MEDICAL_SELECTION_ANSWER5_ENABLEMENT](state, payload) {
    Vue.set(state.enableAnswer5Options, payload.key, payload.value);
  },
  [BusinessRule.Mutations.RESET_MEDICAL_SELECTION_ANSWERS](state) {
    state.businessRule.agreementModel.insuredPersons.forEach(insuredPerson => {
      Vue.set(insuredPerson.medicalSelection, 'question1', null);
      Vue.set(insuredPerson.medicalSelection, 'question2', null);
      Vue.set(insuredPerson.medicalSelection, 'question3', null);
      Vue.set(insuredPerson.medicalSelection, 'additionalInfoQuestion3', '');
      Vue.set(insuredPerson.medicalSelection, 'question4', null);
      Vue.set(insuredPerson.medicalSelection, 'additionalInfoQuestion4', '');
      Vue.set(insuredPerson.medicalSelection, 'question5', []);
    });
    state.enableAnswer5Options = {};
  },

  [BusinessRule.Mutations.SET_NETTO_PREMIUMS](state, payload) {
    state.nettoPremiums = payload;
  },

  [BusinessRule.Mutations.SET_PAYMENT_INFO](state, payload) {
    const agreement = state.businessRule.agreementModel;
    agreement.hasCancellationService = payload.hasAgreedToTransferService;
    agreement.paymentInformation.primaryBankAccountNumber = payload.IBAN.toUpperCase().replace(/\s/g, '');
    if(store.getters.selectedContract.paymentMethodCode === 'B')
    {
      agreement.PaymentMethodForCosts = payload.paymentMethod;
      agreement.paymentMethod = 'B';
    } else if (store.getters.selectedContract.paymentMethodCode === 'I') {
      agreement.PaymentMethodForCosts = payload.paymentMethod;
      agreement.paymentMethod = 'I';
    } else {
      agreement.paymentMethod = payload.paymentMethod;
      agreement.PaymentMethodForCosts = payload.paymentMethod;
    }
  },

  [BusinessRule.Mutations.SET_PAYMENT_TERM_SELECTION](state, payload) {
    Vue.set(state.businessRule.agreementModel, 'paymentTerm', payload);
  },

  [BusinessRule.Mutations.UPDATE_PAYMENT_TERMS](state, payload) {
    state.businessRule.agreementModel.paymentTermNetDiscounts = payload.paymentTermNetDiscounts;
  },

  [BusinessRule.Mutations.SET_PERSONAL_DATA](state, payload) {
    payload.data.insureds.forEach(insuredForm => {
      const insured = state.businessRule.agreementModel.insuredPersons.find(p => p.id === insuredForm.personId);
      insured.initials = insuredForm.initials;
      insured.firstName = insuredForm.firstName;
      insured.lastNamePrefix = insuredForm.lastNamePrefix;
      insured.lastName = insuredForm.lastName;
      insured.gender = insuredForm.gender;
      insured.email = insuredForm.email;
      insured.socialSecurityNumber = insuredForm.socialSecurityNumber;
      insured.nationality = insuredForm.nationality;
    });

    const mainInsured = state.businessRule.agreementModel.insuredPersons.find(p => p.typeOfInsured === 'H');
    if (mainInsured) {
      state.businessRule.agreementModel.address.email = mainInsured.email;
    }
  },

  [BusinessRule.Mutations.SET_PERSONAL_EXTRA_DATA](state, payload) {
    state.businessRule.agreementModel.address.zipcode = payload.data.address.zipcode;
    state.businessRule.agreementModel.address.housenumber = payload.data.address.housenumber;
    state.businessRule.agreementModel.address.housenumberAddition = payload.data.address.housenumberAddition;
    state.businessRule.agreementModel.address.street = payload.data.address.street;
    state.businessRule.agreementModel.address.city = payload.data.address.city;
    state.businessRule.agreementModel.address.country = payload.data.address.country;
    state.businessRule.agreementModel.address.telephoneNumber = PhoneHelpers.normalizePhoneNumber(payload.data.contact.telephoneNumber);
    state.businessRule.agreementModel.languageCode = payload.data.contact.languageCode;
    state.businessRule.agreementModel.targetGroupCode = payload.data.employer.targetGroupCode;
    state.businessRule.agreementModel.employeeNumber = payload.data.employer.employeeNumber;

    const mainInsured = state.businessRule.agreementModel.insuredPersons.find(p => p.typeOfInsured === 'H');
    if (mainInsured) {
      mainInsured.telephoneNumber = payload.data.contact.telephoneNumber;
      mainInsured.employeeNumber = payload.data.employer.employeeNumber;
    }
  },

  [BusinessRule.Mutations.CLEAR](state) {
    state.businessRule = createBusinessRule();
    state.temporaryStartDate = true;
  },

  [BusinessRule.Mutations.SET_LASTPOLICYYEAR](state, payload) {
    state.businessRule.lastPolicyYear = payload.lastPolicyYear;
  },

  [BusinessRule.Mutations.SET_SHOULDLOCKCONTRACT](state, payload) {
    state.businessRule.shouldLockContract = payload.value;
  },

  /**
   * Set the language of the business rule object. Not the agreementModel. This field must be set to get USP's in the correct language.
   * @param {{language: String}} payload The payload containing the language to set the business rule object to.
   */
  [BusinessRule.Mutations.SET_LANGUAGE](state, payload) {
    state.businessRule.language = payload.language;
    state.businessRule.agreementModel.LanguageCode = payload.language;
  },

  [BusinessRule.Mutations.SET_PAYMENT_METHOD_AND_TERM](state, payload) {
    Vue.set(state.businessRule.agreementModel, 'possiblePaymentMethods', []);
    state.businessRule.agreementModel.possiblePaymentMethods.push(payload.paymentMethod);
    state.businessRule.agreementModel.paymentTerm = payload.paymentTerm;
  },

  [BusinessRule.Mutations.SET_AGREEMENT](state, payload) {
    Vue.set(state.businessRule, 'agreementModel', payload);
  },
  [BusinessRule.Mutations.SET_VISITED_PACKAGE_TYPES](state, payload) {
    state.visitedPackageTypes = payload;
  },
  [BusinessRule.Mutations.ADD_VISITED_PACKAGE_TYPE](state, payload) {
    if (state.visitedPackageTypes.indexOf(payload) === -1) {
      state.visitedPackageTypes.push(payload);
    }
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
