import Config from '@/constants/config';

const Actions = {
  LOAD_BY_CONTRACT_CODE: 'a.logo.load-by-contract-code'
};

const Mutations = {
  SET: 'm.logo.set',
  CLEAR: 'm.logo.clear',

  SET_LOADING: 'm.logo.set-is-loading'
};

const Endpoints = {
  LoadByContract: () => `${Config.API.BASE_URL}/api/asset`
};

export default {
  Actions,
  Mutations,
  Endpoints
};
