import { api } from '@/utils/api';

import Zorgzoeker from '@/constants/zorgzoeker';

export default {
  Get_Cities() {
    const endpoint = Zorgzoeker.Endpoints.GetCities();
    return api.get(endpoint);
  },

  Get_Hospitals(zipCode, city, distance, basicInsuranceComponentCode, year) {
    const endpoint = Zorgzoeker.Endpoints.GetHospitals();
    const params = {
      zipCode: zipCode,
      city: city,
      distance: distance,
      basicInsuranceComponentCode: basicInsuranceComponentCode,
      year: year
    };
    return api.get(endpoint, { params });
  },

  Get_Cares(searchName) {
    const endpoint = Zorgzoeker.Endpoints.GetCares();
    const params = {
      naam: searchName
    };

    return api.get(endpoint, { params });
  },

  Get_Institutions(qualificationId, specialismId, conditionId, searchName) {
    const endpoint = Zorgzoeker.Endpoints.GetInstitutions();
    const params = {
      qualificationId: qualificationId,
      specialismId: specialismId,
      conditionId: conditionId,
      name: searchName
    };

    return api.get(endpoint, { params });
  },

  Get_CareLocations(year, qualificationId, specialismId, conditionId, institution , zipcode, city, distance, basicInsuranceComponentCode) {
    const endpoint = Zorgzoeker.Endpoints.GetCareLocations();
    const params = {
      year: year,
      qualificationId: qualificationId,
      specialismId: specialismId,
      conditionId: conditionId,
      institution: institution,
      zipcode: zipcode,
      city: city,
      distance: distance,
      basicInsuranceComponentCode: basicInsuranceComponentCode
    };

    return api.get(endpoint, { params });
  }
};
