import LogoUtils from '@/utils/logos';
import Logo from '@/constants/logos';

const state = {
  logo: '',
  logoLoading: false
};

const getters = {
  logo: state => state.logo,
  logoLoading: state => state.logoLoading
};

const actions = {
  [Logo.Actions.LOAD_BY_CONTRACT_CODE]({ commit }, contractCode) {
    LogoUtils.LoadByContract(contractCode)
      .then((response) => commit(Logo.Mutations.SET, response.data));
  }
};

const mutations = {
  [Logo.Mutations.SET](state, payload) {
    state.logo = payload.content;
  },

  [Logo.Mutations.SET_LOADING](state, payload) {
    state.logoLoading = payload;
  },

  [Logo.Mutations.CLEAR](state) {
    state.logo = '';
    state.logoLoading = false;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
