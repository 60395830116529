<template>
  <Button layoutType="secondary" :disabled="disabled" :type="type">
    <Chevron
      id="chevron"
      left 
      :size="10" 
    />
    <slot>
      {{ $tExistsOrDefault('calculatePremium.general.previous') }}
    </slot>
  </Button>
</template>
<script>
import Button from '@/components/shared/button/Button.vue';
import Chevron from '@/assets/icons/Chevron.vue';

export default {
  name: 'ButtonPrevious',
  components: { Button, Chevron },
  props:{
    disabled: {
      type: Boolean,
      default: false
    },
     type: {
      type: String,
      default: 'button',
      validator: function (value) {
        return ['button', 'submit', 'reset'].includes(value);
      }
    }
  }
};
</script>
<style lang='scss' scoped>
Button{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;
    #chevron{
        transition: all 0.2s ease-in-out;
    }
   &:hover:not([disabled]) #chevron{
         transform: translate(-5px, 0) rotate(90deg);
    }
}
</style>
