import { render, staticRenderFns } from "./BasicInsuranceExplanationCard2.vue?vue&type=template&id=70c92316&scoped=true"
import script from "./BasicInsuranceExplanationCard2.vue?vue&type=script&lang=js"
export * from "./BasicInsuranceExplanationCard2.vue?vue&type=script&lang=js"
import style0 from "./BasicInsuranceExplanationCard2.vue?vue&type=style&index=0&id=70c92316&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c92316",
  null
  
)

export default component.exports