<template>
  <svg width="23" height="24" viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd" stroke="#23B623">
        <path d="m5 11.695 3.62 4.15L17 7.012" stroke-linecap="round" stroke-width="2"/>
        <ellipse cx="11.5" cy="11.519" rx="11" ry="11.019"/>
    </g>
  </svg>  
</template>

<script>
export default {
  name: 'CheckInCircle'
};
</script>