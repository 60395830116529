import Vue from 'vue';

import Labels from '@/constants/labels';
import LabelsUtils from '@/utils/labels';

const state = {
  labelsLoading: {
    'LOAD_BY_LANGUAGE': true
  },
  language: ''
};

const getters = {
  labelsLoading: state => state.labelsLoading,
  language: state => state.language
};

const actions = {
  [Labels.Actions.LOAD_BY_LANGUAGE]({ commit }, language) {
    return LabelsUtils.LoadByLanguage(language)
      .then((response) => commit(Labels.Mutations.SET, {
        language: language,
        labels: response.data
      }));
  }
};

const mutations = {
  [Labels.Mutations.SET](state, payload) {
    state.language = payload.language;
    Vue.i18n.add(payload.language, payload.labels);
    Vue.i18n.set(payload.language);
  },

  [Labels.Mutations.SET_LOADING](state, payload) {
    // Boilerplate code, but according to the following page more safe: https://eslint.org/docs/rules/no-prototype-builtins
    if (Object.prototype.hasOwnProperty.call(state.labelsLoading, payload.type)) {
      Vue.set(state.labelsLoading, payload.type, payload.value);
    }
  },

  [Labels.Mutations.CLEAR](state) {
    state.labelsLoading = {
      'LOAD_BY_LANGUAGE': false
    };
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
