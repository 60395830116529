const Actions = {
  SET_INSURED: 'a.validation.set-insured',
  SET_CONFIGURATION: 'a.validation.set-configuration',
  SET_PERSONAL_DATA: 'a.validation.set-personal-data',
  SET_PERSONAL_EXTRA_DATA: 'a.validation.set-personal-extra-data',
  SET_OVERVIEW: 'a.validation.set-overview',
  SET_ALL: 'a.validation.set-all',
  POSTED_CONFIGURATION: 'a.validation.post-configuration'
};

const Mutations = {
  SET_INSURED: 'm.validation.set-insured',
  SET_CONFIGURATION: 'm.validation.set-configuration',
  SET_PERSONAL_DATA: 'm.validation.set-personal-data',
  SET_PERSONAL_EXTRA_DATA: 'm.validation.set-personal-extra-data',
  SET_OVERVIEW: 'm.validation.set-overview',
  SET_ALL: 'm.validation.set-all',
  POSTED_CONFIGURATION: 'm.validation.post-configuration'
};

export default {
  Actions,
  Mutations
};
