export default {
  BusinessRule: {
    create() {
      return {
        'contractCode': '',
        'shouldLockContract': false,
        'agreementModel': {
          'id': 1,
          'requestNumber': 0,
          'contractCode': '',
          'targetGroupCode': '',
          'productCode': '',
          'startDate': '',
          'languageCode': 'NL',
          'hasPartner': false,
          'hasCancellationService': false,
          'hasDigitalPolicy': true,
          'hasDigitalNews': true,
          'mustBuyOffOwnRisk': false,
          'externalId': 0,
          'address': {
            'street': '',
            'zipcode': '',
            'housenumber': '',
            'housenumberAddition': '',
            'country': 'NL',
            'telephoneNumber': '',
            'email': '',
            'city': ''
          },
          'paymentInformation': {
            'primaryBankAccountNumber': '',
            'primaryBankAccountBicNumber': '',
            'secundaryBankAccountNumber': '',
            'secundaryBankAccountBicNumber': ''
          },
          'paymentTerm': 1,
          'paymentMethod': '',
          'paymentMethodForCosts': '',
          'insuredPersons': [],
          'paymentTermNetDiscounts': [],
          'grossPremiumTotal': '',
          'grossPremiumWithOwnRiskDiscountTotal': '',
          'netPremiumWithOwnRiskDiscountTotal': '',
          'netPremiumWithDiscountTotal': '',
          'borderelOrInterMediaryDiscount': 0,
          'possiblePaymentMethod': 'Z',
          'possiblePaymentMethods': [],
          'TermsAndConditionMedicalSelection': false,
          'MaxAmountAp': 1,
          'MaxAmountTa': 1,
          'employeeNumber': ''
        },
        'lastPolicyYear': false,
        'language': 'nl',
        'productComponentCardCollectionPerPerson': []
      };
    }
  },
  Insured: {
    create(typeOfInsured, id = 0) {
      return {
        'id': id,
        'initials': '',
        'firstName': '',
        'lastNamePrefix': '',
        'lastName': '',
        'maidenName': '',
        'maidenNamePrefix': '',
        'ownRiskPaymentMethod': '',
        'typeOfInsured': typeOfInsured, // H, P, M
        'gender': '', // M, V
        'socialSecurityNumber': '',
        'nationality': 'NL',
        'email': '',
        'dateOfBirth': null,
        'chosenOwnRisk': 0,
        'chosenOwnRiskDiscount': '',
        'chosenInsurances': [],
        'totalPrice': '',
        'selectionHasChanged': false,
        'grossPremiumTotal': '',
        'netPremiumWithOwnRiskDiscountTotal': '',
        'netPremiumWithDiscountTotal': '',
        'canChooseOwnRisk': false,
        'invalidDate': false,
        'medicalSelection': {
        }
      };
    }
  }
};
