<template>
  <Container class="insured-container" :backgroundAlt="true">
    <div>
      <div class="flex-wrapper">
        <div>
          <ValidationObserver
            ref="insuredObserver"
            tag="form"
            @submit.prevent="storeValidationState"
            class="d-flex flex-column"
            v-if="selectedContract"
          >
            <div>
              <ContractStartDate />
              <CalculatePremiumForm />
            </div>
          </ValidationObserver>
        </div>
        <div v-if="!this.$screen.xs" id="contract-advantages-wrapper">
          <ContractAdvantages
            :contractCode="selectedContractCode"
            :contract="selectedContract"
            logoPosition="top"
          />
        </div>
      </div>
      <div class="button-group-previous-next">
        <ButtonPrevious type="button" @click.native="previous" />
        <ButtonNext
          type="submit"
          @click.native="handleCalculatePremium"
          :disabled="disableCalculatePremium"
        />
      </div>
    </div>
  </Container>
</template>

<script>
import { mapGetters } from "vuex";

import BusinessRule from "@/constants/business-rule";
import Contracts from "@/constants/contracts";
import Logo from "@/constants/logos";
import Validation from "@/constants/validation";
import Modal from "@/constants/modal";
import NProgress from "nprogress";

import { ValidationObserver } from "vee-validate";

import CalculatePremiumForm from "@/components/insured/CalculatePremiumForm.vue";
import ButtonPrevious from "@/components/shared/button/ButtonPrevious.vue";
import ButtonNext from "@/components/shared/button/ButtonNext.vue";

import ModalDataFactory from "@/components/modals/ModalDataFactory";
import Container from "@/components/shared/2.0-import/Container.vue";
import ContractStartDate from "@/components/contracts/ContractStartDate.vue";
import ContractAdvantages from "@/components/home/ContractAdvantages.vue";

export default {
  name: "Insured",
  props: {
    contractCode: Number,
  },
  components: {
    CalculatePremiumForm,
    ValidationObserver,
    ButtonPrevious,
    ButtonNext,
    Container,
    ContractStartDate,
    ContractAdvantages,
  },
  data() {
    return {
      disableCalculatePremium: false,
    };
  },
  computed: {
    ...mapGetters([
      "selectedContract",
      "selectedContractCode",
      "language",
      "startDate",
    ]),
  },
  beforeMount() {
    this.$store.commit(
      BusinessRule.Mutations.SET_CONTRACT_CODE_IN_BR,
      this.contractCode
    );
    this.$store.commit(Contracts.Mutations.SET_SELECTED_ID, this.contractCode);
    this.$store.dispatch(Contracts.Actions.LOAD_EJW_INFO);
    this.$store.dispatch(Logo.Actions.LOAD_BY_CONTRACT_CODE, this.contractCode);
    this.$store.dispatch(
      Contracts.Actions.LOAD_CONTRACT,
      this.$store.getters.businessRule.lastPolicyYear
    );
  },
  methods: {
    async handleCalculatePremium() {
      if (await this.$refs.insuredObserver.validate()) {
        let businessRuleModel = this.$store.getters.businessRule;
        const currentYear = new Date().getFullYear();
        const startYear = this.startDate.getFullYear();
        if (!this.$store.getters.isEjw && startYear !== currentYear) {
          this.$store.dispatch(Modal.Actions.OPEN, {
            modalData: ModalDataFactory.create(
              this.$tExistsOrDefault("insured.nextYearPremiumsUnknown.title"),
              this.$tExistsOrDefault("insured.nextYearPremiumsUnknown.message")
            ),
          });
        } else {
          this.disableCalculatePremium = true;
          this.startLoadingIndicatorBar();
          this.$store.commit(BusinessRule.Mutations.SET_LASTPOLICYYEAR, {
            lastPolicyYear:
              this.$store.getters.lastPolicyYearAvailable === true &&
              startYear === currentYear,
          });
          NProgress.inc(0.1);

          this.$store
            .dispatch(BusinessRule.Actions.POST)
            .then(() => {
              this.$store.dispatch(
                Contracts.Actions.LOAD_CONTRACT,
                businessRuleModel.lastPolicyYear
              );
              NProgress.inc(0.25);
            })
            .then(() => NProgress.set(1.0))
            .finally(() => {
              setTimeout(() => {
                NProgress.done();
                this.disableCalculatePremium = false;
                this.navigateToConfiguration();
              }, 250);
            });
        }
      } else {
        // dirty way of going to the first error.
        var el = this.$el.getElementsByClassName("error")[0].parentNode;
        el.scrollIntoView();
      }
    },
    startLoadingIndicatorBar() {
      NProgress.configure({
        parent: "#loading-indicator",
        showSpinner: false,
        trickleSpeed: 75,
        easing: "ease",
        speed: 275,
      });
      this.$store.commit(Modal.Mutations.SHOW_OVERLAY, true);
      NProgress.start();
    },
    navigateToConfiguration() {
      this.$store.commit(Modal.Mutations.SHOW_OVERLAY, false);
      this.$router.push({
        name: "configuration",
        params: {
          lang: this.$store.getters.language,
          activationStep: 0,
        },
      });
    },
    async storeValidationState() {
      this.$store.dispatch(
        Validation.Actions.SET_INSURED,
        await this.$refs.insuredObserver.validate()
      );
    },
    previous() {
      this.$router.push({
        name: "contract-overview",
        params: {
          contractCode: this.selectedContractCode,
          lang: this.language,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-min-height {
  min-height: 82vh;
  margin-bottom: 45px;
}

.flex-wrapper {
  display: flex;
  flex-direction: row;
  & > div:first-child {
    flex: 0 1 450px;
  }
  & > div:last-child {
    flex: 1 1 auto;
  }
}

#contract-advantages-wrapper {
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 0 24px;
  > div {
    max-width: 410px;
  }
}
</style>

<style lang="scss">
.insured-container > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
