<template>
	<svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#9f9d9d">
		<g id="SVGRepo_bgCarrier" stroke-width="0"/>
		<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
		<g id="SVGRepo_iconCarrier">
			<circle cx="12" cy="12" r="10" stroke="#9f9d9d" stroke-width="1.5"/>
			<path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#9f9d9d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</g>
	</svg>
</template>
  
<script>
export default {
  name: 'NotYetCompensated'
};
</script>