<template>
  <div :class="{'filler' : !hideHeader, 'invisible': hideHeader }">
    <header class="position-fixed w-100">
      <Container class="header-container" :padded="false" :background-alt="true">
        <div class="d-flex h-100 justify-content-between align-items-center pl-0">
          <div 
            class="col d-flex pl-0"
            :class="{'justify-content-center': !$screen.gt.xs}"
          >
            <a
              v-if="$route.meta.logo"
              :href="this.homepage"
              target="_blank"
            >
              <WhiteLabelLogo class="white-label-logo" />
            </a>
            <Steps 
              v-else-if="$route.params.lang"
              class="z-index-10 w-100" 
            />
          </div>
          <div >
            <LanguagePicker />
          </div>
        </div>
      </Container>
    </header>
  </div>
</template>
<script>
import Container from '@/components/shared/2.0-import/Container.vue';
import LanguagePicker from '@/components/shared/LanguagePicker.vue';
import { mapGetters } from 'vuex';
import Steps from '@/components/steps/Steps.vue';
import WhiteLabelLogo from '@/components/shared/WhiteLabelLogo.vue';

export default {
  name: 'Header',
  components: {
    Container,
    LanguagePicker,
    Steps,
    WhiteLabelLogo
},
  props: {
    hideHeader: Boolean
  },
  computed: {
    
    ...mapGetters(['labelSettings']),
    homepage(){
      
      return this.labelSettings.homepage;
    }
  }  
};
</script>
<style lang="scss">
.header-container > * {
  padding: 16px 24px 0px 24px;
}
</style>

<style lang="scss" scoped>
header, .filler {
  height: 70px;
}
header {
  background: white;
  z-index: 4;
}

.white-label-logo {
  height: 38px;
}
</style>
