<template>
  <PartialOverlayWithCloseButton v-model="modelValue">
    <div>
      <div class="h1-container">
        <H1>{{
          $tExistsOrDefault(
            "calculatePremium.basicInsurance.informationOverlay.title"
          )
        }}</H1>
      </div>
      <section>
        <div class="h2-container">
          <H2>{{
            $tExistsOrDefault(
              "calculatePremium.basicInsurance.informationOverlay.sectionIncluded.title"
            )
          }}</H2>
        </div>
        <div class="bulletpoint-container">
          <div
            v-for="item in $tExistsOrDefault(
              'calculatePremium.basicInsurance.informationOverlay.sectionIncluded.items'
            )"
            class="bulletpoint-wrapper"
            :key="item"
          >
            <Check />
            <PlainText>{{ item }}</PlainText>
          </div>
        </div>
        <div>
          <PlainText>{{
            $tExistsOrDefault(
              "calculatePremium.basicInsurance.informationOverlay.sectionIncluded.footer.cta-pt1"
            )
          }}</PlainText>
          <PlainText>{{
            $tExistsOrDefault(
              "calculatePremium.basicInsurance.informationOverlay.sectionIncluded.footer.cta-pt2"
            )
          }}</PlainText>
        </div>
      </section>
      <section>
        <div class="h2-container">
          <H2>{{
            $tExistsOrDefault(
              "calculatePremium.basicInsurance.informationOverlay.sectionExcluded.title"
            )
          }}</H2>
        </div>
        <div class="bulletpoint-container">
          <div
            v-for="item in $tExistsOrDefault(
              'calculatePremium.basicInsurance.informationOverlay.sectionExcluded.items'
            )"
            class="bulletpoint-wrapper"
            :key="item"
          >
            <Unchecked />
            <PlainText>{{ item }}</PlainText>
          </div>
        </div>
        <PlainText>{{
          $tExistsOrDefault(
            "calculatePremium.basicInsurance.informationOverlay.sectionExcluded.footer"
          )
        }}</PlainText>
      </section>
    </div>
  </PartialOverlayWithCloseButton>
</template>

<script>
import PartialOverlayWithCloseButton from "@/components/shared/2.0-import/PartialOverlayWithCloseButton.vue";
import H1 from "@/components/shared/2.0-import/text/H1.vue";
import H2 from "@/components/shared/2.0-import/text/H2.vue";
import PlainText from "@/components/shared/2.0-import/text/PlainText.vue";
import Check from "@/assets/icons/Check.vue";
import Unchecked from "@/assets/icons/Unchecked.vue";

export default {
  name: "BasicInsuranceOverlay",
  components: {
    PartialOverlayWithCloseButton,
    H1,
    H2,
    PlainText,
    Check,
    Unchecked,
  },
  props: {
    value: Boolean,
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    onClose() {
      this.value = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.h1-container {
  margin-bottom: 32px;
}
.h2-container {
  margin-bottom: 12px;
}

.bulletpoint-wrapper {
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  :first-child {
    flex-shrink: 0;
    height: 22px;
  }
}

.bulletpoint-container {
  margin-bottom: 10px;
}
section {
  margin-bottom: 24px;
  margin-right: 15px;
}
</style>
