<template>
  <div>
    <div class="d-flex justify-content-center text-center">
      <LinkText  
        :class="{'invisible': !(conditionsDocument || coverDocument || product.insuranceCardLink)}" 
        @click.native="showOverlay=true"
      >
        {{ $tExistsOrDefault('calculatePremium.downloads.showMore') }} 
        <Chevron id="chevron" right :size="10" />
      </LinkText>
    </div>
    <IFramesOverlay
      v-model="showOverlay"
      :product="product"
    />
  </div>
</template>

<script>
import Chevron from '@/assets/icons/Chevron.vue';
import LinkText from '@/components/shared/2.0-import/text/LinkText.vue';
import DocumentUtils from '@/utils/document';
import IFramesOverlay from '@/components/shared/2.0-import/IFramesOverlay.vue';

export default {
  name: 'DocumentDownloads',
  props: {
    product: Object,
    size: { type: String, default: '2x' }
  },
  components: { 
    Chevron,
    IFramesOverlay, 
    LinkText 
  },
  data: function () {
    return {
      showOverlay: false,
      showCompareOverlay: false,
    };
  },
  computed: {
    coverDocument() {
      if (!this.product.documentInsurancePolicy) {
        return null;
      }
      return DocumentUtils.GetDownloadUrl(this.product.documentInsurancePolicy);
    },
    productComponentCode() {
      return this.product.productComponentCode.toString();
    },
    conditionsDocument() {
      if (!this.product.documentConditionsPolicy) {
        return null;
      }
      return DocumentUtils.GetDownloadUrl(this.product.documentConditionsPolicy);
    }
  },
  methods: {

  }

};
</script>

<style lang="scss" scoped>
#chevron {
  margin-left: 5px;
}
</style>