<template>
  <Step v-if="$screen.gt.sm" class="d-flex">
    <StepDesktop 
      v-for="(step,i) in stepInformation" 
      :key="i"
      :isVisitedOrActive="isVisitedOrActive(step.number)"
      :stepNumber="step.number"
      :stepName="$tExistsOrDefault(step.name)"
      :maxSteps="step.maxSteps"
      :currentStep="step.currentStep"
    />
  </Step>
  <Step v-else class="d-flex align-content-end">
    <StepMobile
      v-for="(step,i) in stepInformation" 
      :key="i"
      :isVisitedOrActive="isVisitedOrActive(step.number)"
      :stepNumber="step.number"
      :stepName="$tExistsOrDefault(step.name)"
      :maxSteps="step.maxSteps"    
      :currentStep="step.currentStep"         
    />
  </Step>
</template>

<script>
import Step from '@/components/steps/Step.vue';
import StepDesktop from '@/components/steps/StepDesktop.vue';
import StepMobile from '@/components/steps/StepMobile.vue';

export default {
  name: 'Steps',
  components: {
    Step,
    StepDesktop,
    StepMobile,
  },
  data() {
    return {
      stepInformation: [
        {number: 1, name: 'breadcrumb.selectPersons', maxSteps: 1, currentStep: 0},
        {number: 2, name: 'breadcrumb.choosePackages', maxSteps: 5, currentStep: 0},
        {number: 3, name: 'breadcrumb.personalDetails', maxSteps: 3, currentStep: 0},
        {number: 4, name: 'breadcrumb.checkAndSubmit', maxSteps: 1, currentStep: 0},
      ]
    };
  },
  methods: {
    isVisitedOrActive(stepNumber) {
      if(stepNumber === 2 && this.$route.meta.step === 2) {
        this.stepInformation[stepNumber-1].currentStep = parseInt(this.$route.params.activationStep) + 1;
      } else {
        this.stepInformation[stepNumber-1].currentStep = this.$route.meta.stepProgress;
      }
      return {isVisited: this.$route.meta.step > stepNumber, isActive: this.$route.meta.step === stepNumber };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>