<template>
  <div>
    <div class="subtitle mt-4">{{ insuredTitleLabel }}</div>
    <!-- Question 1 -->
    <div class="question">{{ $tExistsOrDefault('medicalSelection.question1') }}</div>
    <ValidationProvider
      tag="div"
      rules="requiredChoice"
      v-slot="{ errors }"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']" :key="`question1-${option}`" 
          name="question-1"
          :radioValue="option == 'yes'"
          v-model="answers.answer1"
          @change="answersChanged"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
        <span 
          v-if="errors[0]" 
          :class="{'error': errors[0]}"
        >
          <CircleXMark iconInText="true" />
          {{ errors[0] }}
        </span>
      </div>
    </ValidationProvider>

    <!-- Question 2 -->
    <div class="question">{{ $tExistsOrDefault('medicalSelection.question2') }}</div>
    <ValidationProvider
      tag="div"
      rules="requiredChoice"
      v-slot="{ errors }"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']" :key="`question2-${option}`" 
          name="question-2"
          :radioValue="option == 'yes'"
          v-model="answers.answer2"
          @change="answersChanged"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
        <span 
          v-if="errors[0]" 
          :class="{'error': errors[0]}"
        >
          <CircleXMark iconInText="true" />
          {{ errors[0] }}
        </span>
      </div>
    </ValidationProvider>

    <!-- Question 3 -->
    <div class="question">{{ $tExistsOrDefault('medicalSelection.question3') }}</div>
    <ValidationProvider
      tag="div"
      rules="requiredChoice"
      v-slot="{ errors }"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']" :key="`question3-${option}`" 
          name="question-3"
          :radioValue="option == 'yes'"
          v-model="answers.answer3"
          @change="answer3Changed"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
        <span 
          v-if="errors[0]" 
          :class="{'error': errors[0]}"
        >
          <CircleXMark iconInText="true" />
          {{ errors[0] }}
        </span>
      </div>
    </ValidationProvider>
    <div v-if="answers.answer3" class="question ml-4 mb-1">{{ $tExistsOrDefault('medicalSelection.infoQuestion3') }}</div>
    <ValidationProvider
      v-if="answers.answer3"
      tag="div"
      rules="requiredExplanation"
      v-slot="{ errors }"
    >
      <div v-if="answers.answer3" class="ml-4">
        <textarea
          class="form-control"
          :class="{'error-border': errors[0]}"
          v-model="answers.answer3Comment"
          maxlength="200"
          rows="3"
          :disabled="!answers.answer3"
          @blur="answersChanged"
        />
        <span 
          v-if="errors[0]" 
          :class="{'error': errors[0]}"
        >
          <CircleXMark iconInText="true" />
          {{ errors[0] }}
        </span>
      </div>
    </ValidationProvider>
      
    <!-- Question 4 -->
    <div class="question">{{ $tExistsOrDefault('medicalSelection.question4') }}</div>
    <ValidationProvider
      tag="div"
      rules="requiredChoice"
      v-slot="{ errors }"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']" :key="`question4-${option}`" 
          name="question-4"
          :radioValue="option == 'yes'"
          v-model="answers.answer4"
          @change="answer4Changed"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
        <span 
          v-if="errors[0]" 
          :class="{'error': errors[0]}"
        >
          <CircleXMark iconInText="true" />
          {{ errors[0] }}
        </span>
      </div>
    </ValidationProvider>
    <div v-if="answers.answer4" class="question ml-4 mb-1">{{ $tExistsOrDefault('medicalSelection.infoQuestion4') }}</div>
    <ValidationProvider
      v-if="answers.answer4"
      tag="div"
      rules="requiredExplanation"
      v-slot="{ errors }"
    >
      <div v-if="answers.answer4" class="ml-4">
        <textarea
          class="form-control"
          :class="{'error-border': errors[0]}"
          v-model="answers.answer4Comment"
          maxlength="200"
          rows="3"
          :disabled="!answers.answer4"
          @blur="answersChanged"
        />
        <span 
          v-if="errors[0]"
          :class="{'error': errors[0]}"
        >
          <CircleXMark iconInText="true" />
          {{ errors[0] }}
        </span>
      </div>
    </ValidationProvider>

    <!-- Question 5 -->
    <div class="question">{{ $tExistsOrDefault('medicalSelection.question5') }}</div>
    <ValidationProvider
      tag="div"
      rules="requiredChoice"
      v-slot="{ errors }"
    >
      <div class="radio-button-group">
        <RadioButton
          v-for="option in ['yes', 'no']" :key="`question1-${option}`" 
          name="question-5"
          :radioValue="option == 'yes'"
          v-model="showAnswer5Options"
          @change="showAnswer5Options = (option == 'yes')"
        >
          {{ $tExistsOrDefault(`medicalSelection.${option}`) }}
        </RadioButton>
      </div>
      <span 
        v-if="errors[0]" 
        :class="{'error': errors[0]}"
      >
        <CircleXMark iconInText="true" />
        {{ errors[0] }}
      </span>
    </ValidationProvider>
    <!-- Question 5 checkboxes -->
    <div v-if="showAnswer5Options" class="ml-4 mb-1">
      <div class="mt-0 mb-2">{{ $tExistsOrDefault('medicalSelection.subquestion5') }}</div>
      <ValidationProvider
        :rules="{ requiredTreatments :{ atLeastOneTreatment: atLeastOneAnswerSelectedQuestion5 }}"
        tag="div"
        v-slot="{ errors }"
      >
        <Checkbox
          v-for="question in question5subquestions" 
          :key="`checkbox-${question.label}`" 
          :value="answers.answer5.includes(question.value)"
          @input="answer5Changed(question.value)"
        >
          <PlainText class="checkBoxText">{{ $tExistsOrDefault(question.label) }}</PlainText>
        </Checkbox>
        <span 
          v-if="errors[0]" 
          :class="{'error': errors[0]}"
        >
          <CircleXMark iconInText="true" />
          {{ errors[0] }}
        </span>
      </ValidationProvider>
    </div>
    <div v-else>
      <ul class="ml-4">
        <li class="bullet" v-for="question in question5subquestions" :key="`li-${question.label}`"><label class="my-0">{{ $tExistsOrDefault(question.label) }}</label></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import BusinessRule from '@/constants/business-rule';
import { mapGetters } from 'vuex';
import prettySelectOnEnter from '@/mixins/prettySelectOnEnter';
import Dates from '@/helpers/dates';
import CircleXMark from '@/assets/icons/CircleXMark.vue';
import RadioButton from '@/components/shared/RadioButton.vue';
import Checkbox from '@/components/shared/Checkbox.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';

export default {
  name: 'MedicalSelectionForm',
  components: { 
    ValidationProvider,
    CircleXMark,
    RadioButton,
    Checkbox,
    PlainText
  },
  props: {
    personId: Number
  },
  mixins: [prettySelectOnEnter],
  data() {
    return {
      answers: {
        answer1: null,
        answer2: null,
        answer3: null,
        answer3Comment: '',
        answer4: null,
        answer4Comment: '',
        answer5: []
      },
      question5subquestions: [
          {label: 'medicalSelection.answer1', value: '4 of meer vullingen'},
          {label: 'medicalSelection.answer2', value: 'een of meer kronen'},	
          {label: 'medicalSelection.answer3', value: 'een of meer bruggen'},
          {label: 'medicalSelection.answer4', value: 'een of meer implantaten'},
          {label: 'medicalSelection.answer5', value: 'een gedeeltelijke gebitsprothese'},
          {label: 'medicalSelection.answer6', value: 'een uitgebreide tandvleesbehandeling'},
          {label: 'medicalSelection.answer7', value: '2 of meer wortelkanaalbehandelingen'}
        ]
    };
  },
  computed: {
    ...mapGetters(['enableAnswer5Options', 'getDateOfBirth']),
    showAnswer5Options: {
      get() { return this.enableAnswer5Options[this.personId] },
      set(v) {
        this.$store.commit(BusinessRule.Mutations.SET_MEDICAL_SELECTION_ANSWER5_ENABLEMENT, { key: this.personId, value: v }); 
        if (v) { 
          this.answers.answer5 = this.answers.answer5.filter(val => val !== 'nee');
        } 
        else {
          this.answers.answer5 = ['nee'];
        }
        this.answersChanged();
      }
    },
    insured() {
      return this.$store.getters.insured.find(i => i.id === this.personId);
    },
    packageNameWithMedicalSelection() {
      return this.insured.chosenInsurances.find(ci => ci.hasMedicalSelection).title;
    },
    insuredTitleLabel() {
      return `${this.getInsuredLabel()} (${this.formatDate()}), ${this.packageNameWithMedicalSelection}`;
    },
    atLeastOneAnswerSelectedQuestion5() {
      return this.answers.answer5.length > 0;
    }
  },
  methods: {
    getInsuredLabel() {
      if (this.insured != null) {
        switch (this.insured.typeOfInsured) {
          case 'H':
            return this.$tExistsOrDefault('personal.yourselfTitle');
          case 'P':
            return this.$tExistsOrDefault('personal.partnerTitle');
          case 'M':
            return this.$tExistsOrDefault('personal.childTitle');
        }
      }
    },
    formatDate() { 
      return Dates.formatDate(this.getDateOfBirth(this.insured.id), 'dd MMMM yyyy');
    },
    answersChanged() {
      this.$store.commit(BusinessRule.Mutations.SET_MEDICAL_SELECTION_ANSWERS, { personId: this.personId, answers: this.answers });
    },
    answer3Changed() {
      if (this.answers.answer3 === false) {
        this.answers.answer3Comment = '';
      }
      this.answersChanged();
    },
    answer4Changed() {
      if (this.answers.answer4 === false) {
        this.answers.answer4Comment = '';
      }
      this.answersChanged();
    },
    answer5Changed(value) {
      if(this.answers.answer5.includes(value)) {
        this.answers.answer5 = this.answers.answer5.filter(val => val !== value);
      }
      else {
        this.answers.answer5.push(value);
      }
      this.answersChanged();
    }
  },
  mounted() {
    // Set initial form data
    this.$set(this.answers, 'answer1', this.insured.medicalSelection.question1);
    this.$set(this.answers, 'answer2', this.insured.medicalSelection.question2);
    this.$set(this.answers, 'answer3', this.insured.medicalSelection.question3);
    this.$set(this.answers, 'answer3Comment', this.insured.medicalSelection.additionalInfoQuestion3);
    this.$set(this.answers, 'answer4', this.insured.medicalSelection.question4);
    this.$set(this.answers, 'answer4Comment', this.insured.medicalSelection.additionalInfoQuestion4);
    this.$set(this.answers, 'answer5', this.insured.medicalSelection.question5 || []);
  }
};
</script>

<style lang="scss" scoped>

.radio-button-group{
  margin-top: 6px;
}
.question {
  margin-top: 24px;
  line-height: 22px;
}
textarea {
  resize: none;
  width: 100%;
}
ul li {
  text-indent: -1em; 
  padding-left: 1em;
}
ul li label {
  display: inline;
  margin-left: -0.9em;
}

.checkBoxText {
    margin-left: 11px;
    margin-bottom: 4px;
}

.checkBoxText {
    margin-left: 11px;
    margin-bottom: 4px;
}
ul li.bullet::before {
  content: '\2022';
  color: var(--color-primary-900);
  color: var(--color-primary-900);
  font-weight: bold;
  font-size: 25px;
  display: inline-block;
  width: 22px !important;
  margin-left: 0.5rem;
}
ul li::before {
  content: '';
  display: inline-block;
  width: 22px !important;
  margin-left: 0.5rem;
}


</style>