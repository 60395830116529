<template>
  <div class="item__content row1 row">
    <div class="col-md-4 offset-md-8"><span>{{ formatCurrency(this.person.grossPremiumTotal - this.person.netPremiumWithDiscountTotal) }}</span></div>
  </div>
</template>

<script>
import formatCurrencyHelper from '@/helpers/formatCurrency';

export default {
  name: 'PremiumCalculation',
  props: {
    person: Object
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(
        value,
        this.$i18n.locale() ? this.$i18n.locale() : 'NL'
      );
    }
  },
  computed: {
    agreement () {
      return this.$store.getters.agreement;
    },
    insuredPersons () {
      return this.$store.getters.agreement.insuredPersons;
    }
  }
};
</script>
