import TooltipDisabledPackages from '@/constants/tooltipDisabledPackages';
import store from '@/store';

const state = {
  isTooltipVisible: false,
  isTooltipStateLocked: false,
  packageData: undefined,
};

const getters = {
  isTooltipVisible: state => state.isTooltipVisible,
  packageData: state => state.packageData,
};

const actions = {
  [TooltipDisabledPackages.Actions.SET_DISABLED_PACKAGE]({ getters, commit }, product) {
    const packageData = getters.packageData;
    if(packageData?.productCompontCode !== product.productComponentCode) {
      commit(TooltipDisabledPackages.Mutations.SET_PACKAGE_DATA, product);
      commit(TooltipDisabledPackages.Mutations.UPDATE_TOOLTIP_VISIBILITY, true);
    }
  },
  [TooltipDisabledPackages.Actions.RESET_PACKAGE_DATA]({ commit }) {
    commit(TooltipDisabledPackages.Mutations.SET_PACKAGE_DATA, undefined);
    commit(TooltipDisabledPackages.Mutations.UPDATE_TOOLTIP_VISIBILITY, false);
  },
  [TooltipDisabledPackages.Actions.UPDATE_TOOLTIP_VISIBILITY]({ commit }, isTooltipVisible) {
    commit(TooltipDisabledPackages.Mutations.UPDATE_TOOLTIP_VISIBILITY, isTooltipVisible);
  },
  [TooltipDisabledPackages.Actions.LOCK_TOOLTIP]({ commit }) {
    commit(TooltipDisabledPackages.Mutations.LOCK_TOOLTIP, true);
    setTimeout(() => { 
      commit(TooltipDisabledPackages.Mutations.LOCK_TOOLTIP, false)
    }, 100 );
  }
};

const mutations = {
  [TooltipDisabledPackages.Mutations.SET_PACKAGE_DATA](state, payload) {
    if (state.isTooltipStateLocked) return;
    if (!payload) state.packageData = payload;
    else {
      state.packageData = {
        contractCode: store.getters.selectedContractCode,
        packageType: payload.packageTypeForWebApplications,
        productComponentCode: payload.productComponentCode,
        cardIsDisabled: payload.isDisabled,
        productTitle: payload.title
      };
    }
  },
  [TooltipDisabledPackages.Mutations.UPDATE_TOOLTIP_VISIBILITY](state, payload) {
    if (state.isTooltipStateLocked) return;
    state.isTooltipVisible = payload;
  },
  [TooltipDisabledPackages.Mutations.LOCK_TOOLTIP](state, payload) {
    state.isTooltipStateLocked = payload;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};