<template>
  <transition name="modal">
    <div class="modal-mask d-flex" @click="handleClose">
        <div class="modal-container">
          <div v-if="modalData.component">
            <component @onClose="closeModal" :is="modalData.component" :class="[shouldBounceClass]" />
          </div>
          <DataModal v-else :class="[shouldBounceClass]" :modalData="modalData" />
        </div>
    </div>
  </transition>
</template>

<script>
import Modal from '@/constants/modal';

import FullscreenOverlay from '@/components/shared/2.0-import/FullscreenOverlay.vue';

import { mapGetters } from 'vuex';
import DataModal from '@/components/modals/DataModal.vue';

export default {
  name: 'Modal',
  props: {
    modalData: null
  },
  data() {
    return {
      shouldBounce: false
    };
  },
  components: {
    DataModal,
    FullscreenOverlay
  },
  computed: {
    ...mapGetters(['isPersistent']),
    shouldBounceClass() {
      return this.shouldBounce ? 'modal-bounce' : '';
    }
  },
  methods: {
    handleClose(e) {
      if (e.target.className.includes('modal-mask')) {
        if (!this.isPersistent) {
          this.closeModal();
          if (this.modalData.closeAction) {
            this.modalData.closeAction();
          }
        }
        else {
          this.shouldBounce = true;
          setTimeout(() => {
            this.shouldBounce = false;
          }, 200);
        }
      }
    },
    closeModal() {
      this.$store.dispatch(Modal.Actions.CLOSE);
    }
  },
  mounted() {
    document.documentElement.classList.add('disabled-scroll');
  },
  beforeDestroy() {
    document.documentElement.classList.remove('disabled-scroll');
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  overflow: auto;
  z-index: 2147483004; // higher z-index than liveChat
  top: 0;
  left: 0;
  min-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  padding: 1rem;
  background-color: rgba(24, 37, 50, 0.5);
  transition: opacity .3s ease;
}

.modal-container {
  width: 100%;
  max-width: 650px;
  border-radius: var(--border-radius);
  margin: auto;  
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 20, .33);
  transition: all .3s ease;
  overflow: hidden;
  padding: 1.8rem;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.modal-bounce {
  animation-name: bounce;
  animation-duration: .2s;
}

@keyframes bounce {
  0% {transform: scale(1);}
  65% {transform: scale(1.05);}
  100% {transform: scale(1);}
}
</style>
