<template>
  <div class="btn-container" :class="{'reverse': reverse}">
    <Close class="close-icon" />
    <p class="d-none d-md-block">{{ $tExistsOrDefault('shared.button.close') }}</p>
  </div>
</template>
<script>
import Close from '@/assets/icons/Close.vue';
export default {
  components: { Close },
  name: 'CloseButton',
  props:{
    reverse: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style lang='scss' scoped>
.btn-container{
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-primary-800);
  user-select: none;
  transition: all 0.1s ease-in-out;
  height: 30px;
  gap: 5px;
  p {
      color: var(--color-primary-800);
      font-size: 16px;
      margin-bottom: 0;
  }
  .close-icon {
      height: 100%;
      width: 20px;
  }
  &:hover {
    cursor: pointer;
    p{
      color: var(--color-primary-600);  
    }
    .close-icon{
      color: var(--color-primary-600);  
    }
  }
}
.reverse{
  flex-direction: row-reverse;
}
</style>
