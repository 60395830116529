<template>
  <div class="form-wrapper mt-3">
    <H3>{{ $tExistsOrDefault('insured.calculatePremium') }}</H3>

    <CalculatePremiumInsuredMain />
    <CalculatePremiumInsuredPartner />
    <CalculatePremiumInsuredChildren />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CalculatePremiumInsuredMain from '@/components/insured/CalculatePremiumInsuredMain.vue';
import CalculatePremiumInsuredPartner from '@/components/insured/CalculatePremiumInsuredPartner.vue';
import CalculatePremiumInsuredChildren from '@/components/insured/CalculatePremiumInsuredChildren.vue';
import H3 from '@/components/shared/2.0-import/text/H3.vue';

export default {
  name: 'CalculatePremiumForm',
  components: {
    CalculatePremiumInsuredMain,
    CalculatePremiumInsuredPartner,
    CalculatePremiumInsuredChildren,
    H3
  },
  computed: {
    ...mapGetters(['businessRule', 'agreement'])
  },
};
</script>

<style lang="scss" scoped>
h3{
  margin-bottom: 24px;
}

.child-free-insured {
  margin-top: 16px;
  font-size: 18px;
}
.custom-margin-bottom {
  margin-bottom: 32px;
}

::v-deep .vs__dropdown-toggle {
  height: 60px !important;
  background-color: white !important;
}

</style>
