var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CloseButtonStickyHeader',{attrs:{"padded":false},on:{"close":_vm.onClose}},[_c('H1',{attrs:{"id":"title"}},[_vm._v(_vm._s(_vm.title))]),(_vm.basicReimbursementProduct)?_c('div',{directives:[{name:"match-heights",rawName:"v-match-heights",value:({ el: 
      ['.header-section', '.title-section', '.card-title', '.card-price', 
       '.row0', '.row1', '.row2', '.row3', '.row4', '.row5', '.row6', 
       '.row7', '.row8', '.row9', '.row10', '.row11', '.row12', '.row13'
      ]
    }),expression:"{ el: \n      ['.header-section', '.title-section', '.card-title', '.card-price', \n       '.row0', '.row1', '.row2', '.row3', '.row4', '.row5', '.row6', \n       '.row7', '.row8', '.row9', '.row10', '.row11', '.row12', '.row13'\n      ]\n    }"}],staticClass:"d-flex",class:{'ml-n3': !_vm.isPaddedOverlay && !_vm.$screen.xs}},[(_vm.$screen.gt.xs)?_c('CompareCard',{attrs:{"isHeaderColumn":"","typeOfInsurance":_vm.typeOfInsurance,"product":{
        reimbursementTypes: 
          _vm.filteredReimbursementTypes.map((x, i) => { return { key: i+1, value: x.categoryName } })
      }}}):_vm._e(),_c('HorizontalScroll',{staticClass:"w-100 mx-0 horizontal-scroll",attrs:{"useGap":false}},[(!_vm.compareProductExclusions.includes(_vm.basicReimbursementProduct.productComponentCode))?_c('CompareCard',{attrs:{"product":_vm.basicReimbursementProduct,"amountOfCardsCompared":_vm.amountOfCardsCompared,"typeOfInsurance":_vm.typeOfInsurance},on:{"excludeFromComparison":(id) => {_vm.compareProductExclusions.push(id); _vm.$forceUpdate()}}}):_vm._e(),_vm._l((_vm.filteredProducts),function(product,i){return _c('CompareCard',{key:`${i}`,attrs:{"product":product,"typeOfInsurance":_vm.typeOfInsurance,"amountOfCardsCompared":_vm.amountOfCardsCompared},on:{"excludeFromComparison":(id) => {_vm.compareProductExclusions.push(id); _vm.$forceUpdate()}}})})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }