<template>
  <th>
    <tr class="d-flex justify-content-end">
      <span 
        class="exclude-from-comparison"
        :class="{'invisible': amountOfCardsCompared <= 2}" 
        @click="$emit('excludeFromComparison', product.productComponentCode)"
      >
        <Delete />
      </span>
    </tr>										
    <tr>
      <CardHeader>{{ productTitle() }}</CardHeader>
    </tr>
    <tr v-if="!isBasicInsurance">
      <ProductPremium :product="product" />
    </tr>
  </th>
</template>

<script>
import formatCurrencyHelper from '@/helpers/formatCurrency';
import productHelper from '@/helpers/product';
import Delete from '@/assets/icons/Delete.vue';
import { mapGetters } from 'vuex';
import CardHeader from '@/components/shared/2.0-import/text/CardHeader.vue';
import ProductPremium from '@/components/configuration/insuranceCard/ProductPremium';

export default {
  name: 'HeaderInsurance',
  components: { 
    Delete,
    CardHeader,
    ProductPremium 
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    typeOfInsurance: {
      type: String,
      required: true,
    },
    amountOfCardsCompared: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapGetters(['startDate']),
    isBasicInsurance() {
      return this.typeOfInsurance == 'MB';
    },
    filterProduct(){
      return {
        ...this.product,
        persons: productHelper.filteredPersonsForProduct(this.product, this.startDate),
      };
    },
    minPremium() {
      return productHelper.minPremium(this.filterProduct);
    },
    isFree() {
      return productHelper.isFree(this.product);
    }
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(value, this.$i18n.locale() ? this.$i18n.locale() : 'NL');
    },
    productTitle() {
      const euroSymbol = '€';
      const textPrecedingEuroSymbol = this.product.title.split(euroSymbol)[0];
      if (this.product.title.includes(euroSymbol)) {
        const euroSymbolAndAmountText = euroSymbol + this.product.title.split(euroSymbol)[1];
        const whitespace = ' ';
        if (euroSymbolAndAmountText.includes(whitespace)) {
          return textPrecedingEuroSymbol + euroSymbolAndAmountText.replace(/\s+/g, '');
        }        
      }
      else {
        return this.product.title;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.exclude-from-comparison {
  color: var(--color-primary-900);
}
</style>