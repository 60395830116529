import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import Revisit from '@/constants/revisit';
import Modal from '@/constants/modal';
import BusinessRule from '@/constants/business-rule';

import EmailFormModal from './EmailFormModal.vue';

import RevisitEmailSuccesDialog from '@/components/shared/2.0-import/RevisitEmailSuccesDialog';

export default {
  create(title, text, text2, showClose = true, buttons = [], closeAction = null, formComponent = null) {
    return {
      title: title,
      text: text,
      text2: text2 ? text2 : null,
      showClose: showClose,
      buttons: buttons,
      closeAction: closeAction ? closeAction : null,
      formComponent: formComponent
    };
  },
  createRevisit() {
    return this.create(
      Vue.prototype.$tExistsOrDefault('modal.revisit.title'),
      Vue.prototype.$tExistsOrDefault('modal.revisit.paragraph'),
      null,
      false,
      [{
        isPrimary: false,
        action: () => {
          store.dispatch(Revisit.Actions.PATCH, { id: Vue.$cookies.get(store.getters.revisitCookieName), state: Revisit.RevisitStateEnum.declined })
            .then(() => {
              Vue.$cookies.remove(store.getters.revisitCookieName);
              store.dispatch(BusinessRule.Actions.RESET_VISITED_PACKAGE_TYPES);
              store.dispatch(Modal.Actions.CLOSE);
            });
        },
        text: Vue.prototype.$tExistsOrDefault('modal.revisit.start-again')
      },
      {
        isPrimary: true,
        action: () => {
          store.dispatch(Modal.Actions.CLOSE);
          router.push({
            name: 'RevisitLoader', params: {
              lang: store.getters.language,
              id: Vue.$cookies.get(store.getters.revisitCookieName),
              type: 'cookie'
            }
          });
        },
        text: Vue.prototype.$tExistsOrDefault('modal.revisit.continue-configuration')
      }]
    );
  },
  createInvalidRevisit() {
    return this.create(
      Vue.prototype.$tExistsOrDefault('modal.revisit-invalid.title'),
      Vue.prototype.$tExistsOrDefault('modal.revisit-invalid.paragraph'),
      null,
      true,
      null,
      () => {
        Vue.$cookies.remove(store.getters.revisitCookieName);
        router.replace({ name: 'home' });
      }
    );
  },
  createConfigurationWarning() {
    return this.create(
      Vue.prototype.$tExistsOrDefault('calculatePremium.configurationWarning.title'),
      Vue.prototype.$tExistsOrDefault('calculatePremium.configurationWarning.text'),
      null,
      false,
      [{
        isPrimary: false,
        text: Vue.prototype.$tExistsOrDefault('calculatePremium.configurationWarning.buttons.cancel'),
        action: () => {
          store.dispatch(BusinessRule.Actions.REVERT_TO_PREVIOUS_SELECTION_STATE);
          store.dispatch(Modal.Actions.CLOSE);
        }
      }, {
        isPrimary: true,
        text: Vue.prototype.$tExistsOrDefault('calculatePremium.configurationWarning.buttons.edit'),
        action: () => {
          store.dispatch(BusinessRule.Actions.SET_HAS_PACKAGE_SELECTION_CONFLICT_FALSE);
          store.dispatch(Modal.Actions.CLOSE);
        }
      }]
    );
  },
  createSendEmail() {
    return this.create(
      Vue.prototype.$tExistsOrDefault('overview.revisit-email-popup.title'), 
      Vue.prototype.$tExistsOrDefault('overview.revisit-email-popup.paragraph'), 
      Vue.prototype.$tExistsOrDefault('overview.revisit-email-popup.permission-premium-calculation-email'),
      true,
      null,
      null, 
      EmailFormModal
    );
  },
  createEmailSuccessfullySend() {
    return {
      component: RevisitEmailSuccesDialog
    };
  },
  createRemoveAllPackagesWhenDisabledBaiscInsuranceSelected(product, person) {
    return this.create(
      Vue.prototype.$tExistsOrDefault('calculatePremium.basicInsurance.resetSelectionModal.popUpTitle'),
      Vue.prototype.$tExistsOrDefault('calculatePremium.basicInsurance.resetSelectionModal.popUpText'),
      null,
      false,
      [{
        isPrimary: false,
        text: Vue.prototype.$tExistsOrDefault('calculatePremium.basicInsurance.resetSelectionModal.popUpCancel'),
        action: () => {
          store.dispatch(Modal.Actions.CLOSE);
        }
      },
      {
        isPrimary: true,
        text: Vue.prototype.$tExistsOrDefault('calculatePremium.basicInsurance.resetSelectionModal.popUpSubmit'),
        action: () => {
          store.dispatch(BusinessRule.Actions.SELECT_PRODUCT_FOR_PERSON, { product, person })
            .then(() => {
              store.dispatch(BusinessRule.Actions.POST);
            });
          store.dispatch(Modal.Actions.CLOSE);
        }
      }]
    );
  }
};