import { render, staticRenderFns } from "./BasicInsuranceIntroduction.vue?vue&type=template&id=8ca295de&scoped=true"
import script from "./BasicInsuranceIntroduction.vue?vue&type=script&lang=js"
export * from "./BasicInsuranceIntroduction.vue?vue&type=script&lang=js"
import style0 from "./BasicInsuranceIntroduction.vue?vue&type=style&index=0&id=8ca295de&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ca295de",
  null
  
)

export default component.exports