<template>
  <FullscreenOverlay
    v-model="modelValue"
    sizedWindow
    sizedWindowFlexPosition="end"
    style="flex-wrap: wrap;"
    :class="{'overlay': $screen.gt.xs}"
  >
    <div class="overlay-wrapper">
      <CloseButtonStickyHeader @close="onClose">
        <slot />
        <div v-if="$screen.xs" class="mobile-space" />
      </CloseButtonStickyHeader>
    </div>
  </FullscreenOverlay>
</template>

<script>
import FullscreenOverlay from '@/components/shared/2.0-import/FullscreenOverlay.vue';
import CloseButtonStickyHeader from './CloseButtonStickyHeader.vue';

export default {
  name: 'PartialOverlayWithCloseButton',
  components: {
    FullscreenOverlay,
    CloseButtonStickyHeader
  },
  props: {
    value: Boolean
  },
  data() {
    return {
    };
  },
  computed: {
    modelValue: {
      get() { 
        return this.value; 
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    isMobile() {
      return this.$screen.xs; 
    },
  },
  methods: {
    onClose() {
      this.modelValue = false;
    },
  },
  watch: {
  }
};
</script>

<style lang='scss' scoped>
.overlay-wrapper {
  height: 100vh;
  user-select: none;
}
.mobile-space {
  height: 7dvh;
}
</style>
