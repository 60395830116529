<template>
  <H3 v-if="insuredPerson.typeOfInsured === 'H'">
    {{ $tExistsOrDefault('calculatePremium.premiumOverview.insuredPerson.you') }}
  </H3>
  <H3 v-else-if="insuredPerson.typeOfInsured === 'P'">
    {{ $tExistsOrDefault('calculatePremium.premiumOverview.insuredPerson.partner') }}
  </H3>
  <H3 v-else-if="insuredPerson.typeOfInsured === 'M'">
    {{ $tExistsOrDefault('calculatePremium.premiumOverview.insuredPerson.child') }}
    {{ dateLabelOfChild }}
  </H3>
</template>

<script>
import H3 from '@/components/shared/2.0-import/text/H3.vue';

export default {
  name: 'PersonPremiumCardHeader',
  components: {
    H3
  },
  props: {
    insuredPerson: Object
  },
  computed: {
    dateLabelOfChild() {
      if (this.insuredPerson.typeOfInsured === 'M') return `(${this.insuredPerson.dateOfBirth})`;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
