<template>
  <label
    :disabled="disabled" @click.stop="clickHandler"
    :tabindex="disabled ? -1: 0"
    @keydown.space="clickHandler"
  >
    <div class="radio-container">
      <input
        tabindex="-1"
        type="radio"
        :checked="checked"
        :disabled="disabled"
        :name="name"
      />
      <span class="checkmark" />
    </div>
    <slot />
  </label>
</template>
<script>

export default {
    name: 'RadioButton',
    props: {
        value: {
            type: [String, Number, Boolean]
        },
        /**
         * Name of the radio button group
         * This makes sure that only one radio button can be selected within the group
         */
        name: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        radioValue: {
            type: [String, Number, Boolean],
            required: true
        }
    },
    methods:{
      clickHandler(event) {
        event.preventDefault();
          if(this.radioValue !== this.value){
              this.$emit('input', this.radioValue);
              this.$emit('change');
          }
        }
    },
    computed: {
        checked() {
            return this.value === this.radioValue;
        }
    }
};
</script>

<style lang="scss" scoped>
label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;  
  .radio-container{
    position: relative;
    margin-right: 12px;
    /* Hide the browser's default radio button */
    input {
      width: 24px;
      height: 24px;
      position: absolute;
      opacity: 0;
      cursor: inherit;
      pointer-events: none;
    }
    /* Create a custom radio button */
    .checkmark {
      display: block;
      height: 24px;
      width: 24px;
      background-color: white;
      border-radius: 50%;
      border: 1px solid var(--color-primary-900);
      transition: all 0.2s ease-in-out;
      &:after{
        display: block;
       content: "";
        position: absolute;
        top:4px;
        left:4px;
        margin:auto;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: var(--color-primary-900);
        opacity: 0;
         transition: all 0.2s ease-in-out;
      }
    }
    input:checked ~ .checkmark:after {
       opacity: 1;
    }
      input:checked:disabled ~ .checkmark:after {
        background: var(--color-grey-500);
    }
  }
  &[disabled]{
    .checkmark{
      border-color: var(--color-grey-500);
      pointer-events: none;
    }
    &::v-deep{
      color: var(--color-grey-500);
    }
  }
  &:hover:not([disabled]), &:focus:not([disabled]){
    .checkmark{
      box-shadow: 0 0 0 3px var(--color-primary-200);
    }
  }
  &:focus-visible{
    outline: none;
  }
}
</style>