import Vue from 'vue';
import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';

// eslint-disable-next-line no-unused-vars
import Global from '@/constants/global';

import Modal from '@/stores/modal';
import Contracts from '@/stores/contracts';
import Logo from '@/stores/logo';
import Search from '@/stores/search';
import BusinessRule from '@/stores/business-rule';
import Labels from '@/stores/labels';
import Validation from '@/stores/validation';
import CollectivityLabel from '@/stores/collectivityLabel';
import Revisit from '@/stores/revisit';
import Settings from '@/stores/settings';
import TooltipDisabledPackages from '@/stores/tooltipDisabledPackages';
import Address from '@/stores/address';
import Zorgzoeker from '@/stores/zorgzoeker';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    Contracts,
    Logo,
    BusinessRule,
    Modal,
    Labels,
    Search,
    Validation,
    CollectivityLabel,
    Revisit,
    Settings,
    TooltipDisabledPackages,
    Address,
    Zorgzoeker
  },
  mutations: {
  },
  plugins: [
  ],
  strict: process.env.NODE_ENV !== 'production'
});

Vue.use(vuexI18n.plugin, store, {
  onTranslationNotFound: function (locale, key) {
    console.warn(`vuex-i18n :: Key '${key}' not found for locale '${locale}'`);
  }
});
const plugin = {
  install(Vue, store) {
    Vue.prototype.$tExistsOrDefault = (path, options = {}) => {
      // setup required data
      options = { 
        default: 'default', 
        shouldTakeDefault: false, 
        query: [store.getters.selectedContractCode, store.getters.labelSettings.label], 
        inserts: {},
        ...options 
      };
      if (!Array.isArray(options.query)) options.query = [options.query];
      if (!options.query.includes(options.default)) options.query.push(options.default);

      // try getting translation by query priority
      if (!options.shouldTakeDefault) {
        for (const query of options.query) {
          if(query && Vue.i18n.keyExists(`${path}.${query}`)) return Vue.i18n.translate(`${path}.${query}`, options.inserts); 
        }
        // Support for array of objects
        if (Vue.i18n.keyExists(`${path}`)){
            var result = Vue.i18n.translate(`${path}`, options.inserts);
            if(Array.isArray(result)){
              return result.map((item) => {
                var matchedKey = options.query.find(option => Object.keys(item).includes(option));
                if(matchedKey){
                  return item[matchedKey];
                }
                return item;
              });
            }
          return Vue.i18n.translate(`${path}`, options.inserts);
        } 
      }

      // not found OR shouldTakeDefault = true
      return options.shouldTakeDefault 
        ? Vue.i18n.translate(`${path}.${options.default}`)
        : Vue.i18n.translate(options.query[0] ? `${path}.${options.query[0]}` : `${path}`);
    };
  },
};
Vue.use(plugin, store);

export default store;
