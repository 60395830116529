<template>
  <div>
    <div id="text-container">
      <H1>{{ $tExistsOrDefault('calculatePremium.ownRisk.groupTitle') }}</H1>
      <PlainText :class="{'max-width-text': $screen.gt.xs}">{{ $tExistsOrDefault('calculatePremium.ownRisk.intro') }}</PlainText>
      <LinkText @click.native="showOwnRiskOverlay = true" id="link-text">{{ $tExistsOrDefault('calculatePremium.ownRisk.linkText') }}</LinkText>
    </div>
    <HorizontalScroll
      v-match-heights="{el: ['.ownrisk', '.discount-rate', '.person-checkbox']}"
      gap="22px"
    >
      <OwnRiskComponent
        v-for="(product, i) in components"
        :key="'own-risk-components' + i"
        :product="product"
        class="own-risk-card"
        v-tooltip.top="!hasSelectedBasicInsurance ? $tExistsOrDefault('calculatePremium.ownRisk.noBasicSelected') : ''"
        :class="{ 'first-card': i === 0, 'last-card': i === components.length -1}"
      />
    </HorizontalScroll>
    <OwnRiskOverlay v-model="showOwnRiskOverlay" />
  </div>
</template>
<script>
import HorizontalScroll from '@/components/shared/2023/HorizontalScroll.vue';
import OwnRiskComponent from '@/components/configuration/OwnRiskComponent.vue';
import H1 from '@/components/shared/2.0-import/text/H1.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import LinkText from '@/components/shared/2.0-import/text/LinkText.vue';
import OwnRiskOverlay from '../overlays/OwnRiskOverlay.vue';

export default {
  name: 'OwnRiskGroup',
  components: {
    HorizontalScroll,
    OwnRiskComponent,
    H1,
    PlainText,
    LinkText,
    OwnRiskOverlay
  },
  data () {
    return {
      showOwnRiskOverlay: false
    };
  },
  props: {
    productList: Array,
    hasSelectedBasicInsurance: Boolean
  },
  computed: {
    components () {
      return this.productList?.find(group => group.packageType === 'OW')?.products?.map(p => this.disablePersonsIfNoBasisInsurance(p));
    }
  },
  methods:{
    disablePersonsIfNoBasisInsurance(product){
      return {
        ...product,
        persons: product.persons.map(p => {
          return {
            ...p,
            isDisabled: !this.hasSelectedBasicInsurance
          };
        })
      };
    }
  }
};
</script>
<style lang="scss" scoped>
#text-container{
  margin-bottom: 19px;
 *{
   margin-bottom: 7px;
  }
}
.max-width-text {
  width: 650px;
}
.own-risk-card {
  margin-top: 10px;
}
.first-card{
  margin-left: 5px;
}
.last-card{
  margin-right: 5px;
}
</style>
