import store from '@/store';
import { api } from '@/utils/api';

import Contracts from '@/constants/contracts';

const isLoading = function (type, value) {
  store.commit(Contracts.Mutations.SET_LOADING, {
    type: type,
    value: value
  });
};

export default {
  LoadByLandingsname (landingsName) {
    isLoading('LOAD_BY_LANDINGSNAME', true);

    const endpoint = Contracts.Endpoints.LoadByLandingsname();
    const params = { landingsName: landingsName };

    return api.get(endpoint, { params })
      .finally(() => isLoading('LOAD_BY_LANDINGSNAME', false));
  },

  LoadBySelectedContract (contract) {
    isLoading('LOAD_BY_SELECTED_CONTRACT', true);

    const endpoint = Contracts.Endpoints.LoadBySelectedContract();
    const params = {
      collectivityCodes: contract,
      referenceDate: new Date().toISOString().substr(0, 10),
      language: store.getters.language,
      policyYear: 2019
    };

    return api.get(endpoint, { params })
      .finally(() => isLoading('LOAD_BY_SELECTED_CONTRACT', false));
  },

  Search (data) {
    isLoading('SEARCH', true);

    const endpoint = Contracts.Endpoints.Search();
    const params = {
      q: data,
      label: store.getters.labelSettings.label
    };

    return api.get(endpoint, { params })
      .finally(() => isLoading('SEARCH', false));
  },

  LoadContract (contractCode, lastPolicyYear, label) {
    isLoading('LOAD_CONTRACT', true);

    const endpoint = Contracts.Endpoints.LoadContract(contractCode, lastPolicyYear, label);

    return api.get(endpoint)
      .finally(() => isLoading('LOAD_CONTRACT', false));
  },

  LoadSubcontractsByContractCode (contractCode, lastPolicyYear) {
    isLoading('LOAD_SUBCONTRACTS_BY_CONTRACT_CODE', true);

    const endpoint = Contracts.Endpoints.LoadSubcontractsByContractCode(contractCode, lastPolicyYear);

    return api.get(endpoint, { })
      .finally(() => isLoading('LOAD_SUBCONTRACTS_BY_CONTRACT_CODE', false));
  },

  LoadEjwInfo (data) {
    isLoading('LOAD_EJW_INFO', true);

    const endpoint = Contracts.Endpoints.LoadEjwInfo(data);

    return api.get(endpoint, {})
      .finally(() => isLoading('LOAD_EJW_INFO', false));
  },

  LoadReimbursementCategories () {
    isLoading('LOAD_REIMBURSEMENT_CATEGORIES', true);

    const endpoint = Contracts.Endpoints.LoadReimbursementCategories();

    return api.get(endpoint, { })
      .finally(() => isLoading('LOAD_REIMBURSEMENT_CATEGORIES', false));
  }
};
