import Search from '@/constants/search';

const state = {
  searchText: ''
};

const getters = {
  searchText: state => state.searchText
};

const actions = {
  [Search.Actions.CLEAR_SEARCH] ({ commit }) {
    commit(Search.Mutations.CLEAR_SEARCH);
  }
};

const mutations = {
  [Search.Mutations.SET] (state, payload) {
    state.searchText = payload;
  },

  [Search.Mutations.CLEAR_SEARCH] (state) {
    state.contractSearch = false;
  },

  [Search.Mutations.CLEAR] (state) {
    state.searchText = '';
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
