<template>
  <svg id="info-icon" @click.stop width="21" height="24" viewBox="0 0 21 24"
    xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd" transform="translate(0 2)">
      <circle stroke="currentColor" cx="10.5" cy="10.5" r="10" />
      <text font-family="SourceSansPro-Bold, Source Sans Pro" font-size="18" font-weight="bold" fill="currentColor">
        <tspan x="8.032" y="16.75">i</tspan>
      </text>
    </g>
  </svg>
</template>
  
<script>
export default {
  name: 'InformationIcon'
};
</script>
  
<style lang="scss" scoped>
#info-icon {
  color: var(--color-primary);
}
</style>