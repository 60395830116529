<template>
  <div id="webchat-target"></div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      reloadPage: false,
    };
  },
  computed: {
    ...mapGetters(["language"]),
    emailVerificationAC() {
      if (this.language === "nl") {
        return "EmailVerification";
      }
      return "EmailVerificationEN";
    },
    feedbackFormAC() {
      if (this.language === "nl") {
        return "FeedBackForm";
      }
      return "FeedBackFormEN";
    },
    webChatConfig(){
      return {
        language: this.language,
        storageLocation: sessionStorage,
        ucc: "ucc-webchat-email", 
        available: {
          adaptivecard: {
            id: this.emailVerificationAC,
            disableInput: true,
          },
          state: "Enabled",
        },
        away: {
          teaseMessageSettings: {
            showAsMessage: true,
            showAsTeaser: false,
            message: {
              en: this.$tExistsOrDefault("webChat.away"),
            },
          },
          state: "ChatInteractionDisabled",
        },
        busy: {
          teaseMessageSettings: {
            message: {
              en: this.$tExistsOrDefault("webChat.busy"),
            },
            showAsMessage: true,
            showAsTeaser: false,
          },
          adaptivecard: {
            id: this.emailVerificationAC,
            disableInput: true,
          },
          state: "Enabled",
        },
        offline: {
          state: "Hidden",
        },
        postDialogue: {
          adaptivecard: {
            id: this.feedbackFormAC,
            disableInput: true,
          },
        },
        templates: {
          themes: [
            {
              themeName: "light",
              primaryColor: "#4db1cb",
              backgroundColor: "#f6fcfe",
              borderColor: "#def0f5",
              iconColor: "#595959",
              textColor: "#434343",
              textColorLight: "#8c8c8c",
              hoverColor: "#008fb4",
            },
          ],
          agentPlaceholder:
            '<span class="agent-placeholder"><div class="logo"><webchat-image src="resource:start-chat"></webchat-image><webchat-ucc-presence-indicator></webchat-ucc-presence-indicator></div><div class="webchat-message"><webchat-title></webchat-title"><webchat-presence-text></webchat-presence-text></div></span><style>:host {pointer-events: none}.agent-placeholder {display: flex;flex-direction: row;margin: 15px 15px 0 15px;padding-bottom: 15px;border-bottom: 1px solid var(--border-color)}.logo {background-color: var(--primary-color);display: flex;justify-content: center;align-items: center}.logo svg {width: 28px;height: 28px;transition: width 200ms, height 200ms}.logo {background-position: center;background-repeat: no-repeat;background-size: cover;transition: height 200ms, width 200ms;width: 58px;height: 58px;border-radius: 50%;position: relative}:host([small=true]) .logo {width: 45px;height: 45px}:host([small=true]) .logo svg {width: 21px;height: 21px}.webchat-message {font-family: Roboto, "Segoe UI Emoji";display: flex;flex-direction: column;align-self: center;padding-left: 10px}.webchat-message .title {font-size: 16px;font-weight: 600;color: var(--text-color)}webchat-ucc-presence-indicator {position: absolute;right: 0;bottom: 0}</style>',
          notification: '<div class="message-state"></div>',
          startButton: '<div class="container"><div class="button-circle"></div><span class="icon"><webchat-image src="resource:start-chat"></webchat-image></span></div><webchat-unread-counter></webchat-unread-counter><webchat-ucc-presence-indicator></webchat-ucc-presence-indicator><style>:host(.unread-messages) .unread-counter {display: block}:host(.unread-messages) .button-circle {width: 23px;height: 23px;top: -18px;right: -65px}:host(.unread-messages) webchat-ucc-presence-indicator {display: none}.container {overflow: hidden;width: 60px;height: 60px;border-radius: 50%;position: relative; z-index:2147482999 !important;}.container .button-circle {position: absolute;top: -15px;right:             -61px;width: 16px;height: 16px;background-color: transparent;border: 60px solid var(--primary-color);border-radius: 50%}.container .icon {position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%)}.container svg *[fill]:not([fill=none]) {fill: #fff}.container svg *[stroke]:not([stroke=none]) {stroke: #fff}.container svg {transition: transform 200ms ease-in-out}.container:hover .button-circle {border-color: var(--hover-color)}.unread-counter {position: absolute;bottom: -3px;right: -3px}webchat-ucc-presence-indicator {position: absolute;bottom: 1px;right: 1px}webchat-unread-counter {position: absolute;bottom: 1px;right: 1px; z-index:10000;}</style>',
          topBar:
            '<div class="topbar-container"><webchat-minimize-button class="minimize-button"></webchat-minimize-button><webchat-close-button class="close-button"></webchat-close-button></div><style>:host {    height: 44px;    box-sizing: border-box;    width: 100%;    background-color: transparent;    padding: 0 13px;    display: block}.topbar-container {    display: flex;  position: relative;    height: 100%;    align-items: center; justify-content:right;}</style>',
        },
      };
    },
    webChatConfigIos() {
      return {
        language: this.language,
        storageLocation: sessionStorage,
        ucc: "ucc-webchat-email", 
        available: {
          teaseMessageSettings: {
            showAsMessage: true,
            showAsTeaser: false,
            message: {
              en: this.$tExistsOrDefault("webChat.availableIos"),
            },
          },
          state: "Enabled",
        },
        away: {
          teaseMessageSettings: {
            showAsMessage: true,
            showAsTeaser: false,
            message: {
              en: this.$tExistsOrDefault("webChat.away"),
            },
          },
          state: "ChatInteractionDisabled",
        },
        busy: {
          teaseMessageSettings: {
            message: {
              en: this.$tExistsOrDefault("webChat.busy"),
            },
            showAsMessage: true,
            showAsTeaser: false,
          },
          adaptivecard: {
            id: this.emailVerificationAC,
            disableInput: true,
          },
          state: "Enabled",
        },
        offline: {
          state: "Hidden",
        },
        templates: {
          themes: [
            {
              themeName: "light",
              primaryColor: "#4db1cb",
              backgroundColor: "#f6fcfe",
              borderColor: "#def0f5",
              iconColor: "#595959",
              textColor: "#434343",
              textColorLight: "#8c8c8c",
              hoverColor: "#008fb4",
            },
          ],
          agentPlaceholder:
            '<span class="agent-placeholder"><div class="logo"><webchat-image src="resource:start-chat"></webchat-image><webchat-ucc-presence-indicator></webchat-ucc-presence-indicator></div><div class="webchat-message"><webchat-title></webchat-title"><webchat-presence-text></webchat-presence-text></div></span><style>:host {pointer-events: none}.agent-placeholder {display: flex;flex-direction: row;margin: 15px 15px 0 15px;padding-bottom: 15px;border-bottom: 1px solid var(--border-color)}.logo {background-color: var(--primary-color);display: flex;justify-content: center;align-items: center}.logo svg {width: 28px;height: 28px;transition: width 200ms, height 200ms}.logo {background-position: center;background-repeat: no-repeat;background-size: cover;transition: height 200ms, width 200ms;width: 58px;height: 58px;border-radius: 50%;position: relative}:host([small=true]) .logo {width: 45px;height: 45px}:host([small=true]) .logo svg {width: 21px;height: 21px}.webchat-message {font-family: Roboto, "Segoe UI Emoji";display: flex;flex-direction: column;align-self: center;padding-left: 10px}.webchat-message .title {font-size: 16px;font-weight: 600;color: var(--text-color)}webchat-ucc-presence-indicator {position: absolute;right: 0;bottom: 0}</style>',
          notification: '<div class="message-state"></div>',
          startButton: '<div class="container"><div class="button-circle"></div><span class="icon"><webchat-image src="resource:start-chat"></webchat-image></span></div><webchat-unread-counter></webchat-unread-counter><webchat-ucc-presence-indicator></webchat-ucc-presence-indicator><style>:host(.unread-messages) .unread-counter {display: block}:host(.unread-messages) .button-circle {width: 23px;height: 23px;top: -18px;right: -65px}:host(.unread-messages) webchat-ucc-presence-indicator {display: none}.container {overflow: hidden;width: 60px;height: 60px;border-radius: 50%;position: relative; z-index:2147482999 !important;}.container .button-circle {position: absolute;top: -15px;right:             -61px;width: 16px;height: 16px;background-color: transparent;border: 60px solid var(--primary-color);border-radius: 50%}.container .icon {position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%)}.container svg *[fill]:not([fill=none]) {fill: #fff}.container svg *[stroke]:not([stroke=none]) {stroke: #fff}.container svg {transition: transform 200ms ease-in-out}.container:hover .button-circle {border-color: var(--hover-color)}.unread-counter {position: absolute;bottom: -3px;right: -3px}webchat-ucc-presence-indicator {position: absolute;bottom: 1px;right: 1px}webchat-unread-counter {position: absolute;bottom: 1px;right: 1px; z-index:10000;}</style>',
          topBar:
            '<div class="topbar-container"><webchat-minimize-button class="minimize-button"></webchat-minimize-button><webchat-close-button class="close-button"></webchat-close-button></div><style>:host {    height: 44px;    box-sizing: border-box;    width: 100%;    background-color: transparent;    padding: 0 13px;    display: block}.topbar-container {    display: flex;  position: relative;    height: 100%;    align-items: center; justify-content:right;}</style>',
        },
      };
    }
  },
  methods: {
    loadWebChat() {
      const isIos = navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
      const config = isIos ? this.webChatConfigIos : this.webChatConfig; 

      window.WebChat.load(config);

      window.addEventListener("WebChatLoaded", function () {
        const floatingButtonElement = document.getElementsByTagName(
          "webchat-floating-button"
        )[0];
        if (floatingButtonElement) {
          floatingButtonElement.style.position = "absolute";
          floatingButtonElement.style.zIndex = 100;
        }
      });
    },
  },
  watch: {
    language(lang) {
      if (lang && !this.reloadPage) {
        this.reloadPage = true;
        this.loadWebChat();
      } else if (this.reloadPage) {
        this.$router.push(`/${this.language}`);
        location.reload();
      }
    },
  },
};
</script>
