<template>
  <Teleport
    to="#app-base"
    v-show="value"
  >
    <FullscreenOverlay
      :value="value"
      class="mw-100vw mh-100"
      style="min-height:100vh"
    >
      <CompareDesktop
        v-if="$screen.gt.xs"
        :typeOfInsurance="typeOfInsurance"
        @closeOverlay="closeCompareOverlay"
      />
      <CompareMobile 
        v-else
        :typeOfInsurance="typeOfInsurance"
        @closeOverlay="closeCompareOverlay"
      />
    </FullscreenOverlay>
  </Teleport>
</template>

<script>
import FullscreenOverlay from '@/components/shared/2.0-import/FullscreenOverlay.vue';
import Teleport from 'vue2-teleport';
import CompareMobile from '@/components/shared/2.0-import/CompareMobile.vue';
import CompareDesktop from '@/components/shared/2.0-import/CompareDesktop.vue';

export default {
  name: 'CompareOverlay',
  components: {  
    FullscreenOverlay,
    Teleport,
    CompareMobile,
    CompareDesktop
  },
  emits: ['closeOverlay'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    typeOfInsurance: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeCompareOverlay(value) {
      this.$emit('closeOverlay', value);
    }
  }
}
</script>

<style lang='scss' scoped>
.mw-100vw {
  max-width: 100vw;
}
</style>
