<template>
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    preserve-aspect-ratio="xMidYMid meet"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        :stroke="color" stroke-width="2"
        stroke-linecap="round"
        d="m5 10.675 3.62 4.144L17 6"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'Check',
  props: {
    color: {
      type: String,
      default: 'var(--color-positive)'
    },
  }
};
</script>