/<template>
  <div class="container-position">
    <div class="custom-tooltip" v-if="shouldShowTooltip">
      <div class="p-2 pb-4 d-flex align-items-center flex-column">
        <div class="align-self-end">
          <DisabledPackageClose class="close-btn" @click.native="closeTooltip()" />
        </div>
        <div class="mt-1 mx-2">
          <PlainText type="white">{{ text }}</PlainText>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div 
          class="arrow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DisabledPackageClose from '@/assets/icons/DisabledPackageClose.vue';
import TooltipDisabledPackages from '@/constants/tooltipDisabledPackages';
import Button from '@/components/shared/button/Button.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';

export default {
  name: 'DisabledPackagesInfoContainer',
  components: { 
    DisabledPackageClose,
    Button,
    PlainText
  },
  data() {
    return {
      showInsuranceOverlay: false
    };
  },
  props: {
    text: {
      type: String,
      required: true
    },
    productComponentCodeCard: String,
  },
  computed: {
    shouldShowTooltip() {
      const packageData = this.$store.getters.packageData;
      const isTooltipVisible = this.$store.getters.isTooltipVisible;
      return isTooltipVisible && packageData?.productComponentCode === this.productComponentCodeCard;
    }
  },
  methods: {
    closeTooltip() {
      this.$store.dispatch(TooltipDisabledPackages.Actions.RESET_PACKAGE_DATA);
      this.$store.dispatch(TooltipDisabledPackages.Actions.LOCK_TOOLTIP);
    }, 
    showOverlay() {
      this.$emit('showOverlay', this.showInsuranceOverlay = true);
    }
  }
};
</script>
<style lang="scss" scoped>
.container-position {
  position: absolute;
  left: 0;
  right: 0;
}
.custom-tooltip {
  position: relative;
  background: var(--color-primary-800);
  z-index: 20;
  transform: translate(0px, -102%); 
  border-radius: 3px;
  width: calc(100% - 40px); // 100% - 2 * 20px (card padding)
  margin-left: auto;
  margin-right: auto;
  color: var(--color-white);
}
.arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid var(--color-primary-800);
  transform: translate(0, 7px);
}
.close-btn:hover {
  cursor: pointer;
}
</style>