<template>
  <div>
    <CalculatePremiumInsuredChild
      v-for="insuredChild in insuredChildren"
      :key="'insured-child-' + insuredChild.id"
      :insured="insuredChild"
    />
    <CalculatePremiumInsuredChild
      :insured="null"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BusinessRule from '@/constants/business-rule';

import CalculatePremiumInsuredChild from '@/components/insured/CalculatePremiumInsuredChild.vue';

export default {
  name: 'CalculatePremiumInsuredChildren',
  components: {
    CalculatePremiumInsuredChild,
  },
  computed: {
    ...mapGetters(['insured', 'agreement']),
    insuredChildren() {
      return this.insured.filter(i => i.typeOfInsured === 'M');
    },
  },
  methods: {
    handleAddChild() {
      this.$store.commit(BusinessRule.Mutations.ADD_INSURED, { typeOfInsured: 'M' });
    }
  }
};
</script>

<style lang='scss' scoped>

</style>
