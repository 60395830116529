<template>
  <div 
    v-tooltip="{ content: shouldShowInformation && !usePopup ? text : '', placement: position }"
    @click="onClick" 
  > 
    <slot />
  </div>
</template>

<script>
import Modal from '@/constants/modal';
import ModalDataFactory from '@/components/modals/ModalDataFactory';

export default {
  name: 'ExtraInformationContainer',
  props: {
    top: Boolean, 
    bottom: Boolean, 
    left: Boolean, 
    right: Boolean, 
    text: {
      type: String,
      required: true
    },
    title: String,
    alwaysUsePopup: Boolean,
    shouldShowInformation: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    position() {
      if (this.bottom) return 'bottom';
      if (this.left) return 'left';
      if (this.right) return 'right';
      return 'top';
    },
    usePopup() {
      return this.alwaysUsePopup || this.$screen.lt.md;
    }
  },
  methods: {
    onClick() {
      if (this.shouldShowInformation && this.usePopup && this.text) {
        this.$store.dispatch(Modal.Actions.OPEN, { 
          modalData: ModalDataFactory.create(
            this.title ? this.title : this.$tExistsOrDefault('modal.general.information'),
            this.text
          )
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
