<template>
  <div class="sticky-container">
    <div class="flex-row-items">
      <div class="buttons">
        <ButtonPrevious @click.native="$emit('back')"></ButtonPrevious>
        <Button class="button-complete" layoutType="action" @click.native="$emit('complete-application')">
          {{ $tExistsOrDefault('calculatePremium.previewOverview.buttonCompleteInsuranceRequest') }}
        </Button>
      </div>
      <PreviewOverviewPaymentTermSlot :showWhite="true" />
      
      <EmailCalculation color="white" />
    </div>
    
  </div>
</template>
  
<script>
import ButtonPrevious from '@/components/shared/button/ButtonPrevious.vue';
import Button from '@/components/shared/button/Button.vue';

import EmailCalculation from '@/components/shared/EmailCalculation.vue';

import PreviewOverviewPaymentTermSlot from '@/components/configuration/previewOverview/PreviewOverviewPaymentTermSlot.vue';

export default {
  name: 'PreviewOverviewMobileButtons',
  components: {
    ButtonPrevious,
    Button,

    EmailCalculation,

    PreviewOverviewPaymentTermSlot
  },
  emits: ['back', 'complete-application']
}

</script>
  
<style lang="scss" scoped>
.flex-row-items{
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.sticky-container {
  position: sticky;
  bottom: 0;
  padding: 23px;
  background-color: var(--color-primary-900);
}

.buttons {
  display: flex;
  gap: 24px;
}

.button-complete {
  flex: 1;
}
</style>