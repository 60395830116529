<template>
  <label
    :class="{ 'disable': disabled, 'locked': locked}" :tabindex="disabledOrLocked ? -1: 0"
    @click="clickHandler"
    @keydown.space="clickHandler"
  >
    <input
      type="checkbox" :disabled="disabledOrLocked"
      v-model="modelValue"
      tabindex="-1"
    />
    <div class="check-container" :style="{ width: checkBoxSize + 'px !important', height: checkBoxSize + 'px !important'}">
      <Check
        id="check-icon" :color="checkIconColor"
        :class="{ 'hidden': !modelValue }"
      />
    </div>
    <slot />
  </label>
</template>
<script>
import Check from '@/assets/icons/Check.vue';

export default {
  components: { Check },
  name: 'Checkbox',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    value: null,
    checkBoxSize: {
      type: Number,
      default: 16
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    checkIconColor() {
      return this.disabled ? 'var(--color-grey-400)' : 'var(--color-primary-900)';
    },
    disabledOrLocked() {
      return this.disabled || this.locked;
    }
  },
  methods: {
    clickHandler(event) {
      if (!this.disabled && !this.locked) {
        event.stopPropagation();
        event.preventDefault();
        this.modelValue = !this.modelValue;
        this.$emit('change', event);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.check-container {
  border: 1px solid var(--color-primary-900);
  aspect-ratio: 1;
}

label {
  cursor: pointer;
  color: var(--color-primary-900);
  display: flex;
  padding-right: 8px;
  user-select: none;
  margin-bottom: 0;

  &:hover:not(.disable, .locked), &:focus:not(.disable, .locked){
    .check-container{
      box-shadow: 0 0 0 3px var(--color-primary-200);
    }
  }

  &:focus-visible{
    outline: none;
  }

  input[type=checkbox] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
   &.disable{
    cursor: not-allowed;
      .check-container{
        border: 1px solid var(--color-grey-400);
      }
    }


  .check-container {
    border-radius: 3px;
    background-color: white;
    box-sizing: border-box;
    position: relative;
    transition: box-shadow 0.2s ease-in-out;
    
    #check-icon {
      transition: all 0.2s ease-in-out;
      opacity: 1;
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;

      &.hidden {
        opacity: 0;
      }
    }
  }
}

.is-invalid * .check-container {
  border: solid 1px var(--color-error);
  box-shadow: 0 0 5px 1px rgba(191, 43, 45, 0.5);
}
</style>