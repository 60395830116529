import Revisit from '@/constants/revisit';
import RevisitUtils from '@/utils/revisit';
import CollectivityLabel from '@/stores/collectivityLabel';
import VueCookies from 'vue-cookies';
import router from '@/router';
import { v4 as uuidv4 } from 'uuid';
import i18n from 'vuex-i18n';

const state = {
  stateItem: {},
  afterMedicalSelectionRevisitRedirect: undefined
};

const getters = {
  revisitStateItem: () => state.stateItem,
  revisitCookieName: () => 'revisitId',
  afterMedicalSelectionRevisitRedirect: () => state.afterMedicalSelectionRevisitRedirect
};

const actions = {
  [Revisit.Actions.SAVE](
    { dispatch, getters }, 
    { type = 'cookie', agreementModel, step, urlLastPage = router.currentRoute?.path, emailDetails = undefined }) 
  {
    const request = { 
      type: type.toLowerCase(),
      agreementModel: agreementModel,
      urlLastPage: urlLastPage,
      state: Revisit.RevisitStateEnum.unused,
      step: step
    };
    
    if (request.type === 'cookie') {
      let revisitId = VueCookies.get(getters.revisitCookieName);
      if (!revisitId) {
        revisitId = uuidv4();
        VueCookies.set(
          getters.revisitCookieName,
          revisitId,
          '6m',
          '/'
        );
      };
      request.id = revisitId;
    }
    else if (request.type === 'email') {
      request.id = uuidv4();
      request.customerEmailAddress = emailDetails.emailaddress;
      request.label = CollectivityLabel.state.settings.label;
      request.labelFullName = CollectivityLabel.state.settings.fullName;
      request.languageCode = i18n.store.state.locale;
    }
    else {
      throw `non existing revisit type: ${type}, use 'cookie' or 'email'`;
    }

    RevisitUtils.Save(request);
  },
  [Revisit.Actions.GET_AGREEMENT_STATE] ({ commit }, id) {
    return RevisitUtils.Get(id)
      .then((response) => {
        commit(Revisit.Mutations.SET_STATE_ITEM, response.data);
      });
  },
  // eslint-disable-next-line no-empty-pattern
  [Revisit.Actions.PATCH] ({}, payload) {
    return RevisitUtils.Patch(payload.id, payload.state);
  }
};

const mutations = {
  [Revisit.Mutations.SET_STATE_ITEM] (state, payload) {
    state.stateItem = payload;
  },
  [Revisit.Mutations.SET_AFTER_MEDICAL_REDIRECT] (state, payload) {
    state.afterMedicalSelectionRevisitRedirect = payload;
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
};
