<template>
  <div>
    <section class="header-section">
      <div class="header-title">
        <Magnifier id="magnifier-icon" />
        <H1>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.title') }}</H1>
      </div>
      <PlainText>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.paragraph') }}</PlainText>
    </section>

    <ValidationObserver ref="healthcareProvidersOverlayForm" v-slot="{ invalid }">
      <div class="flex-item" :class="{ 'mobile': $screen.xs }">
        <div class="year-container">
          <Label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.year') }}</Label>
          <Dropdown 
            v-model="form.year" 
            :options="yearOptions" 
            :useTranslationKeys="false" 
            :disabled="!isEjw"
          />
        </div>

        <div class="flex-1 input-container">
          <Label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.whichCare') }}</Label>
          <FormFieldValidation class="input-container" :rules="{ 'requiredCare': selectedCare }" :mode="'eager'">
            <InputField 
              v-model="form.care" 
              v-debounce:500="getCares" 
              :isOptionsStringArray="false"
              @selected-value="x => setCareId(x)" 
              :options="cares"
            />
            <div v-if="isLoadingCares" class="loading-icon-absolute">
              <font-awesome-icon
                icon="circle-notch"
                size="lg"
                spin
              />
            </div>
          </FormFieldValidation>
        </div>
      </div>

      <div class="flex-item" :class="{ 'mobile': $screen.xs }" v-show="form.careId != null && !isZorgCategory">
        <div class="flex-1">
          <Label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.yourBasicInsurance') }}</Label>
          <BasicInsuranceDropdown v-model="modelValue" />
        </div>
      </div>

      <div class="flex-item" :class="{ 'mobile': $screen.xs }">
        <div class="flex-1">
          <Label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.institutionName') }}</Label>
          <div class="input-container">
            <InputField 
              v-model="form.institution" 
              v-debounce:500="getInstitutions" 
              :shouldFilterByValue="false"
              :useTranslationKeys="false" 
              :options="institutions" 
            />
            <div v-if="isLoadingInstitution" class="loading-icon-absolute">
              <font-awesome-icon
                icon="circle-notch"
                size="lg"
                spin
              />
            </div>
          </div>          
        </div>
      </div>

      <div class="flex-item" :class="{ 'mobile': $screen.xs }">
        <div class="flex-1">
          <Label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.zipcodeLocation') }}</Label>
          <LocationInputField v-model="form.location" @zipcode="(x) => form.zipcode = x" @city="(x) => form.city = x"></LocationInputField>
        </div>

        <div>
          <Label>{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.maxDistance') }}</Label>
          <DistanceDropdown v-model="form.distance"></DistanceDropdown>
        </div>
      </div>

      <Button class="search-button" :disabled="isLoading" @click.native="getHealthCareProviders()">{{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.search') }}</Button>

      <PlainText class="results-info" v-if="showResultsInfo">
        {{ $tExistsOrDefault('calculatePremium.basicInsurance.healthcareProviderOverlay.searchResults', { inserts: { numberOfResults: healthCareProviders.length, care: selectedCare.text, zipcodeCity: form.zipcode ?? form.city } }) }}  
      </PlainText>

      <Loading :isLoading="isLoading">
        <HealthcareProviderInfo :selectedYear="form.year" @clickHealthCareProvider="(x) => $emit('clickHealthCareProvider', { healthcareProvider: x, selectedYear: form.year })" :healthCareProviders="healthCareProviders" />
      </Loading>
    </ValidationObserver>
  </div>
</template>
    
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import H1 from '@/components/shared/2.0-import/text/H1.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';

import HealthcareProviderInfo from '@/components/healthcare-provider/HealthcareProviderInfo.vue';
import Button from '@/components/shared/button/Button.vue';
import InputField from '@/components/shared/form/InputField.vue';
import Dropdown from '@/components/shared/form/Dropdown.vue';
import DistanceDropdown from '@/components/shared/form/zorgzoeker/DistanceDropdown.vue';
import BasicInsuranceDropdown from '@/components/shared/form/zorgzoeker/BasicInsuranceDropdown.vue';
import LocationInputField from '@/components/shared/form/zorgzoeker/LocationInputField.vue';
import FormFieldValidation from '@/components/shared/form/FormFieldValidation.vue';
import Loading from '@/components/shared/2.0-import/Loading.vue';

import ZorgzoekerUtils from '@/utils/zorgzoeker';
import Magnifier from '@/assets/icons/Magnifier.vue';

import HealthCareProviderCategory from '@/constants/HealthCareProviderCategory';

export default {
  name: 'HealthcareProviderSearch',
  emits: ['clickHealthCareProvider'],
  components: {
    ValidationObserver,
    H1,
    PlainText,
    HealthcareProviderInfo,
    Button,
    InputField,
    Dropdown,
    DistanceDropdown,
    BasicInsuranceDropdown,
    LocationInputField,
    FormFieldValidation,
    Loading,
    Magnifier
  },
  props: {
    value: Number,
  },
  data() {
    return {
      form: {
        year: 2023,
        care: null,
        careId: null,
        institution: null,
        location: '',
        zipcode: null,
        city: null,
        distance: 10000
      },
      isLoadingCares: false,
      cares: [],
      isLoadingInstitution: false,
      institutions: [],
      healthCareProviders: [],
      isLoading: false,
      showResultsInfo: false
    };
  },
  computed: {
    ...mapGetters(['isEjw']),
    yearOptions(){
      var options = [ this.currentYear];
      if(this.isEjw){
        options.push(this.currentYear + 1);
      }
      return options.map(x => ({ value: x, text: x }));
    },
    currentYear() {
      return new Date().getFullYear();
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
        this.resetData();
      },
    },
    selectedCare() {
      return this.cares.find(x => x.value === this.form.careId);
    },
    isZorgCategory() {
      return this.selectedCareCategory === HealthCareProviderCategory.Zorg;
    },
    selectedCareCategory() {
      return this.selectedCare?.category
    }
  },
  methods: {
    onClose() {
      this.value = false;
    },
    getCares() {
      if (this.form.care && this.form.care.length >= 3) {
        this.isLoadingCares = true;
        ZorgzoekerUtils.Get_Cares(this.form.care).then(x => {
          const list = [];
          x.data.forEach(y => {
            y.cares.forEach(z => {
              list.push({ value: z.id, text: z.name, category: y.category });
            });
          });

          this.cares = list;
        }).finally(() => {
          this.isLoadingCares = false;
        });
      }
    },
    getInstitutions() {
      if (this.form.careId && this.form.institution && this.form.institution.length >=3) {
        this.isLoadingInstitution = true;
        ZorgzoekerUtils.Get_Institutions(
          this.selectedCareCategory === HealthCareProviderCategory.Zorg ? this.form.careId : null, 
          this.selectedCareCategory === HealthCareProviderCategory.Specialismen ? this.form.careId : null, 
          this.selectedCareCategory === HealthCareProviderCategory.Aandoeningen ? this.form.careId : null, 
          this.form.institution)
        .then(x => {
          this.institutions = x.data;
        }).finally(() => {
          this.isLoadingInstitution = false;
        });
      }
    },
    async getHealthCareProviders() {
      const isValid = await this.$refs.healthcareProvidersOverlayForm.validate();

      if (isValid) {
        this.isLoading = true;
        ZorgzoekerUtils.Get_CareLocations(
          this.form.year, 
          this.selectedCareCategory === HealthCareProviderCategory.Zorg ? this.form.careId : null, 
          this.selectedCareCategory === HealthCareProviderCategory.Specialismen ? this.form.careId : null, 
          this.selectedCareCategory === HealthCareProviderCategory.Aandoeningen ? this.form.careId : null, 
          this.form.institution, 
          this.form.zipcode, 
          this.form.city, 
          this.form.distance, 
          this.isZorgCategory ? null : this.modelValue
        ).then(x => {
          this.healthCareProviders = x.data;
          this.showResultsInfo = true;
        })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    chrevonColor() {
      return 'var(--color-primary)';
    },
    setCareId(careId) {
      this.form.careId = careId;
    },
    resetData() {
      this.healthCareProviders = [];
      this.showResultsInfo = false;
    }
  },
  watch: {
    form: {
      handler() {
        this.resetData();
      },
      deep: true
    },
  },
  mounted() {
    this.form.year = this.isEjw? this.currentYear + 1 : this.currentYear;
  }
};
</script>
    
<style lang='scss' scoped>
.header-section {
  margin-bottom: 24px;
}

.header-title {
  display: flex;
  gap: 15px;
  margin-bottom: 12px;
}

#magnifier-icon {
  color: var(--color-primary);
}

.flex-item {
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
}

.loading-icon-absolute {
  position: absolute;
	top: 8px;
  right: 10px;
  color: var(--color-grey-500);
}

.flex-1 {
  flex: 1;
}

.input-container {
  position: relative;
}

.flex-item.mobile {
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.year-container {
  width: 104px;
}

.search-button {
  margin-bottom: 32px;
}

.results-info {
  margin-bottom: 32px;
}
</style>