<template>
  <div>
    <div class="subtitle">{{ $tExistsOrDefault('medicalSelection.signed') }}</div>
    <div class="agreement" v-html="$tExistsOrDefault('medicalSelection.agreement')"></div>
    <Button 
      type="primary"
      class="mt-4"
      @click.native="onClick()"
    >
       {{ $tExistsOrDefault('medicalSelection.agree') }}
    </Button>
  </div>
</template>

<script>
import Button from '@/components/shared/button/Button.vue';

export default {
  name: 'AgreementTermsForm',
  components: {
    Button
  },
  methods: {
    onClick() {
      this.$emit('acceptedTerms');
    }
  }
};
</script>

<style lang="scss" scoped>
.agreement {
  margin-top: 8px;
  max-width: 673px;
}
</style>