<template>
  <div class="main-content-wrapper">
    <div class="container">
      <H1>{{ $tExistsOrDefault('calculatePremium.calculationOverview.title') }}</H1>
      <p v-html="$tExistsOrDefault('calculatePremium.calculationOverview.intro')" />

      <div class="main-content">
        <div class="block-wrapper">
          <div class="block-primary">
            <CalculationInsured
              v-for="(person, i) in insuredPersons"
              :key="'insured=person' + i"
              :person="person"
            />
            <div class="row items items-small items-blue">
              <div class="col-md-8 flex">
                <div class="item">
                  <div class="item__heading">
                    {{ $tExistsOrDefault('calculatePremium.calculationOverview.total') }}
                  </div>
                  <TotalAmountInsured
                    v-for="(person, i) in insuredPersons"
                    :key="'insured-person-total' + i"
                    :person="person"
                  />
                  <hr />
                  <div class="item__content row9">
                    <hr v-if="insuredPersons.length > 1" />
                  </div>
                  <div class="item__content row10">
                    <div class="row">
                      <div class="col-md-10"><strong>{{ $tExistsOrDefault('calculatePremium.calculationOverview.totalPriceMonth') }}</strong></div>
                      <div class="col-md-2"><span> {{ formatCurrency(agreement.netPremiumWithDiscountTotal) }}</span></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4 flex">
                <div class="item">
                  <div class="item__heading">
                    <br />
                  </div>
                  <TotalDiscountInsured
                    v-for="(person, i) in insuredPersons"
                    :key="'insured-person-discount' + i"
                    :person="person"
                  />
                  <hr />
                  <div class="item__content row9">
                    <hr v-if="insuredPersons.length > 1" />
                  </div>
                  <div class="item__content row10 row">
                    <div class="col-md-8"><strong>{{ $tExistsOrDefault('calculatePremium.calculationOverview.totalDiscountMonth') }}</strong></div>
                    <div class="col-md-4"><span>{{ formatCurrency(agreement.grossPremiumTotal - agreement.netPremiumWithDiscountTotal) }}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-button">
          <button @click="goBack()" type="submit" class="btn btn-primary mb-2 secondary-colors">{{ $tExistsOrDefault('personal.prev') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalculationInsured from '@/components/premiumCalculation/CalculationInsured.vue';
import TotalAmountInsured from '@/components/premiumCalculation/TotalAmountInsured.vue';
import TotalDiscountInsured from '@/components/premiumCalculation/TotalDiscountInsured.vue';
import Dates from '@/helpers/dates';
import formatCurrencyHelper from '@/helpers/formatCurrency';
import Revisit from '@/constants/revisit';
import H1 from '@/components/shared/2.0-import/text/H1.vue';

export default {
  name: 'PremiumCalculation',
  components: {
    CalculationInsured,
    TotalAmountInsured,
    TotalDiscountInsured,
    H1
  },
  computed: {
    agreement () {
      return this.$store.getters.agreement;
    },
    insuredPersons () {
      return this.$store.getters.agreement.insuredPersons;
    },
    label (person) {
      let result = '';
      switch (person.typeOfInsured) {
        case 'H':
          result += this.$tExistsOrDefault('calculatePremium.general.main') + ': ';
          break;
        case 'P':
          result += this.$tExistsOrDefault('calculatePremium.general.partner') + ': ';
          break;
        case 'M':
          result += this.$tExistsOrDefault('calculatePremium.general.child') + ': ';
          break;
      }
      return result + this.person.dateOfBirth;
    }
  },
  methods: {
    formatCurrency(value) {
      return formatCurrencyHelper(
        value,
        this.$i18n.locale() ? this.$i18n.locale() : 'NL'
      );
    },
    goBack () {
      this.$router.push({
        name: 'configuration',
        params: {
          activationStep: 5,
          lang: this.$store.getters.language,
          contractCode: this.$store.getters.selectedContractCode
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch(Revisit.Actions.SAVE, { agreementModel: this.agreement});
  }
};
</script>

<style lang='scss' scoped>
  div.item {
    min-width: 100%;
  }
</style>
