<template>
  <div class="p-3 scrollable" :class="{'mobile': $screen.xs}">
    <ul
      v-for="(insuredPerson, i) in insured"
      :key="i"
    >
      <li class="mb-3">
        <InsurancePersonSummary :id="i" :insuredPerson="insuredPerson" />
      </li>
    </ul>
    <hr />
    <div>
      <div class="d-flex justify-content-between align-items-center div-text-sm">
        <span class="color-black">{{ $tExistsOrDefault('calculatePremium.premiumOverview.button.totalPremium') }}</span>
        <span class="text-right div-text-lg color-black">{{ formatCurrency(monthlyPremium(selectedPaymentTermInConfiguration)) }}</span>
      </div>
      <div class="div-text-xs text-right">{{ $tExistsOrDefault(`calculatePremium.premiumOverview.button.term.1`) }}</div>
      <div v-if="selectedPaymentTermInConfiguration === 12">
        <p class="text-right mb-0 div-text-lg" style="margin-top:14px;">
          {{ formatCurrency(paymentTermWithNetDiscount(selectedPaymentTermInConfiguration).paymentTermNetPremiumWithOwnRiskDiscountTotal) }}
        </p>
        <div class="div-text-xs text-right">{{ $tExistsOrDefault(`calculatePremium.premiumOverview.button.term.12`) }}</div>
        <div class="text-right div-text-xs" style="margin-top:9px;">
          {{ $t('calculatePremium.premiumOverview.collectivityDiscount', { discount: formatCurrency(paymentTermDiscount(selectedPaymentTermInConfiguration)) }) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import InsurancePersonSummary from '@/components/shared/2.0-import/InsurancePersonSummary.vue';
import { formatCurrency2 } from '@/helpers/formatCurrency';
import PaymentTermHelpers from '@/helpers/payment-term';

export default {
  name: 'InsuranceSummary',
  components: { InsurancePersonSummary },
  data() {
    return {
      formatCurrency: formatCurrency2,
      monthlyPremium: PaymentTermHelpers.monthlyPremium,
      totalBasePremium: PaymentTermHelpers.totalBasePremium,
      paymentTermDiscount: PaymentTermHelpers.paymentTermDiscount,
      paymentTermWithNetDiscount: PaymentTermHelpers.paymentTermWithNetDiscount
    };
  },
  computed: {
    ...mapGetters(['agreement', 'insured', 'selectedPaymentTermInConfiguration', 'nettoPremiums']),
  }
};
</script>
<style lang="scss" scoped>
li {
  cursor: pointer;
}
.scrollable {
  max-height: 60vh;
  overflow-y: auto;
  &.mobile {
    margin-bottom: 80px;
    max-height: 90vh;
  }
}
hr {
  border-color: #cccccc;
  background: none;
}
.div-text-lg {
  font-size: 28px;
  font-family: var(--text-font-semibold);
}
.div-text-sm {
  font-size: 18px;
  font-family: var(--text-font-semibold);
}
.div-text-xs {
  font-size: 16px;
  font-family: var(--text-font);
}
</style>
