<template>
  <div class="insurancecard-header">
    <div class="text-center">
      <div id="card-title">
        <CardHeader class="d-inline">{{ title }}</CardHeader>
        <LevelIndicator v-if="showLevelIndicator()" :level="level.level" :maxLevel="level.maxLevel" />
      </div>
      <PlainText v-if="subtitle" type="secondary">{{ subtitle }}</PlainText>
    </div>
    <ProductPremium :product="product" />
  </div>
</template>

<script>

import CardHeader from '@/components/shared/2.0-import/text/CardHeader.vue';
import PlainText from '@/components/shared/2.0-import/text/PlainText.vue';
import LevelIndicator from '@/components/shared/LevelIndicator.vue';
import ProductPremium from '@/components/configuration/insuranceCard/ProductPremium.vue';

export default {
  name: 'InsuranceCardHeader',
  components: {
    CardHeader,
    PlainText,
    LevelIndicator,
    ProductPremium
  },
  data() {
    return {
    };
  },
  props: {
    title: {
      type: String,
      required: true	
    },
    subtitle: {
      type: String,
      required: false
    },
    level: {
      type: Object,
      required: false
    },
    showInsurancePremium: {
      type: Boolean,
      default: true
    },
    product: {
      type: Object,
      required: true
    },
  },
  methods: {
    showLevelIndicator() {
      return this.level && this.level.maxLevel > 1 && this.level.maxLevel < 6;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>