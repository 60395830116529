<template>
  <FormFieldValidation :rules="{ 'validCorrectLocationOrZipcode': cities }" :mode="'eager'">
    <InputField :placeholder="$tExistsOrDefault('calculatePremium.basicInsurance.hospitalsOverlay.zipCodeOrCity')"
      v-mask="locationMask" 
      :options="cities" 
      :useTranslationKeys="false" 
      v-model="modelValue"
      :minimumLengthShowDropdown="3" />
  </FormFieldValidation>
</template>
      
<script>
import { mapGetters } from 'vuex';

import InputField from '@/components/shared/form/InputField.vue';
import FormFieldValidation from '@/components/shared/form/FormFieldValidation.vue';

export default {
  name: 'DistanceDropdown',
  emits: ['zipcode', 'city'],
  components: {
    InputField,
    FormFieldValidation
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['cities']),
    modelValue:{
      get(){
        return this.value;
      },
      set(v){
        this.$emit('input', v);
      }
    },
    isTypingZipCode() {
      return this.modelValue && this.modelValue.match(/^\d/);
    },
    locationMask() {
      if (this.isTypingZipCode) {
        return '#### AA';
      }
      else {
        return null;
      }
    },
    zipcode() {
      if (this.isTypingZipCode && this.modelValue.length >= 4) {
        return this.modelValue;
      }
      else {
        return null;
      }
    },
    city() {
      if (!this.isTypingZipCode) {
        return this.modelValue;
      }
      else {
        return null;
      }
    }
  },
  methods: {
    locationChanged() {
      this.$emit('zipcode', this.zipcode);
      this.$emit('city', this.city);
    }
  },
  watch: {
    modelValue() {
      this.locationChanged();
    }
  }
};
</script>
      
<style lang='scss' scoped></style>