<template>
  <div>
    <ValidationProvider :rules="rules" :mode="mode" :debounce="200" v-slot="{ errors }">
      <div :class="{ 'is-invalid': errors[0] }">
        <slot />
      </div>

      <div :class=" {'validation-message' : shouldHaveSpaceBetween }">
        <ValidationMessage v-for="error in errors" :key="error">{{ $tExistsOrDefault(error) }}</ValidationMessage>
      </div>    
    </ValidationProvider>
  </div>
</template>
    
    
<script>
import ValidationMessage from '@/components/shared/form/ValidationMessage.vue';
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'FormFieldValidation',
  components: {
    ValidationMessage,
    ValidationProvider
  },
  props: {
    rules: {
      type: Object
    },
    mode: {
      type: String,
      default: "lazy"
    },
    shouldHaveSpaceBetween: {
      type: Boolean,
      default: true
    }
  }
}
</script>
    
<style lang='scss' scoped>
.validation-message {
  margin-top: 0.5rem;
}
</style>