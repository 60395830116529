const pretty_enterKeyCode = 13;
const pretty_spaceKeyCode = 32;

export default {
  methods: {
    // this only works when:
    //   -the pretty checkbox is focused
    //   -the element has the @change event attached
    //   -the element has the value attribute
    //   -the element has the v-model attribute
    pretty_setOnEnter(event) {
      if (event.keyCode === pretty_enterKeyCode || event.keyCode === pretty_spaceKeyCode) {
        let modelValue = event.target.__vue__.$options.propsData.modelValue;
        const value = event.target.__vue__.$options.propsData.value;
        const internalInput = event.target.querySelector('input');
        const isArray = Array.isArray(modelValue);

        if (isArray) {
          !internalInput.checked 
            ? event.target.__vue__.$listeners.change([...modelValue, value]) 
            : event.target.__vue__.$listeners.change(modelValue.filter(x => x !== value));
        } else {
          modelValue = value;
          event.target.__vue__.$listeners.change(modelValue);
          event.target.__vue__.updateInput();
        }
      }
    },
    pretty_preventScrollOnSpace(event) {
      if (event.keyCode === pretty_spaceKeyCode) {
        event.preventDefault();
      }
    }
  },
  updated() {
    const prettyElements = this.$el.querySelectorAll('.pretty:not([hasKeyEventListener="true"]');
    prettyElements.forEach(prettyEl => {
      prettyEl.addEventListener('keyup', this.pretty_setOnEnter);
      prettyEl.addEventListener('keydown', this.pretty_preventScrollOnSpace);
      prettyEl.setAttribute('hasKeyEventListener', true);
      prettyEl.tabIndex = 0;
      prettyEl.querySelector('input').tabIndex = -1;
    });
  },
  beforeDestroy() {
    const prettyElements = this.$el.querySelectorAll('.pretty[hasKeyEventListener="true"]');
    prettyElements.forEach(prettyEl => {
      prettyEl.removeEventListener('keyup', this.pretty_setOnEnter);
      prettyEl.removeEventListener('keydown', this.pretty_preventScrollOnSpace);
      prettyEl.removeAttribute('hasKeyEventListener');
    });
  }
};
