<template>
  <div class="rectangle-with-shadow" :class="{'padded': padded}">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'RectangleWithShadow',
  props:{
    padded: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.rectangle-with-shadow{
    border-radius: 3px;
    box-shadow: 4px 4px 15px 0 rgba(140, 140, 140, 0.19);
    background-color: white;
    margin-bottom: 15px !important;
}

.padded{
    padding: 24px !important;
}
</style>
