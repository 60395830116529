<template>
  <vue-horizontal ref="horizontal" :buttonBetween="false" :snap="$screen.lt.lg ? 'center' : 'none'" :class="{
    'gap': useGap,
    'mobile': $screen.lt.md,
    'pad-right': addPaddingRight
  }">
    <slot />
  </vue-horizontal>
</template>
<script>

/**
 * @deprecated This component is replaced by HorizontalScroll from '@/components/shared/2023/HorizontalScroll.vue';
 */
export default {
  name: 'HorizontalScroll',
  props: {
    useGap: { type: Boolean, default: true },
    addPaddingRight: {
      type: Boolean,
      default: false
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-hl-btn {
  svg {
    background: var(--color-primary-900);
    color: white;
    width: 39px;
    height: 39px;
    border-radius: 50%;
  }
}

.vue-horizontal {
  overflow: hidden !important;

  &.mobile {
    margin-left: calc(var(--page-margin-x-mobile) * -1);
    margin-right: calc(var(--page-margin-x-mobile) * -1);

    ::v-deep .v-hl-container {
      padding-left: var(--page-margin-x-mobile);
      padding-right: var(--page-margin-x-mobile);
    }
  }

  &.gap {
    ::v-deep .v-hl-container>* {
      margin-right: 20px;
      padding-left: 0;
      margin-bottom: 4px;
      margin-top: 4px;

      &:last-child {
        margin-right: .2rem;
      }
    }
  }
}
</style>
