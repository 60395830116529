import Config from '@/constants/config';

const Actions = {
  LOAD_BY_LANGUAGE: 'a.labels.load-by-language'
};

const Mutations = {
  SET: 'm.labels.set',
  CLEAR: 'm.labels.clear',
  SET_LOADING: 'm.labels.set-is-loading'
};

const Endpoints = {
  LoadByLanguage: () => `${Config.API.BASE_URL}/api/Label`
};

export default {
  Actions,
  Mutations,
  Endpoints
};
