<template>
  <span class="validation-error">
    <CircleXMark class="circle-mark" iconInText="true" />
    <slot />
  </span> 
</template>

<script>
import CircleXMark from '@/assets/icons/CircleXMark.vue';

export default {
  name: 'ValidationMessage',
  components: {
    CircleXMark
  }
}
</script>

<style lang='scss' scoped>
.validation-error {
  color: var(--color-error)
}

.circle-mark {
  margin-right: 7px;
}
</style>