// import store from '@/store';
import { api } from '@/utils/api';

import Revisit from '@/constants/revisit';

export default {
  Save (request) {
    const endpoint = Revisit.Endpoints.Save();
    return api.post(endpoint, request);
  },
  Get (id) {
    const params= {
      id: id
    };
    const endpoint = Revisit.Endpoints.Get();
    return api.get(endpoint, { params });
  },
  Patch (id, state) {
    const endpoint = Revisit.Endpoints.Patch();
    return api.patch(endpoint, {
      id: id,
      state: state
    });
  }
};
