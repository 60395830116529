<template>
  <Button :disabled="disabled" :layoutType="checked? 'primary': 'secondary'" class="w-100">
    <div class="flex-row">
      <div class="col-auto p-0">
        <Checkbox v-model="checked" :locked="true" :disabled="disabled" />
      </div>
      <div class="col-auto px-0" id="slot-wrapper">
        <slot />
      </div>  
      
      <!-- Premium for person -->
      <div class="col-auto ml-auto px-0" v-if="price !== null">
        <span class="price" :class="priceClass">
          {{ formatCurrency(price) }}
        </span>
      </div>
    </div>    
  </Button>
</template>
<script>
import formatCurrencyHelper from '@/helpers/formatCurrency';
import Checkbox from '@/components/shared/Checkbox.vue';
import Button from '@/components/shared/button/Button.vue';

export default {
  components: { Checkbox, Button },
    name: 'CheckButtonSlotPrice',
    props: {
        price: Number|| null,
        checked: Boolean,
        disabled: {
            type: Boolean,
            default: false
        },
        tooltipText: {
            type: String,
            default: ''
        },
        showTooltip: {
            type: Boolean,
            default: true
        }
    },
    computed:{
      priceIsNegative(){
          return this.price < 0;
      },
      priceClass(){
        if(this.price === null || this.price == 0) return 'invisible';
        if(this.disabled) return 'text-grey-500';
        if(this.checked){
          return 'text-white';
        }else{
          return this.priceIsNegative ? 'text-positive' : 'text-primary-900';
        }
      },
    },
    methods: {
        formatCurrency(value) {
        if (!value) value = 0;
        return formatCurrencyHelper(
            value,
            this.$i18n.locale() ? this.$i18n.locale() : 'NL'
        );
        },
    }
};
</script>

<style lang="scss" scoped>
.price {
  font-family: var(--text-font-semibold);
}

.text-positive {
  color: var(--color-positive) !important;
}

.text-primary-900 {
  color: var(--color-primary-900);
}

.text-grey-500 {
  color: var(--color-grey-500);
}

.text-white {
  color: white;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  break-before: always;
}

.flex-col-auto {
  width: auto;
}
.flex-col {
  width: 50%;
}

span {
  font-size: 16px;
  font-family: var(--text-font);
  &.dateOfBirth{
    font-size: 14px;
  }
}

.state label::before {
  line-height: normal;
}
</style>