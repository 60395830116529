<template>
  <PartialOverlayWithCloseButton ref="overlay" v-model="showHealthcareProvidersOverlay">
    <KeepAlive>
      <HealthcareProviderSearch 
        v-if="!healthcareProviderSelected" 
        @clickHealthCareProvider="x => setHealthCareProvider(x)"
        v-model="modelValue"
      />
      <HealthcareProviderDetails 
        v-else 
        :selectedYear="selectedYear" 
        :healthCareProvider="healthcareProviderSelected" 
        @closeHealthcareProvider="() => resetHealthCareProvider()" 
      />
    </KeepAlive>
  </PartialOverlayWithCloseButton>
</template>
  
<script>
import HealthcareProviderSearch from '@/components/healthcare-provider/HealthcareProviderSearch.vue';
import HealthcareProviderDetails from '@/components/healthcare-provider/HealthcareProviderDetails.vue';
import PartialOverlayWithCloseButton from '@/components/shared/2.0-import/PartialOverlayWithCloseButton.vue';

export default {
  name: 'HealthcareProvidersOverlay',
  components: {
    HealthcareProviderSearch,
    HealthcareProviderDetails,
    PartialOverlayWithCloseButton
  },
  props: {
    value: Number,
  },
  data() {
    return {
      healthcareProviderSelected: null,
      selectedYear: null
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    showHealthcareProvidersOverlay:{
      get(){
        return this.value != null;
      },
      set(value){
        if(!value){
           this.$emit('input', null);
        }
      }
    }
  },
  methods: {
    setHealthCareProvider({healthcareProvider, selectedYear }) {
      this.healthcareProviderSelected = healthcareProvider;
      this.selectedYear = selectedYear;
    },
    resetHealthCareProvider() {
      this.healthcareProviderSelected = null;
      this.selectedYear = null;
    }
  },
  watch: {
    value(){
      this.resetHealthCareProvider();
    }    
  }
};
</script>
  
<style lang='scss' scoped>
</style>