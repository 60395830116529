import Config from '@/constants/config';

const Actions = {
  GET_CITIES: 'a.zorgzoeker.get_cities',
};

const Mutations = {
  SET_CITIES: 'a.zorgzoeker.set_cities'
};

const Endpoints = {
  GetCities: () => `${Config.API.BASE_URL}/api/zorgzoeker/cities`,
  GetHospitals: () => `${Config.API.BASE_URL}/api/zorgzoeker/hospitals`,
  GetCares: () => `${Config.API.BASE_URL}/api/zorgzoeker/care`,
  GetInstitutions: () => `${Config.API.BASE_URL}/api/zorgzoeker/institutions`,
  GetCareLocations: () => `${Config.API.BASE_URL}/api/zorgzoeker/care-locations`
};

export default {
  Actions,
  Mutations,
  Endpoints
};
